.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  position: relative;
  background: #fefefe;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 100px;
}

.editor2 {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  position: relative;
  background: #fefefe;
}

.editor2 :global(.public-DraftEditor-content) {
  min-height: 40px;
}

.popoverContainerStyle {
  position: absolute;
  max-height: 200px;
  overflow: auto;
  background: white;
  border: 1px solid #ccc;
  z-index: 5000;
  border-radius: 5px;
  min-width: 300px;
}
