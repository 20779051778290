/* ============================== GLOBAL ============================== */

@media (min-width: 1200px) {
  .container {
    max-width: 1160px;
  }
}

body {
  margin: 0;
  font-size: 0.938em;
  background-color: #fff;
  font-family: 'ProximaNova-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* note : hide the acknowledgement modal on app download screen in mobile view */
@media (max-width: 769px) {
  ._ack_modal,
  .modal-backdrop {
    display: none !important;
  }
}

@media (min-width: 769px) {
  ._ack_modal,
  .modal-backdrop {
    display: block !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.img-center {
  display: block;
  margin: 0 auto;
}

.btn-load-more {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
  margin: 0 auto;
  display: block;
}

.acknowledgement-success-title-text {
  color: #000;
  text-align: center;
  font-family: 'ProximaNova-Regular';
  font-size: 18px;
  line-height: 22px;
}

/* ============================== COMMON ============================== */

/* --------------- CUSTOM COOMON CSS --------------- */
.common-ws-pre-line {
  white-space: pre-line;
}

.common-word-break-all {
  word-break: break-all;
}

.common-cursor-pointer {
  cursor: pointer;
}

.common-cursor-not-allowed {
  cursor: not-allowed;
}

.common-unstyled-achor {
  text-decoration: none !important;
  color: inherit;
}

.common-unstyled-achor-no-pointer {
  text-decoration: none !important;
  color: inherit;
  cursor: text;
}

.common-unstyled-achor-no-pointer:hover {
  text-decoration: none !important;
  color: inherit;
  cursor: text;
}

.common-unstyled-achor:hover {
  text-decoration: none !important;
  color: inherit;
}

.common-container-md {
  width: 680px;
}

.common-container-lg {
  width: 820px;
}

.common-wrapper-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.common-tab-navigator-container {
  height: fit-content;
  padding-bottom: 0.8em !important;
  padding-top: 0.6em !important;
}

/* --------------- CHATBOT WIDGET --------------- */

@media (min-width: 992px) {
  .rw-conversation-container {
    width: 400px !important;
  }

  .rw-full-screen > .rw-conversation-container {
    width: 450px !important;
  }
}

/* .rw-conversation-container .rw-header.rw-with-subtitle {
  height: 90px !important;
} */
.rw-widget-container {
  z-index: 1030 !important;
}

.rw-title {
  font-family: 'ProximaNova-SemiBold' !important;
}

.rw-launcher {
  background-color: #ec1b25 !important;
}

.rw-conversation-container .rw-header {
  background-color: #ec1b25 !important;
  text-align: left !important;
}

.rw-conversation-container .rw-client {
  background-color: #8aaad9 !important;
}

.rw-conversation-container .rw-send .rw-send-icon-ready {
  fill: #ec1c24 !important;
}

.rw-conversation-container .rw-header.rw-with-subtitle span {
  font-size: 0.875em;
  font-family: 'Lato-Regular';
  bottom: 14px !important;
}

.rw-conversation-container .rw-header.rw-with-subtitle .rw-title {
  top: 13px !important;
  margin-left: 0.5rem !important;
}

.rw-conversation-container .rw-header.rw-with-subtitle span.rw-with-avatar {
  margin-left: 0.5rem !important;
}

.rw-message-text,
.rw-new-message {
  font-size: 0.875em !important;
  font-family: 'Lato-Regular';
}

.rw-full-screen.rw-chat-open {
  background-color: rgba(0, 0, 0, 0.5) !important;
  transition: background-color 200ms ease-in-out !important;
}

.rw-conversation-container .rw-replies {
  font-size: 0.813em !important;
}

.rw-conversation-container .rw-reply {
  background-color: #fff !important;
  border: solid 1px #8aaad9 !important;
  font-weight: none !important;
  border-radius: 6px !important;
  max-width: none !important;
  text-align: left !important;
  color: #8aaad9 !important;
  box-shadow: none !important;
  font-family: 'Lato-Regular' !important;
  padding: 0.3rem 0.55rem !important;
}

.chatbot-user-profile-show-more-text {
  color: #8aaad9;
  font-family: 'ProximaNova-Regular';
}

.rw-open-launcher {
  padding-top: 0.3rem;
}

.rw-conversation-container .rw-header .rw-avatar {
  height: 42px !important;
  width: 42px !important;
}

.rw-conversation-container .rw-header.rw-with-subtitle .rw-avatar {
  top: 14px !important;
}

.chatbot-user-profile-title {
  font-size: 0.875em !important;
  text-align: center;
  font-family: 'ProximaNovaCond-SemiBold';
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
}

.chatbot-user-profile-show-more-container {
  border: 1px solid #8aaad9 !important;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
}

/* --------------- RC SLIDER --------------- */
.rc-slider-track {
  background-color: #8aaad9 !important;
}

.rc-slider-handle {
  border: solid 2px #8aaad9 !important;
  box-shadow: 0 0 0 3px #8aaad9 !important;
}

.rc-slider-disabled {
  background-color: transparent !important;
}

/* --------------- BOOTSTRAP MODAL --------------- */
.modal-header {
  padding: 0.5rem 1rem;
}

/* --------------- MATERIAL CARD --------------- */
.material-card {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  transition: all 0.5s;
}
.material-card1 {
  box-shadow: 0 1px 0px 0px #b9c7dc;
  transition: all 0.5s;
}

.material-card:hover {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.2);
  transform: scale(1.005);
}

.material-card-no-hover {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15);
}

/* --------------- BOOTSTRAP TABS --------------- */

.nav-tabs {
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  color: #000;
  font-size: 1.125em;
  font-family: 'ProximaNovaCond-Regular';
  font-weight: 100;
}

.nav-tabs .nav-link:hover {
  color: #000;
  background-color: #f5f5f5;
  font-size: 1.125em;
  font-family: 'ProximaNovaCond-Regular';
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #000;
  background-color: transparent;
  border-color: transparent transparent transparent;
  border-bottom: 4px solid #ec1c24 !important;
  font-size: 1.125em;
  font-family: 'ProximaNovaCond-Bold';
}

/* --------------- BOOTSTRAP NAVBAR --------------- */

@media (max-width: 991px) {
  .navbar-collapse {
    overflow-y: auto;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    position: fixed;
    top: 0;
    /* adjust to height of navbar */
    left: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
    width: 40%;
    height: 100%;
    background-color: #fff;
    box-shadow: 2px 2px 4px #e0e0e0;
  }

  .navbar-collapse::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .navbar-collapse.collapsing {
    left: -75%;
    transition: height 0s ease;
  }

  .navbar-collapse.show {
    left: 0;
    transition: left 300ms ease-in-out;
  }

  .navbar-toggler.collapsed ~ .navbar-collapse {
    transition: left 300ms ease-in-out;
  }

  .navbar-collapse-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
    transition: background-color 300ms ease-in-out;
  }

  .dropdown-menu {
    border: none;
    padding: 0;
  }

  .header-feature-item {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .dropdown-menu {
    max-height: calc(100vh - 100px);
    padding: 0;
    overflow-y: scroll;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    min-width: 130px;
  }

  .dropdown-menu::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.navbar-brand {
  max-width: 17.25rem;
}

.navbar-nav > .nav-item > a {
  line-height: 42px;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #000 !important;
  background-color: #eee;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0);
}

/* --------------- BOOTSTRAP BUTTON --------------- */
button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

/* --------------- CONTAINERS --------------- */
.main-container {
  height: 100%;
  width: 100%;
}

.main-content-container {
  padding: 1rem;
}

.main-content-container2 {
  padding-top: 2rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
}

.main-content-container3 {
  padding: 10px;
}

.main-content-container-padding {
  padding-top: 1rem;
}

/* --------------- COLORS --------------- */
.common-primary-color {
  color: #ec1c24;
}

.common-primary-background-color {
  background-color: #ec1c24;
}

/* --------------- HEADERS --------------- */
.header-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.header-icon-estore {
  width: 25px;
  height: 18px;
  cursor: pointer;
}

.header-icon-no-pointer {
  width: 25px;
  height: 25px;
}

.header-view {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  padding-bottom: 1rem;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.header-view-absolute {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.6rem;
}

.store-header-view-absolute {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cart-items-count-chip {
  position: absolute;
  background-color: #5591b7;
  margin-bottom: 10px;
  margin-left: 20px;
  padding: 0px 4px;
  border-radius: 20px;
  font-size: 10px;
  color: #fff;
  cursor: pointer;
  font-family: 'ProximaNova-Regular';
}

/* --------------- IFRAME --------------- */
.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  overflow-y: scroll;
}

/* --------------- TOAST --------------- */
.Toastify__progress-bar--dark {
  background: #ec1c24 !important;
}

/* --------------- CUSTOM SWITCH --------------- */
.custom-switch.custom-switch-md .custom-control-label {
  padding-left: 0.5rem;
  padding-bottom: 1.5rem;
}

.custom-switch.custom-switch-md .custom-control-label::before {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}

.custom-switch.custom-switch-md .custom-control-label::after {
  width: calc(1.5rem - 4px);
  height: calc(1.5rem - 4px);
  border-radius: calc(2rem - (1.5rem / 2));
}

.custom-switch.custom-switch-md .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(calc(1.5rem - 0.25rem));
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ec1c24;
  background-color: #ec1c24;
}

/* --------------- REACT PHONE INPUT 2 --------------- */
.react-tel-input .form-control {
  width: 100% !important;
}

/* --------------- CUSTOM FONTS --------------- */
@font-face {
  font-family: 'Poppins-SemiBold';
  src: url(assets/fonts/Poppins-SemiBold.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url(assets/fonts/ProximaNova-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-SemiBold';
  src: url(assets/fonts/ProximaNova-SemiBold.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Bold';
  src: url(assets/fonts/ProximaNova-Bold.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNovaCond-SemiBold';
  src: url(assets/fonts/ProximaNovaCond-Semibold.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNovaCond-Regular';
  src: url(assets/fonts/ProximaNovaCond-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNovaCond-Bold';
  src: url(assets/fonts/ProximaNovaCond-Bold.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Regular';
  src: url(assets/fonts/Lato-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Bold';
  src: url(assets/fonts/Lato-Bold.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Black';
  src: url(assets/fonts/Lato-Black.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Satisfy-Regular';
  src: url(assets/fonts/Satisfy-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* ============================== COMMON UI ============================== */

/* --------------- COMMON BACK BUTTON --------------- */
.common-back-button-title {
  color: #6e6e6e;
  font-family: 'ProximaNova-SemiBold';
  font-size: 1em;
}

/* --------------- COMMON SECTION HEADER --------------- */
.common-section-header-title {
  color: #ec1c24;
  font-family: 'ProximaNova-Bold';
  margin-bottom: 0;
}

.common-section-header-container {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e0e0e0;
}

/* --------------- COMMON DOWNLOAD APP POPUP --------------- */
.common-download-app-popup-main-container {
  background: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
}

.common-download-app-popup-main-container-web {
  background: #fff;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
}

.common-download-app-popup-container {
  position: relative;
  top: 10%;
  max-width: 100%;
  padding: 1rem;
  overflow: hidden;
}

.common-download-app-popup-container-web {
  position: relative;
  top: 10%;
  max-width: 60%;
  padding: 1rem;
  overflow: hidden;
}

.common-download-app-popup-title {
  color: #211f1f;
  font-size: 1.5em;
  font-weight: 700;
}

.common-download-app-popup-subtitle {
  color: #211f1f;
  font-size: 0.95em;
  font-weight: 700;
}

.common-download-app-popup-normal-text {
  color: #211f1f;
  font-size: 0.95em;
}

/* --------------- COMMON HTML Content --------------- */

.common-content-html .embeddedContent {
  max-width: 100%;
  width: 100%;
  text-align: center;
  position: relative;
}

.common-content-html img {
  max-width: 100%;
  overflow: hidden;
  display: block;
  position: relative;
  padding: 0;
}

.common-embebded-image-style .embedpicture {
  max-width: 100%;
  width: 100%;
  text-align: center;
  position: relative;
}

.common-embebded-image-style .embedpicture .embedimgblock img {
  position: relative;
  display: block;
  padding: 0;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  width: 100%;
}

/* --------------- COMMON NAVBAR HEADER --------------- */
.header-logo-image {
  height: 42px;
  width: 80px;
  cursor: pointer;
}
.header-logo-image-div {
  flex: 1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.test-parent-bg {
  background-color: transparent;
}

.new-to-handle-space {
  min-width: 100vw;
  height: 10px;
  background-color: transparent;
}
.header-custom-navbar {
  background-color: #fff !important;
  box-shadow: 0px 3px 5px #e0e0e0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
}
.header-section-item {
  height: 100%;
  font-size: 0.875em;
  font-family: 'ProximaNova-Bold';
}

.header-section-item1 .dropdown-menu {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.header-section-item {
  font-size: 0.875em;
  padding: 5px;
  padding-right: 20px;
  font-family: 'ProximaNova-Bold';
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.938rem;
  border-radius: 90px;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease;
}
.header-section-item .dropdown-menu {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.header-section-item1 .dropdown-menu {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.header-section-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 90px;
  background: rgba(0, 0, 0, 0);
  pointer-events: none;
}
.header-section-item1::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 90px;
  background: rgba(0, 0, 0, 0);
  pointer-events: none;
}

.header-section-item:hover::before {
  text-decoration: none !important;
  background-color: rgba(68, 114, 196, 0.2);
}

.header-section-item:hover {
  text-decoration: none !important;
  background-color: rgba(68, 114, 196, 1);
  color: #ffffff !important;
  cursor: pointer;
  height: 100%;
}
.header-section-item1:hover {
  text-decoration: none !important;
  cursor: pointer;
  height: 100%;
}

.header-section-item .dropdown-toggle {
  text-decoration: none !important;
  color: inherit;
}

.header-section-item .dropdown-menu {
  text-decoration: none !important;
  display: none;
  opacity: 0;
  visibility: hidden;
}

.header-section-item:hover .dropdown-menu {
  display: block;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0s linear 0s;
  z-index: initial;
}
.header-section-item1:hover .dropdown-menu {
  display: block;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0s linear 0s;
  z-index: initial;
}

.header-section-item:hover {
  text-decoration: none !important;
  height: 100%;
  width: 100%;
  background-color: rgba(68, 114, 196, 1);
  color: #ffffff !important;
  border-radius: 90px;
  cursor: pointer;
}

.header-section-item:hover .dropdown-toggle {
  color: #ffffff !important;
}

.header-section-item .dropdown-toggle::after {
  display: none;
}

.header-section-item1:hover .dropdown-toggle {
  color: #ffffff !important;
}

.header-section-item1 .dropdown-toggle::after {
  display: none;
}
.header-section-item .dropdown-toggle {
  text-decoration: none !important;
}

.header-section-item .dropdown-menu {
  border-top: none;
}
.header-section-item .dropdown-toggle {
  text-decoration: none !important;
}

.header-section-item .dropdown-toggle {
  text-decoration: none !important;
  border: none !important;
  box-shadow: none !important;
}

.header-section-item1 .dropdown-toggle {
  text-decoration: none !important;
}

.header-section-item1 .dropdown-toggle {
  text-decoration: none !important;
  border: none !important;
  box-shadow: none !important;
}

.header-highlighted-section-item {
  font-size: 0.875em;
  font-family: 'ProximaNova-Bold';
  color: #ec1c24 !important;
  display: flex;
  align-items: center;
}
.header-highlighted-section-item-fev {
  padding-left: 10px;
  font-size: 0.875em;
  font-family: 'ProximaNova-Bold';
  color: #ec1c24 !important;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.secondSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profileHeader {
  position: relative;
}

.common-user-profile-photo-container {
  position: relative;
}

.logout-menu {
  margin-top: -5px;
  position: absolute;
  top: 100%; /* Position the menu directly below the profile container */
  left: 100%; /* Center horizontally relative to the parent container */
  transform: translateX(-115%);
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 1px solid #b9c7dc;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  box-sizing: border-box;
}
.viewAllChange {
  color: #4472c4;
  cursor: pointer;
  font-size: 0.75em;
  font-family: 'ProximaNova-SemiBold';
}
.viewAllContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10;
  padding: 10;
}
.profileHeader {
  position: relative;
  height: 30px;
  width: 30px;
  overflow: hidden;
  pointer-events: auto;
  border-radius: 50%;
  border: 2px solid blue;
  left: 5px;
}

.profileHeader img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.navConatiner {
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.dropdown-container {
  flex-direction: row;
  justify-content: center;
  position: fixed;
  border-radius: 20px 20px 20px 20px;
  z-index: 1050;
  box-sizing: border-box;
  gap: 10px;
  padding: 10px 0;
}

.header-section-item-wrapper:hover .dropdown-container {
  display: flex;
}

.header-section-item-wrapper {
  width: 100%;
  height: 100%;
  position: relative;

  /* background-color: rgba(68, 114, 196, 0.05); */
}

.header-section-item:hover .dropdown-container {
  display: flex;
  pointer-events: auto;
}
.header-section-item1:hover .dropdown-container {
  display: flex;
  pointer-events: auto;
}
.header-section-item1:hover .dropdown-container {
  display: flex;
  pointer-events: auto;
}
.drawer-inner-middle {
  flex-grow: 1;
  margin-top: 3px;
  display: flex;
  border: 1px solid #b9c7dc;
  align-items: center;
  background-color: white;
  /* border-color: #89A8D9; */
  border-width: 1px;
  border-radius: 15px 15px 15px 15px;
  align-items: center;
}
.drawer-inner-middle1 {
  flex-grow: 1;
  margin-top: 0px;
  display: flex;
  /* border: 1px solid #b9c7dc; */
  align-items: center;
  background-color: white;
  /* border-color: #89A8D9; */
  border-width: 1px;
  border-radius: 15px 15px 15px 15px;
  align-items: center;
}
.drawer-inner {
  flex-grow: 1;
  min-width: 100%;
  height: 100%;
  max-width: 100%;
  background-color: rgba(137, 168, 217, 0.08);
  display: flex;
  border-width: 1px;
  border-radius: 16px 16px 16px 16px;
  box-sizing: border-box;
}

.drawer-inner .item {
  padding: 10px;
  border-radius: 5px;
  background-color: #f0f0f0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 20%;
  margin: 5px;
}

.drawer-inner .item:hover {
  background-color: #ddd;
}

.header-feature-item {
  padding: 10px;
}
.header-feature-item:hover {
  border-radius: 10px;
  background-color: #ddd;
}

.dropdown-container::-webkit-scrollbar {
  width: 8px;
}

.dropdown-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.dropdown-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.header-logout-container {
  font-size: 0.875em;
  font-family: 'ProximaNova-Bold';
  color: #88a8d8 !important;
  cursor: pointer;
}

.header-logout-container:hover {
  color: red !important;
}
/* .header-logout-container {
  font-size: .875em;
  font-family: "ProximaNova-Bold";
  color: #88a8d8 !important;
  background-color: #f6f6f6;
  padding: .625rem .938rem;
} */

.header-Menu-container {
  display: flex;
  height: 50px;
  flex-direction: row;
  align-items: center;
  border-radius: 100px;
  font-size: 0.875em;
  font-family: 'ProximaNova-Bold';
  background-color: rgba(228, 238, 255, 100);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.header-Menu-container1 {
  display: flex;
  font-size: 0.875em;
  font-family: 'ProximaNova-Bold';
  transform: translateX(0%);
}

.drop-down-container-notification {
  width: 25vw;
}

.header-feature-item {
  font-size: 0.875em;
  font-family: 'ProximaNovaCond-Semibold';
  padding: 0.65rem 1rem;
  min-width: 130px;
}

.header-feature-item-image {
  height: 22px;
  width: 22px;
}

.header-feature-item-highlighted {
  color: #ec1c24;
}

.header-feature-item-unhighlighted {
  color: #000;
}

.header-fullscreen-search-left-section-container {
  background-color: #e8e8e8;
}

.header-fullscreen-search-right-section-container {
  background-color: #fff;
}

#header-fullscreen-search-icon {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(237, 237, 237, 0.8);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translate(0, -100%) scale(0, 0);
  -moz-transform: translate(0, -100%) scale(0, 0);
  -o-transform: translate(0, -100%) scale(0, 0);
  -ms-transform: translate(0, -100%) scale(0, 0);
  transform: translate(0, -100%) scale(0, 0);
  opacity: 0;
  overflow: scroll;
}

#header-fullscreen-search-icon.open {
  -webkit-transform: translate(0, 0) scale(1, 1);
  -moz-transform: translate(0, 0) scale(1, 1);
  -o-transform: translate(0, 0) scale(1, 1);
  -ms-transform: translate(0, 0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
  opacity: 1;
}

#header-fullscreen-search-icon input[type='search'] {
  width: 100%;
  font-size: 1.25em;
  font-weight: 300;
  border: 0;
  padding: 1.5rem 0 1.5rem 3.5rem;
  outline: none;
}

#header-fullscreen-search-icon .close {
  color: #bdbdbd;
  font-size: 3em;
  margin-top: -0.5rem;
}

.header-favorite-added-container {
  border-radius: 50px;
  background-color: rgba(137, 168, 217, 0.08);
  min-height: 50px;
}

.header-favorite-not-added-container {
  border-radius: 50px;
  background-color: rgba(137, 168, 217, 0.08);
  min-height: 50px;
}

.header-favorite-icon {
  height: 20px;
  width: 20px;
}

.header-favorite-title {
  font-family: 'ProximaNovaCond-Bold';
  font-size: 1.25em;
}

/* --------------- FOOTER --------------- */
.footer-microland-link-icon {
  width: 90px;
  height: 12px;
}

.footer-microlander-link-icon {
  width: 90px;
  height: 12px;
}

.footer-text-underline {
  text-decoration: underline;
}

.footer-container {
  background-color: #e7e8e8;
}

.footer-item-text {
  font-family: 'ProximaNova-Regular';
  color: #757575;
  font-size: 0.875em;
  font-weight: 400;
  letter-spacing: 0.58px;
  line-height: 17px;
}

.footer-item-text:hover {
  color: #757575;
}

.footer-icon {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

/* --------------- NAVIGATION --------------- */
.navigation-custom-container {
  min-height: 100vh;
}

/* --------------- CARD VIEW --------------- */
.common-card-view {
  background-color: #eee;
  padding: 1rem;
}

/* --------------- COMMON USER PROFILE PHOTO --------------- */
.common_quick_suggestion_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  font-family: 'ProximaNova-Regular';
  color: #000;
  margin-top: 4px;
  margin-bottom: 8px;
  padding-right: 30px;
}

.common_quick_suggestion_title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  font-family: 'ProximaNova-Bold';
  color: #000;
  margin-top: 12px;
  margin-bottom: 12px;
}

.common_quick_suggestion_see_all {
  font-family: 'ProximaNova-Bold';
  color: #ec1b25;
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: center;
  align-self: center;
}

.common_quick_suggestion_author {
  margin-top: 4px;
  margin-bottom: 0;
}

.common_quick_suggestion_container {
  background-color: #fff;
  margin-top: 3px;
  overflow: auto;
  padding-left: 50px;
}

.common_quick_suggestion_profile_container {
  margin-left: 8px;
  margin-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.common-profile-show-more-text {
  color: #ec1c24;
  font-size: 0.875em;
  font-family: 'ProximaNova-SemiBold';
}

.common-profile-show-more-text-container {
  width: 100%;
}

.common-profile-show-more-icon-size {
  width: 15px;
  height: 15px;
}

.common-user-profile-photo-container {
  position: relative;
  display: block;
  cursor: pointer;
}

.common-user-profile-photo-badge {
  display: flex;
  left: 52%;
  bottom: 10%;
  height: 3.125rem;
  width: 3.125rem;
  position: relative;
  margin-top: -3.263rem;
}

.common-user-profile-photo-badge-text {
  left: 52%;
  position: relative;
  color: #fff;
  font-size: 0.8em;
  margin-left: 1rem;
  margin-top: -2rem;
}

.common-user-profile-photo-1yr-badge-text {
  left: 55%;
  position: relative;
  color: #fff;
  font-size: 0.8em;
  margin-left: 1rem;
  margin-top: -2rem;
}

.common-user-profile-photo-xxs {
  height: 30px;
  width: 30px;
  border-radius: 20px;
}

.common-user-profile-photo-xs {
  height: 40px;
  width: 40px;
  border-radius: 20px;
}

.common-user-profile-photo-sm {
  height: 50px;
  width: 50px;
  border-radius: 25px;
}

.common-user-profile-photo-md {
  height: 60px;
  width: 60px;
  border-radius: 30px;
}

.common-user-profile-photo-lg {
  height: 80px;
  width: 80px;
  border-radius: 40px;
}

.common-user-profile-photo-xl {
  height: 100px;
  width: 100px;
  border-radius: 50px;
}

.common-user-profile-photo-xxl {
  height: 140px;
  width: 140px;
  border-radius: 70px;
}

.common-user-profile-photo-xxxl {
  height: 340px;
  width: 340px;
  border-radius: 170px;
}

/* --------------- COMMON FILTER --------------- */
.filter-unselected-button {
  padding: 0.438rem 1rem;
  border: 1px solid #d5d5d5;
  margin-right: 1rem;
  margin-bottom: 1.25rem;
  background-color: #f1f2f2;
  cursor: pointer;
}

.filter-unselected-text {
  color: #000;
  font-family: 'ProximaNovaCond-SemiBold';
  font-size: 1em;
  margin-bottom: 0;
}

.filter-selected-button {
  padding: 0.438rem 1rem;
  border: 1px solid#96b2d3;
  margin-right: 1rem;
  margin-bottom: 1.25rem;
  background: #f1f2f2;
  cursor: pointer;
}

.filter-selected-text {
  color: #96b2d3;
  font-family: 'ProximaNovaCond-SemiBold';
  font-size: 1em;
  margin-bottom: 0;
}

.filter-header-text {
  text-transform: uppercase;
  color: #000;
  font-weight: 600;
  font-family: 'ProximaNova-Bold';
  font-size: 1.15em;
}

.filter-icon {
  height: 1.4rem;
  width: 1.4rem;
  cursor: pointer;
}

.applied-filter-text {
  font-family: 'ProximaNova-Regular';
  font-size: 1.15em;
}

/* --------------- COMMON LIKED PEOPLE --------------- */

.common-liked-people-name-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
  margin-bottom: 0.625rem;
}

.common-liked-people-place-text {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
}

.common-liked-people-image-row {
  display: flex;
  height: 90px;
  width: '100%';
  flex-direction: row;
  align-items: center;
}

.common-liked-people-pronoun-text {
  font-size: 0.813em;
  color: #6d6e71;
}

/* --------------- COMMON COMMENT BOX --------------- */
.common-comment-box-profinity-button {
  width: 70px;
}

.common-comment-box-profinity-warning-text {
  font-family: 'ProximaNova-SemiBold';
  font-size: 1em;
}

.other-geo-warning-title {
  font-family: 'ProximaNova-Bold';
  font-size: 16px;
  text-align: center;
}

.common-comment-box-profinity-text {
  font-family: 'ProximaNova-Regular';
  font-size: 1em;
}

.common-comment-header-view {
  display: flex;
  justify-content: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.store-common-comment-header-view {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.common-comment-box {
  position: relative;
  width: 100%;
}

.common-comment-box textarea {
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 0;
  padding: 0.625rem;
  color: #676364;
  font-size: 0.813em;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  height: 150px;
}

.common-comment-box textarea:focus {
  outline: 0 !important;
}

.common-submit-btn {
  background-color: #eb2027;
  border: 1px solid #eb2027;
  color: #fff;
  font-size: 1em;
  width: 120px;
  height: 40px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
}

.common-submit-btn-auto {
  background-color: #eb2027;
  border: 1px solid #eb2027;
  color: #fff;
  font-size: 1em;
  height: 40px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
}

.common-submit-btn-container {
  text-align: right;
}

/* --------------- DEFAULT CONTENT VIEW --------------- */
.common-default-content-view-container-no-icon {
  padding: 25vh 0;
}

.default-content-view-container-wishes {
  padding: 10vh 0;
  align-items: center;
  justify-content: center;
}

.common-default-content-view-container-icon {
  padding: 17vh 0;
}

.common-default-content-view-icon-xs {
  height: 60px;
  width: 60px;
}

.common-default-content-view-icon-sm {
  height: 110px;
  width: 110px;
}

.common-default-content-view-icon-md {
  height: 150;
  width: 150;
}

.common-default-content-view-icon-lg {
  height: 250px;
  width: 250px;
}

.common-default-content-view-text {
  font-size: 1.125em;
  font-family: 'Lato-Bold';
  color: #8aaad9;
  text-align: center !important;
  max-width: 60%;
}

.public-profile-default-content {
  display: flex;
  align-items: center;
  height: 90vh;
}

.common-default-content-view-text-wishes {
  font-size: 1.125em;
  font-family: 'Lato-Bold';
  color: #000;
  text-align: center !important;
  max-width: 60%;
  margin-top: 20px;
}

.common-default-content-view-text-wishes-sub {
  font-size: 1.125em;
  font-family: 'Lato-Regular';
  color: #000;
  text-align: center !important;
  max-width: 60%;
  margin-top: 20px;
}

.year-all-wishes-item {
  text-align: center;
  background-color: #fff;
  font-family: 'ProximaNova-Regular';
  padding: 10px;
  color: #000;
}

.year-all-wishes-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.year-all-wishes-devider {
  height: 1px;
  width: 40%;
  background-color: #cfcfcf;
}

/* --------------- COMMON FULLSCREEN LOADER --------------- */
.common-full-screen-loader-container {
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  background-color: #fff;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.common-full-screen-loader-logo {
  height: 84;
  width: 160px;
}

/* --------------- COMMON PAGE NOT FOUND SCREEN --------------- */
.common-text {
  font-family: 'ProximaNova-Bold', sans-serif;
  color: #000;
  font-size: 2.5em;
}

/* --------------- COMMON PROGRESS DIALOG --------------- */
.common-progress-dialog {
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2000;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.85);
}

/* --------------- COMMON CALENDAR DIALOG --------------- */
.react-datepicker__day--highlighted-custom-1 {
  color: #ec1c24 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #ec1c24 !important;
  color: #fff !important;
}

/* --------------- COMMON CUSTOM BUTTON --------------- */
.common-custom-button-container {
  font-weight: 400;
  color: #212529;
  vertical-align: middle;
  font-size: 1rem;
  text-align: center;
}

.common-custom-button-inner-container {
  padding: 0.25rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.common-custom-button-container:focus {
  outline: 0 !important;
}

.common-custom-button-container:disabled {
  opacity: 0.65 !important;
}

.common-custom-button-primary {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
}

.common-custom-button-secondary {
  background-color: #88a8d8;
  border: 1px solid #88a8d8;
  color: #fff;
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
}

.ask-ml-custom-button {
  background-color: #5591b7;
  border: 1px solid #5591b7;
  color: #fff;
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
  font-weight: 600;
}

.common-custom-button-ternary {
  background-color: transparent;
  border: 1px solid #ec1c24;
  color: #ec1c24;
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
}

.common-custom-button-quaternary {
  color: #ec1c24;
  background-color: #efefef;
  border: 1px solid #efefef;
  font-family: 'ProximaNova-SemiBold';
}
/* .buttonConainer-fev {
  flex-direction: row;
  height: auto;
  width: 20%;
  align-items: center;
  justify-content: space-between;
}

.custom-button-quaternary-fev-save {
  height: 20px;
  color: #efefef;
  background-color: #4472C4;
  border-radius: 10px;
  border: 1px solid #efefef;
  font-family: 'ProximaNova-SemiBold';
}
button:focus {
  outline: none;
  box-shadow: none;
}

.custom-button-quaternary-fev-cancle {
  color: black;
  height: 20px;
  border-radius: 10px;
  background-color: #efefef;
  border: 1px solid #efefef;
  font-family: 'ProximaNova-SemiBold';
} */
/* Container for the buttons */
/* Container for the buttons */
/* Container for the buttons */
.buttonConainer-fev {
  display: flex; /* Ensure buttons are placed in a row */
  width: 25%; /* Ensure the container takes full width */
}

/* Button styles for both Save and Cancel buttons */
.custom-button-quaternary-fev-cancle,
.custom-button-quaternary-fev-save {
  flex: 1; /* Both buttons take equal width */
  height: 40px; /* Ensure button height is at least 50px */
  color: #efefef;
  border-radius: 10px;
  font-family: 'ProximaNova-SemiBold';
  padding: 0 20px; /* Adjust padding for better text alignment */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #efefef; /* Apply border */
  margin: 0 5px; /* Optional: space between buttons */
}

/* Specific styles for the Save button */
.custom-button-quaternary-fev-save {
  background-color: #4472c4;
  border-color: #efefef;
}

/* Specific styles for the Cancel button */
.custom-button-quaternary-fev-cancle {
  background-color: #efefef;
  border-color: #efefef;
  color: black;
}

/* Remove focus outline (blue border) */
button:focus {
  outline: none;
  box-shadow: none;
}

/* .common-custom-button-quinary {
  color: #ec1c24;
  background-color: #fff;
  border: 1px solid #fff;
} */

.common-custom-button-primary:hover {
  opacity: 0.9;
  text-decoration: none !important;
  color: #fff !important;
}

.common-custom-button-secondary:hover {
  opacity: 0.9;
  text-decoration: none !important;
  color: #fff !important;
}

.common-custom-button-ternary:hover {
  opacity: 0.8;
  text-decoration: none !important;
  color: #ec1c24 !important;
}

.common-custom-button-quaternary:hover {
  opacity: 0.9;
  text-decoration: none !important;
  color: #ec1c24 !important;
}

.common-custom-button-quinary {
  background-color: #eaeaea;
  border: 1px solid #eaeaea;
  color: #eb2027;
  font-size: 1em;
  text-align: center !important;
  font-family: 'ProximaNova-SemiBold';
  text-transform: uppercase;
}

.common-custom-button-quinary:active,
.common-custom-button-quinary:focus {
  outline: none !important;
  box-shadow: none !important;
}

.common-custom-button-quinary:hover {
  opacity: 0.9;
  text-decoration: none !important;
  color: #eb2027 !important;
}

.common-custom-button-senary {
  background-color: #fff;
  border: 1px solid #fff;
  color: #000;
  font-size: 1em;
  text-align: center !important;
  font-family: 'ProximaNova-SemiBold';
}

.common-custom-button-senary:active,
.common-custom-button-senary:focus {
  outline: none !important;
  box-shadow: none !important;
}

.common-custom-button-senary:hover {
  opacity: 0.9;
  text-decoration: none !important;
  color: #000 !important;
}

.common-custom-button-septenary {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  font-family: 'ProximaNova-SemiBold';
}

.common-custom-button-septenary:hover {
  opacity: 0.9;
  text-decoration: none !important;
  color: #fff !important;
}

.common-custom-button-septenary:active,
.common-custom-button-septenary:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* --------------- COMMON DIVIDER --------------- */

.common-divider-style {
  height: 1px;
  background-color: #e7e7e7;
}

.vertical-divider {
  left: 50%;
  top: 10%;
  bottom: 40%;
  width: 5%;
  border-left: 1px solid #e8e8e8;
}

/* --------------- COMMON LOAD DATE TEXT --------------- */

.common-load-date-text-calender-style {
  width: 12px;
  height: 12px;
}

.common-load-date-text-event-date {
  margin-left: 0.625rem;
  font-size: 0.875em;
  font-family: 'ProximaNovaCond-Bold';
  color: #8aaad9;
}

.common-load-date-text-event-icon-sm {
  height: 20px;
  width: 20px;
}

/* --------------- COMMON EDIT COMMENT BOX--------------- */

.common-edit-comment-emoji-div {
  position: absolute;
  top: 30px;
  right: 10px;
  z-index: 100;
}

.common-edit-comment-emoji-div .emoji-picker-react {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  max-height: 250px;
  overflow-y: auto;
}

.common-edit-cancel-button-container {
  background: #fff;
  border: #fff;
  color: #ec1c24;
  font-size: 1em;
}

.common-edit-cancel-button-container:hover {
  background: #fff;
  border: #fff;
  color: #ec1c24 !important;
  font-size: 1em;
  outline: 0 !important;
}

.common-edit-cancel-button-container:focus {
  background: #fff;
  border: #fff;
  color: #ec1c24;
  font-size: 1em;
  outline: 0 !important;
}

.common-edit-cancel-button-container:focus-visible {
  background: #fff;
  border: #fff;
  color: #ec1c24;
  font-size: 1em;
  outline: 0 !important;
}

.common-edit-update-button-container {
  width: 70px;
}

.common-edit-comment-box {
  position: relative;
  width: 100%;
}

.common-edit-comment-textarea {
  width: 100%;
  border: 1px solid #d3d3d3;
  padding: 0.625rem;
  color: #676364;
  font-size: 0.813em;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  border-radius: 7px;
  min-height: 100px;
}

.common-edit-comment-box textarea:focus {
  outline: 0 !important;
}

/* ============================== COMMON SCREENS ============================== */

/* --------------- COMMON FULL VIDEO SCREEN --------------- */

.common-fullscreen-video-container {
  width: 100vw;
  height: calc(100vh - 74px);
}

/* --------------- COMMON PROFILE SCREEN --------------- */

.dei-champ-badge-style {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  height: 40px;
  width: 140px;
}

.common-profile-org-reportee-top-radius {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.common-profile-org-reportee-bottom-radius {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.common-profile-divider-style {
  height: 1px;
  background-color: #f0f0f0;
}

.common-profile-orgstructure-border {
  background-color: #cecece;
}

.common-profile-reportee-container {
  padding-top: 0.938rem;
}

.common-profile-reportee-border-vertical {
  width: 1px;
  height: 100%;
}

.common-profile-reportee-horizontal-border {
  width: 24px;
  height: 1px;
}

.common-profile-reportee-border-vertical-container {
  height: 80px;
  margin-top: -70px;
}

.common-profile-manager-horizontal-border {
  width: 12px;
  height: 1px;
}

.common-profile-manager-container {
  padding-top: 0.938rem;
}

.common-profile-manager-border-vertical-container {
  height: 72px;
  margin-top: -4.375rem;
}

.common-profile-manager-border-vertical {
  width: 1px;
  height: 100%;
}

.common-profile-org-reportee-container {
  border: 1px solid #d5d5d5;
  background-color: #f7f7f9;
}

.common-profile-org-manager-container {
  background-color: #f7f7f9;
  border-radius: 7px;
}

.common-profile-org-cur-emp-container {
  border-radius: 7px;
  border: 1px solid #898989;
  background-color: #fff;
}

.common-profile-org-emp-container {
  height: 80px;
  width: 100%;
  z-index: 2;
}

.common-profile-org-emp-name-text {
  font-family: 'ProximaNova-Bold';
  font-size: 0.875em;
}

.common-profile-org-emp-designation-text {
  font-family: 'ProximaNova-Regular';
  font-size: 0.875em;
  color: #757575;
}

.common-profile-percentile-text {
  font-size: 0.813em;
  color: #8aaad9;
  font-family: 'ProximaNova-SemiBold';
}

.common-profile-manager-border-container {
  width: 100%;
  height: 15px;
  margin-left: 2.813rem;
}

.common-profile-manager-border {
  width: 1px;
  height: 100%;
  background-color: #cecece;
}

.common-profile-reporting-manager-container {
  background-color: #f7f7f9;
  border-radius: 7px;
}

.common-profile-supervisor-position-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
}

.common-profile-supervisor-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-SemiBold';
}

.common-profile-profile-hierarchy-size {
  width: 20px;
  height: 20px;
}

.common-profile-call-icon {
  width: 20px;
  height: 20px;
}

.common-profile-contact-row {
  height: 60px;
}

.common-profile-contact-icon-container {
  background-color: #f5f5f5;
  padding: 7px;
  border-radius: 100px;
}

.common-profile-contact-icon-text {
  text-align: center;
  font-family: 'Lato-Regular';
  font-size: 0.875em;
}

.common-profile-card-image-size {
  width: 22px;
  height: 22px;
}

.common-profile-visible-icon {
  width: 20px;
  height: 20px;
}

.common-profile-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.common-profile-title-text {
  font-family: 'ProximaNova-Regular';
  font-size: 0.875em;
  color: #757575;
}

.common-profile-subtitle-text {
  font-family: 'ProximaNova-Regular';
  font-size: 1em;
  white-space: pre-line;
}

.common-profile-detail-name {
  font-weight: bold;
  margin-bottom: 0.5em;
}

.common-profile-detail-function-title {
  font-size: 0.9em;
  color: #808184;
  font-family: 'Lato-Regular', sans-serif;
  margin-bottom: 0.5rem;
}

.common-profile-detail-about-text {
  font-size: 1em;
}

.common-profile-detail-equal-space-text {
  margin: 0 1rem;
}

.common-profile-seperator {
  margin: 1rem -1rem;
}

.common-wish-comment-divider {
  height: 1px;
}

.common-wish-comment-user-name {
  font-family: 'ProximaNova-Bold';
  margin-right: 0.313rem;
  font-size: 1em;
}

.common-wish-comment-date {
  font-family: 'ProximaNova-Regular';
  color: #828282;
  font-size: 1em;
}

.common-wish-comment-message {
  font-family: 'ProximaNova-Regular';
  color: #828282;
  font-size: 1em;
}

.common-wish-celebration-messages-title {
  font-family: 'ProximaNova-Bold';
  margin-bottom: 0;
}

.common-wish-celebration-messages-wishes {
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  cursor: pointer;
  background-color: #fafafa;
}

.common-wish-card-container {
  cursor: pointer;
  background-color: #f7f7f9;
  border-radius: 5px;
}

.birthday-wish-card-container {
  cursor: pointer;
  background-color: #003a69;
  border-radius: 5px;
}

.common-wish-card-title {
  font-size: 0.938em;
  margin-bottom: 0;
  font-family: 'ProximaNova-Bold';
}

.common-wish-card-right-icon {
  height: 14px;
  width: 14px;
}

.common-profile-supervisor-pronounce-text {
  font-size: 0.813em;
  color: #6d6e71;
}

.common-profile-org-emp-pronoun-text {
  font-size: 0.813em;
  color: #6d6e71;
}

.send-wish-button {
  display: flex;
  justify-content: center;
  color: #ed1c24;
  border-width: 1px;
  border-color: #ed1c24;
  border-style: solid;
  border-radius: 17px;
  width: 40%;
  margin-top: 10px;
  font-family: 'ProximaNova-Regular';
}

.wishes-employee-details {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.employee-details-container {
  background: #f5f5f5;
  padding: 20px;
}

.send-wish-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wishes-sub-text {
  color: #5591b7;
  font-size: 13px;
  font-family: 'ProximaNova-Regular';
}

.wishes-heading-celebrations {
  color: #000;
  font-family: 'ProximaNova-Bold';
  font-size: 16px;
}

.wishes-heading-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

/* --------------- COMMON COMMENT POPUP MENU SCREEN --------------- */
#common-comment-popup-popover .arrow {
  display: none;
}

.common-comment-popup-menu-container {
  width: 100px;
  height: 100px;
}

[role='tooltip'].popup-content {
  width: 100px !important;
}

.common-comment-popup-more-vertical-icon-size {
  width: 15px;
  height: 15px;
}

.common-comment-popup-text {
  height: 38px;
  font-family: 'ProximaNova-Regular';
  font-size: 1.125em;
}

.common-comment-popup-border {
  height: 1px;
}

.profile-lottie-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* --------------- COMMON COMMENT SCREEN --------------- */

.common-comment-emoji-icon {
  width: 25px;
  height: 25px;
}

.common-comment-toast-text {
  width: 200px;
  font-family: 'ProximaNova-Regular';
  font-size: 1em;
}

.common-comment-toast-button {
  font-family: 'ProximaNova-Regular';
  font-size: 1em;
  color: #8aaad9;
}

.common-comment-item-other-comment-background {
  background-color: #f5f5f5;
}

.common-comment-item-message-text-outer {
  display: inline-block;
  border-radius: 7px;
}

.common-comment-item-action-item-icon {
  height: 20px;
  width: 20px;
}

.common-comment-item-action-item-text {
  font-family: 'ProximaNova-Regular';
  color: #757575;
  font-size: 0.875em;
}

.common-comment-item-name-text {
  font-family: 'ProximaNova-Bold';
  font-size: 0.875em;
}

.common-comment-item-date-text {
  font-family: 'ProximaNova-Bold';
  font-size: 0.75em;
  color: #ababab;
}

.common-comment-item-message-text {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
  white-space: pre-line;
}

.common-comment-item-message-text-comment {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
  white-space: pre-line;
  word-wrap: break-word;
  max-width: 550px;
}

.common-comment-item-edit-comment-container {
  width: 100%;
}

.common-comment-item-vertical-line {
  width: 2px;
  background-color: #e4e4e4;
  height: 100%;
  position: relative;
  right: 16px;
  z-index: -1;
}

.common-comment-reply-item-vertical-line {
  width: 2px;
  background-color: #e4e4e4;
  position: relative;
  left: 14px;
}

.common-comment-reply-item-vertical-line-last-item {
  height: 32px;
}

.common-comment-reply-item-horionztal-line {
  width: 40px;
  background-color: #e4e4e4;
  height: 2px;
  position: relative;
  top: 14px;
  left: 14px;
}

.common-comment-emoji-icon-container {
  position: absolute;
  bottom: 90px;
  right: 30px;
  z-index: 1000;
}

.common-comment-emoji-icon-container-ceo-chat {
  position: absolute;
  bottom: 20px;
  right: 0px;
  z-index: 1000;
}

.ceo-chat-radio {
  margin-top: 0.25rem;
  margin-right: 0.5rem;
}

.ceo-chat-public {
  font-size: 12px;
  color: #4472c4;
  font-family: 'ProximaNova-Regular';
  line-height: 14px;
}

.ceo-chat-private {
  font-size: 12px;
  color: #000000;
  font-family: 'ProximaNova-Regular';
  line-height: 14px;
}

.ceo-chat-public-private-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background-color: #f2f2f2;
  border-radius: 4px;
  padding: 5px 10px;
}

.common-comment-emoji-icon-container-reply {
  position: absolute;
  bottom: 25px;
  right: 10px;
}

.common-comment-wishes-icon-container {
  bottom: 90px;
  right: 75px;
  z-index: 1000;
}

.common-edit-comment-wishes-icon-container {
  bottom: 90px;
  right: 10px;
  z-index: 1000;
}

.common-comment-upload-image-icon-container {
  bottom: 90px;
  right: 10px;
  z-index: 1000;
}

.common-comment-image-container {
  bottom: 90px;
  left: 15px;
  z-index: 1000;
}

.comment-selected-image {
  height: 40px;
  width: 40px;
}

.common-comment-emoji-div {
  position: absolute;
  bottom: 40px;
  right: 0;
  z-index: 100;
}

.common-comment-container {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.common-comment-first-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.625rem;
}

.common-comment-item-row {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  border-bottom: 1px solid #ebebeb;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.common-comment--row {
  width: 85%;
}

.common-comment-error-view {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.error-text {
  font-family: 'Lato-Regular', sans-serif;
  color: #8aaad9;
  font-size: 1em;
}

.common-comment-box-view {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 100px;
}

.common-comment-name {
  font-family: 'ProximaNovaCond-Bold';
  font-size: 0.875em;
}

.common-comment-date {
  font-size: 0.75em;
  font-family: 'ProximaNova-Regular';
  color: #828282;
}

.common-comment-text {
  font-size: 0.813em;
  font-family: 'Lato-Regular', sans-serif;
  color: #4d4d4d;
}

.common-comment-item-pronoun-text {
  font-size: 0.813em;
  color: #6d6e71;
}

/* ============================== SCREENS ============================== */

/* --------------- HOME SCREEN --------------- */
.home-fullscreen-modal-dialog {
  width: 100%;
  max-width: none !important;
}

.home-fullscreen-modal-dialog > .modal-dialog,
.home-fullscreen-modal-dialog > .modal-dialog > .modal-content {
  width: 100%;
  max-width: none !important;
  margin: 0 auto;
  height: 100%;
  border: none;
  border-radius: 0;
  background-color: #000;
}

.home-fullscreen-modal-dialog1 {
  width: 100%;
  max-width: none !important;
}

.appBannerDiv {
  height: 50%;
  width: 50%;
}

.home-fullscreen-modal-dialog1 > .modal-dialog,
.home-fullscreen-modal-dialog1 > .modal-dialog > .modal-content {
  width: 100%;
  max-width: none !important;
  margin: 0 auto;
  height: 100%;
  border: none;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.5);
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-fullscreen-modal-dialog-close-button-container1 {
  position: absolute;
  z-index: 2;
  top: 10%;
  right: 2%;
  font-size: 10px;
  cursor: pointer;
}

.home-fullscreen-modal-dialog > .modal-dialog > .modal-content > .modal-body {
  padding: 0;
}

.home-fullscreen-modal-dialog-close-button-container {
  position: absolute;
  z-index: 2;
  top: 8%;
  right: 2%;
  font-size: 18px;
  cursor: pointer;
}

.home-video-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.home-video-container iframe {
  width: 100%;
  height: 100%;
}

.home-banner-container {
  width: 100%;
  height: 67.5vh;
}

.home-banner-image-container {
  width: calc(67.5vh * 16 / 9);
  height: 67.5vh;
}

.home-loader-outer-view {
  display: flex;
  justify-content: center;
}

.home-container {
  background-color: #fff;
}

.home-favorites-section {
  border-bottom: 2px solid #ebebeb;
}

.home-favorites-item {
  font-size: 1.15em;
  color: #2d2d2d;
  text-transform: uppercase;
  font-family: 'ProximaNovaCond-SemiBold';
  border-right: 2px solid #ec1c24;
  cursor: pointer;
}

.home-favorites-item-last {
  font-size: 1.15em;
  color: #2d2d2d;
  text-transform: uppercase;
  font-family: 'ProximaNovaCond-SemiBold';
  cursor: pointer;
}

.home-section-container {
  padding: 1rem;
  border-bottom: 2px solid #ebebeb;
}

.home-module-link {
  text-decoration: none !important;
  color: inherit;
}

.home-module-link:hover {
  text-decoration: none !important;
  color: inherit;
}

.home-section-title {
  color: #000;
  font-size: 18px;
  text-transform: uppercase;
  font-family: 'ProximaNova-Bold';
  margin-right: 0.5rem;
  margin-bottom: 0;
  cursor: pointer;
}

.home-section-right-icon {
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.home-section-left-icon {
  height: 36px;
  width: 36px;
}

.home-classic-text-image {
  width: 100%;
  margin: 0;
}

.home-horizontal-icon-small-container {
  cursor: pointer;
}

.home-horizontal-icon-small-image {
  height: 6.25rem;
  width: 6.25rem;
  margin-bottom: 1rem;
  border-radius: 50%;
}

.home-horizontal-icon-small-title {
  text-align: center;
  margin-bottom: 0;
  font-size: 1.125em;
  font-family: 'ProximaNovaCond-SemiBold';
}

.dashboard-community-members-text-alignment {
  text-align: center;
}

.home-dynamic-columns-item-container {
  max-width: 70%;
  cursor: pointer;
}

.home-dynamic-columns-item-text {
  margin-bottom: 0.5rem;
}

.home-classic-text-container {
  cursor: pointer;
}

.home-classic-text-title {
  font-size: 1.1em;
  font-family: 'Lato-Bold';
  color: #8aaad9;
}

.home-section-dynamic-ctext {
  width: 35%;
}

.home-section-horizontal-scrollable {
  width: auto;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

.home-section-horizontal-scrollable::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.home-anniversary-widget-title {
  font-family: 'ProximaNovaCond-SemiBold';
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: left;
}

.home-anniversary-widget-image {
  height: 135px;
  width: 240px;
}

.article-time-to-read-text {
  color: gray;
}

.dot-wrapper {
  height: 14px;
  width: 20px;
}

.dot-style {
  height: 4px;
  width: 4px;
  border-radius: 4px;
  background-color: black;
  opacity: 0.5;
}

.ml-35-home-banner {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.ml-35-home-banner-no-fev-sections {
  width: 100%;
  height: auto;
  cursor: pointer;
  margin-top: 20px;
}

/* --------------- DIRECTORY SCREEN --------------- */
.directory-icon {
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.directory-search-form {
  width: 90%;
}

.directory-search-bar {
  border: none;
  outline: 0;
  background: none;
  width: 100%;
  transition: width 0.4s linear;
}

.directory-profile-name {
  text-align: center;
  margin-bottom: 0;
  font-size: 1.125em;
  font-family: 'ProximaNovaCond-SemiBold';
}

.directory-profile-subtitle {
  text-align: center;
  margin-bottom: 0;
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
  color: #8aaad9;
}

/* --------------- NEW JOINEES SCREEN --------------- */
.new-joinees-lower-levels-overview-container {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0;
  line-height: 25px;
}

.yt-div {
  border-bottom: 1px solid #e7e7e7;
}

.new-joinee-top-card-container {
  cursor: pointer;
}

.speaker-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.new-joinee-article-style {
  width: 363px;
  height: 269px;
}

.session-item-container {
  min-width: 40%;
}

.session-ask-question-conatiner {
  width: 30%;
}

.session-detail-container {
  width: 100%;
}

.speaker-conatiner {
  width: 100%;
}

.profile-conatiner {
  width: 110%;
}

.profile-container {
  width: 100%;
}

.calendar-card {
  width: 53px;
  height: 53px;
  background-color: #88a8d8;
  border-radius: 5px;
  color: white;
  min-width: 53px;
}

.holiday-card {
  width: 70px;
  height: 53px;
  background-color: #88a8d8;
  border-radius: 5px;
  color: white;
  min-width: 53px;
}

.new-joinees-lower-levels-overview-image {
  width: 100%;
  object-fit: cover;
}

.new-joinees-lower-levels-overview-article-title {
  font-size: 0.875em;
  font-family: 'ProximaNova-Bold';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.new-joinees-lower-levels-overview-article-title-spacing {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.new-joinees-lower-levels-overview-background-container {
  position: relative;
  display: inline;
}

.new-joinees-lower-levels-overview-foreground-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.new-joinees-induction-center-container {
  height: 269px;
  width: 363px;
  background-color: rgb(71, 69, 127);
  color: #000;
  font-size: 100px;
  font-weight: 700;
}

.new-joinees-schedule-text {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
}

.new-joinees-lower-levels-overview-pdf-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.new-joinees-lower-levels-overview-pdf-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.new-joinees-neo-media-link-icon {
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.new-joinees-lower-levels-overview-pdf-title {
  font-family: 'Lato-Regular';
  font-size: 16px;
  margin-left: 14px;
  margin-bottom: 12px;
  color: #7d9ecf;
  cursor: pointer;
  width: 90%;
}

.new-joinee-media-link {
  color: #7d9ecf;
  font-size: 14px;
}

.new-joinee-media-link :hover {
  text-decoration: underline;
  text-decoration-color: #7d9ecf;
}

.speaker-list-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
}

.ver-div {
  border-right: 1px solid #e8e8e8;
}

.new-joinees-more_info_icon {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.new-joinees-lower-levels-overview-video-button-image {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.new-joinees-chat-button-image {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.new-joinees-lower-levels-checklists-progress-text {
  font-family: 'ProximaNova-SemiBold';
  font-size: 1em;
}

.new-joinees-lower-levels-checklists-section-title {
  font-family: 'ProximaNova-Bold';
  font-size: 1.125em;
}

.new-joinees-lower-levels-checklists-item-text {
  font-family: 'ProximaNova-Regular';
  font-size: 1em;
}

.new-joinees-lower-levels-checklists-item-link-icon {
  height: 24px;
  width: 24px;
}

.new-joinees-lower-levels-quick-links-container {
  background-color: #ebebeb;
}

.new-joinees-lower-levels-quick-links-title {
  font-family: 'ProximaNova-Bold';
  font-size: 1.125em;
}

.new-joinees-lower-levels-quick-links-description {
  font-family: 'ProximaNova-Regular';
  font-size: 1em;
}

.new-joinees-higher-levels-card-main-title {
  font-family: 'ProximaNova-Bold';
  font-size: 1em;
  flex-wrap: 'wrap';
}

.new-joinees-higher-levels-card-main-title2 {
  font-family: 'ProximaNova-Bold';
  font-size: 1em;
  width: 40%;
}

.divider-space {
  width: 70%;
}

.new-joinees-higher-levels-card-sub-title {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
}

.new-joinees-higher-levels-card-speaker-title {
  font-family: 'ProximaNova-Bold';
  font-size: 1em;
  width: '100%';
}

.new-joinees-higher-levels-card-speaker-subtitle {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  color: #757575;
}

.new-joinees-higher-levels-card-rate-container {
  background-color: #eaeaea;
}

.new-joinees-higher-levels-card-rate-text {
  color: #ec1c24;
  font-family: 'ProximaNova-Bold';
  font-size: 0.85em;
}

.new-joinees-higher-levels-card-pdf-media-attachment-icon {
  height: 30px;
  width: 30px;
}

.new-joinees-higher-levels-card-link-media-attachment-icon {
  height: 25px;
  width: 25px;
}

.new-joinees-higher-levels-session-rate-icon {
  height: 20px;
  width: 20px;
}

.new-joinees-higher-levels-questions-questioner-title {
  font-family: 'ProximaNova-Bold';
  font-size: 1em;
}

.new-joinees-higher-levels-questions-questioner-date-posted {
  font-family: 'Lato-Regular';
  font-size: 0.9em;
  color: #595959;
}

.new-joinees-higher-levels-questions-questioner-subtitle {
  font-family: 'ProximaNova-Regular';
  font-size: 0.9em;
  color: #595959;
}

.new-joinees-higher-levels-questions-questioner-question {
  font-family: 'ProximaNova-Regular';
  font-size: 1em;
  color: #383839;
}

.no-answers-yet-text {
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #8aaad9;
  margin: 20px;
  font-weight: 'Lato-Regular';
}

.activity-count {
  font-size: 0.8em;
  color: #8aaad9;
  font-family: 'ProximaNova-Regular';
  margin-top: 0.2em;
  margin-left: 0.2em;
}

/* --------------- MICROGIVE SCREEN --------------- */
.microgive-header-icon {
  width: 25px;
  height: 25px;
}

.microgive-header-view {
  background-color: #ec1c24;
  display: flex;
  justify-content: flex-end;
  padding-top: 0.938rem;
  padding-bottom: 0.938rem;
  align-items: center;
}

.microgive-view-style {
  background-color: #fff;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  vertical-align: middle;
}

.microgive-calender-style {
  width: 20px;
  height: 20px;
}

.microgive-event-date {
  margin-left: 0.625rem;
  font-size: 0.875em;
  font-family: 'ProximaNovaCond-Bold';
  color: #8aaad9;
  font-weight: bold;
}

.microgive-title {
  margin-top: 0.313rem;
  font-family: 'ProximaNovaCond-Bold';
}

.microgive-edit-header-text {
  margin-top: 0.313rem;
  font-family: 'ProximaNova-Bold';
}

.microgive-short-description {
  margin-top: 0.313rem;
  font-size: 0.813em;
  font-family: 'ProximaNovaCond-SemiBold';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #6d6e71;
}

.microgive-normal-icon {
  width: 25px;
  height: 25px;
}

.microgive-profile-image {
  height: 80px;
  width: 80px;
  border-radius: 40px;
  overflow: 'hidden';
}

.microgive-profile-image-xs {
  height: 30px;
  width: 30px;
  border-radius: 20px;
  overflow: 'hidden';
}

.microgive-profile-image-small {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  overflow: 'hidden';
}

.microgive-profile-image-small-overlay {
  margin-left: -16px;
}

.microgive-outer-view {
  display: flex;
  align-items: center;
}

.microgive-name {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
}

.microgive-position-text {
  font-size: 0.813em;
  font-family: 'ProximaNova-Regular';
  margin-top: 0.313rem;
  color: #aaa;
}

.message-input {
  position: fixed;
  margin-top: 1.25rem;
  right: 0.938rem;
  left: 0.938rem;
  bottom: 5px;
}

.microgive-chat-list {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 500px !important;
}

.microgive-send-button {
  background-color: #ec1c24;
  border: 5px #ec1c24;
  color: #fff;
  font-size: 1em;
  height: 100%;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
}

.microgive-button-style {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
  margin: 0 auto;
  display: block;
}

.microgive-edit-sub-button-style {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
  margin: 0 auto;
  display: block;
  border-radius: 5px;
}

.microgive-search-form {
  width: 90%;
}

.microgive-search-bar {
  border: none;
  outline: 0;
  background: none;
  width: 100%;
  transition: width 0.4s linear;
}

.microgive-html-content {
  font-size: 0.938em;
  font-family: 'ProximaNova-Regular';
}

.microgive-content-html .embeddedContent {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  float: none;
}

.microgive-content-html iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.microgive-main-container {
  height: 100%;
  width: 100%;
  background-color: #fff;
}

.microgive-body-container {
  padding: 1rem;
}

.microgive-profile-name {
  text-align: center;
  margin-bottom: 0;
  font-size: 1.125em;
  font-family: 'ProximaNovaCond-SemiBold';
}

.microgive-icon {
  cursor: pointer;
  width: 20px;
}

input#employeeCheck input:checked + label:before {
  background-color: #eb2027;
}

.microgive-people-tag-text {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
}

.microgive-register-message-text {
  color: #000;
  font-family: 'ProximaNova-Bold';
  font-size: 1em;
}

.microgive-team-micro-view {
  display: flex;
  justify-content: center;
}

.microgive-team-micro-text {
  color: #8f8f8f;
  font-family: 'ProximaNova-Regular';
  font-size: 0.813em;
}

.microgive-give-text {
  color: #ec1c24;
  font-family: 'ProximaNova-Regular';
  font-size: 0.813em;
}

.microgive-touch-text {
  color: #8f8f8f;
  font-family: 'ProximaNova-Regular';
  font-size: 0.813em;
}

.microgive-registerion-cancel-text {
  color: #000;
  font-family: 'ProximaNova-Bold';
  font-size: 1em;
}

.microgive-image-to-upload {
  font-size: 1.125em;
  font-family: 'ProximaNova-Regular';
}

.microgive-images-review {
  font-size: 0.75em;
  font-family: 'ProximaNova-Bold';
  color: #8f8f8f;
}

.microgive-upload-text {
  font-size: 1.125em;
  font-family: 'ProximaNova-Regular';
}

.microgive-images-got-uploaded {
  color: #000;
  font-family: 'ProximaNova-Bold';
  font-size: 1.125em;
}

.microgive-images-under-review {
  color: #000;
  font-family: 'ProximaNova-Regular';
  font-size: 1.125em;
}

.microgive-images-reviewed-by {
  color: #8f8f8f;
  font-family: 'ProximaNova-Regular';
  font-size: 0.75em;
}

.microgive-other-view {
  width: 100%;
  display: flex;
}

.microgive-other-item {
  background-color: #e9e9e9;
  max-width: 80%;
  border-radius: 7px;
  padding: 0.313rem;
  margin: 0.313rem;
  min-width: 40%;
  display: inline-block;
}

.microgive-self-view {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.microgive-item-image {
  max-width: 80%;
  align-self: flex-start;
}

.microgive-self-item-bubble {
  background-color: #e9e9e9;

  border-radius: 7px;
  padding: 0.313rem;
  margin: 0.313rem;
  min-width: 40%;
  align-self: flex-start;
}

.microgive-discussion-emp-name {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
  color: #000;
}

.microgive-discussion-date-text {
  font-size: 0.813em;
  font-family: 'ProximaNova-Regular';
  margin-left: 16px;
  color: #626262;
}

.microgive-categories {
  border-color: #d8d6d6;
  border-width: 1px;
  border-style: solid;
  margin-left: 0.313rem;
  margin-right: 0.313rem;
  padding-top: 0.313rem;
  padding-bottom: 0.313rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  cursor: pointer;
  font-size: 0.75em;
  font-family: 'ProximaNova-Bold';
}

.microgive-categories-selected {
  border-color: #d8d6d6;
  border-width: 1px;
  border-style: solid;
  margin-left: 0.313rem;
  margin-right: 0.313rem;
  padding-top: 0.313rem;
  padding-bottom: 0.313rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  background-color: #d8d6d6;
  cursor: pointer;
  font-size: 0.75em;
  font-family: 'ProximaNova-Bold';
}

.volunteer-date-microgive {
  font-size: 0.83em;
}

.microgive-volunteer-title {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
}

.microgive-donation-date-text {
  color: gray;
}

.microgive-initiative-type {
  border-color: gray;
  border-width: 1px;
  border-style: solid;
}

.microgive-tag-share-view {
  display: flex;
  justify-content: flex-end;
}

.microgive-share-image {
  height: 30px;
  width: 30px;
  margin-right: 0.625rem;
}

.microgive-item-data {
  background-color: #fff;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.microgive-title-project-text {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  font-size: 1.063em;
  font-family: 'ProximaNovaCond-Bold';
  color: #000;
  font-weight: bold;
}

.microgive-title-text {
  font-size: 1.125em;
  font-family: 'ProximaNova-Bold';
  color: #000;
}

.microgive-date-time-text {
  font-size: 0.9em;
  font-family: 'ProximaNova-Bold';
  color: #000;
}

.microgive-waitlist-text {
  color: red;
}

.microgive-cancel-button {
  background-color: #e8e8e8;
  border: 1px solid #e8e8e8;
  color: #6d6e71;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
}

.microgive-register-button {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
}

.microgive-cancel-confirmation-dialog {
  width: 100%;
  height: 140px;
  background: #ffffff;
}

.confirm-cancel-header-text {
  font-family: 'ProximaNova-Bold';
  font-size: 16px;
  margin-top: 20px;
  font-weight: 700;
}

.confirmation-modal-text {
  font-family: 'ProximaNova-Bold';
  font-size: 18px;
  margin-top: 10px;
  font-weight: 700;
}

.yes-close-button-container {
  flex-direction: 'row';
  margin-top: 40px;
  margin-left: 40px;
}

.yes-close-button {
  height: 40px;
  width: 90px;
  background-color: #ec1c24;
  border-radius: 4px;
  margin-left: 15px;
  margin-right: 15px;
  font-family: 'ProximaNova-Regular';
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.microgiver-certificate-text-style {
  font-family: 'ProximaNova-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.microgiver-fy-text {
  font-family: 'ProximaNovaCond-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  color: #6d6e71;
}

.yes-close-btn-text {
  font-family: 'ProximaNova-Regular';
  font-size: 16px;
  color: #ffffff;
}

.microgive-edit-dialog {
  width: 100%;
  height: 240px;
  background: #ffffff;
}

.custom-modal {
  border-radius: 10px;
  width: 70%;
}

.modal-style {
  border-radius: 10px;
  width: 200%;
}

.microgive-addguest-decreament {
  width: 30px;
  height: 30px;
  left: 701px;
  top: 599px;
  background: #ed1c24;
  border: 1px solid #f5f5f5;
  color: white;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  border-radius: 4px;
}

.microgive-addguest-disabled-decreament {
  width: 30px;
  height: 30px;
  left: 701px;
  top: 599px;
  background: grey;
  border: 1px solid #f5f5f5;
  color: white;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.microgive-addguest-increament {
  width: 30px;
  height: 30px;
  left: 701px;
  top: 599px;
  background: #ed1c24;
  border: 1px solid #f5f5f5;
  color: white;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  border-radius: 4px;
}

.microgive-addguest-disabled-increament {
  width: 30px;
  height: 30px;
  left: 701px;
  top: 599px;
  background: grey;
  border: 1px solid #f5f5f5;
  color: white;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.microgive-updates-title {
  font-family: 'ProximaNova-Bold';
  font-size: 18px;
}

.microgive-location-icon {
  height: 25px;
  width: 25px;
}

.microgive-participants {
  cursor: pointer;
}

.microgive-comment-icon {
  height: 25px;
  width: 25px;
}

.microgive-created-on-text {
  font-size: 0.85em;
  color: grey;
  font-family: 'ProximaNova-Regular';
}

.microgive-comment-count-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Bold';
  margin-left: 0.938rem;
}

.microgive-tag-count-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
  color: #fff;
  background-color: red;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  overflow: 'hidden';
  align-items: center;
  justify-content: center;
  display: flex;
}

.update-title {
  font-size: 20px;
  margin-left: 10px;
}

.microgive-price-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
}

.anniversary-watch-video {
  border-radius: 5px;
  background-color: #ec1c24;
}

.anniversary-watch-2-video {
  border-radius: 5px;
  border: 1px solid #ec1c24;
  background-color: #fff;
  border-width: 3px;
}

.anniversary-watch-video-container {
  width: 100%;
  flex: 1;
}

.microgive-dropdown {
  width: 130px;
}

.microgive-select-donation-amount-text {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
  color: #000;
}

.microgive-how-much-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
  color: #000;
}

.microgive-total-donors-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Bold';
  color: #000;
  font-weight: bold;
}

.microgive-category-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.625rem;
}

.microgive-donate-today-text {
  font-size: 0.75em;
  font-family: 'ProximaNova-Regular';
  color: #000;
}

.microgive-user-name {
  font-size: 1.125em;
  font-family: 'ProximaNova-Bold';
  color: #000;
  font-weight: bold;
}

.microgive-user-position {
  font-size: 1em;
  font-family: 'ProximaNovaCond-Bold';
  color: #323232;
}

.microgive-fy-style {
  font-size: 1em;
  font-family: 'ProximaNova-SemiBold';
  color: #000;
  margin-top: 1.25rem;
}

.microgive-volunteer-hour {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
  color: #f79738;
}

.microgive-volunteer-hr-text {
  font-size: 0.938em;
  font-family: 'ProximaNovaCond-Semibold';
  color: #000;
  margin-top: 0.313rem;
}

.microgive-microgive-donation-count {
  font-size: 1.063em;
  font-family: 'ProximaNova-Bold';
  color: #f79738;
}

.microgive-fundraiser-container {
  background-color: #eff4f8;
  border: 2px dotted #5591b7;
  padding: 20px;
  border-radius: 5px;
  margin-top: 12px;
  margin-bottom: 30px;
}

.microgive-polls-progress-bar-style {
  max-height: 10px;
  margin-bottom: 6px !important;
  background-color: #cddae5;
  height: 10px;
  border-radius: 100px;
}

.microgive-progress-bar-inner-style {
  background-color: #5591b7 !important;
  max-height: 10px;
  border-radius: 100px;
}

.microgive-row-style {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.microgive-fundraiser-list-item-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Semibold';
  color: #5591b7;
}

.microgive-fundraiser-bold-text {
  font-size: 1.5em;
  font-family: 'ProximaNovaCond-Semibold';
  color: #000;
}

.microgive-fundraiser-normal-text {
  font-family: 'ProximaNovaCond-Semibold';
  color: #000;
}

.microgive-fundraiser-faded-text {
  font-family: 'ProximaNovaCond-Semibold';
  color: #6d6e71;
}

.microgive-fundraiser-text-wrapper {
  flex: 5;
}

.microgive-progress-bar-wrapper {
  flex: 2;
}

.microgive-no-time-donated {
  font-size: 0.938em;
  font-family: 'ProximaNovaCond-Semibold';
  color: #000;
  margin-top: 0.313rem;
}

.microgive-text-underline {
  text-decoration: underline;
}

.microgive-share-faq-icon {
  height: 25px;
  width: 25px;
}

.microgive-btn-volunteer-log {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
}

.microgive-btn-view {
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
}

.microgive-click-here-text {
  font-size: 1.063em;
  font-family: 'ProximaNovaCond-SemiBold';
  color: #555555;
}

.microgive-upload-photos-btn {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  min-width: 120px;
  height: 35px;
  font-family: 'ProximaNova-Regular';
}

.microgive-bottom-view {
  align-items: center;
  justify-content: center;
}

.microgive-upload-icon {
  height: 80px;
  width: 80px;
}

.microgive-upper-view {
  background-color: #f6f6f6;
}

.microgive-total-hrs-text {
  font-size: 1.875em;
  font-family: 'ProximaNova-Bold';
  color: #000;
}

.microgive-no-time-volunteer-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
  color: #000;
}

.microgive-you-donating-text {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
  color: #000;
}

.microgive-awesome-text {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
  color: #000;
}

.microgive-download-certificate-container {
  width: '150px';
  align-self: 'center';
  display: 'flex';
  text-align: 'center';
  background-color: red;
  padding: '5px';
  flex-direction: column;
}

.microgive-certificate-text {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
  /* color: white; */
  color: black;
  display: flex;
}

.microgive-certificate-absolute-style {
  position: 'absolute';
  left: -20000px;
}

.microgive-abbrevation-view {
  display: flex;
  justify-content: center;
}

.microgive-abbrevation-text {
  font-size: 1.875em;
  font-family: 'ProximaNova-Bold';
  color: #8f8f8f;
}

.microgive-amount-text {
  font-size: 1.875em;
  font-family: 'ProximaNova-Bold';
  color: #8f8f8f;
}

.microgive-towards-text {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
}

.microgive-agree-tc {
  font-size: 0.938em;
  color: #8f8f8f;
}

.microgive-search-div {
  background-color: red;
}

.microgive-emp-image-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.625rem;
}

.microgive-emp-images-container-top {
  max-height: 300px;
  overflow-y: scroll;
}

.microgive-emp-images-container-bottom {
  max-height: 500px;
  overflow-y: scroll;
  margin-bottom: 1rem;
}

.microgive-check-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.microgive-employee-check-box {
  height: 16px;
  width: 16px;
  border: 2px solid #bdbcb9;
  margin-right: 0.313rem;
}

.microgive-bottom-button {
  position: fixed;
  bottom: 0;
  height: 40px;
  width: 100%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eb2027;
  font-family: 'Poppins-SemiBold';
  cursor: pointer;
}

.microgive-load-more {
  margin-bottom: 60px;
}

.microgive-donation-count-text {
  font-size: 1.875em;
  font-family: 'ProximaNova-Bold';
  color: #000;
}

.microgive-total-working-hrs-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
  color: #000;
}

.microgive-date-view-style {
  display: flex;
}

.microgive-discussion-emp-pronoun {
  font-size: 0.813em;
  color: #6d6e71;
}

.microgive-download_certificate-container {
  background-color: #ec1c24;
  padding: 5px 15px 5px 15px;
}

.microgive-download_certificate-text {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
  color: rgb(255, 255, 255);
  padding-left: 10px;
}

.microgive-download_certificate-icon {
  height: 16px;
  width: 16px;
}

.eightyg-certificate {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  color: #e30613;
  font-family: 'ProximaNova-SemiBold';
  margin-left: 5px;
  font-size: 12px;
}

.divider-width-style {
  width: 25%;
}

/* --------------- BREAKOUTS SCREEN --------------- */

.breakouts-view-style {
  background-color: #fff;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.breakouts-media-image-container {
  max-width: 28px;
  max-height: 28px;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.breakouts-media-image-container img {
  flex: 1;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  height: 100%;
}

.breakouts-title {
  margin-top: 0.313rem;
  font-size: 0.938em;
  font-family: 'ProximaNovaCond-Bold';
  cursor: pointer;
}

.breakouts-short-description {
  margin-top: 0.313rem;
  font-size: 0.813em;
  font-family: 'ProximaNovaCond-Bold';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.breakouts-button-style {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
  margin: 0 auto;
  display: block;
}

.container-session-details {
  padding: 2%;
}

.question-bg-color {
  background-color: #f1f1f1;
}

.breakouts-post-ans-button-style {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
  display: block;
}

.breakouts-waiting-admin-approval-text {
  font-size: 0.75em;
  font-family: 'ProximaNova-Regular';
  color: #ec1c24;
}

.breakouts-agenda-title-text {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
}

.breakouts-agenda-date-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
}

.breakouts-speakers-text {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
}

.breakouts-rate-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Bold';
  color: #ec1c24;
}

.breakouts-update-summary-text {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
}

.breakouts-likes-count-text {
  font-size: 0.875em;
}

.breakouts-comment-count-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Bold';
}

.breakouts-info-title {
  color: #000;
  font-family: 'ProximaNova-Bold';
  font-size: 1em;
}

.breakouts-date-view-style {
  display: flex;
}

.breakouts-no-of-photos {
  color: #000;
  font-family: 'ProximaNova-Regular';
  font-size: 0.938em;
}

.breakouts-date-recent-text {
  color: #000;
  font-family: 'ProximaNova-Regular';
  font-size: 0.938em;
}

.breakouts-gallery-date {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
  color: #000;
}

.breakouts-count-left-text {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
  color: #fff;
}

.breakouts-info-link-text {
  font-family: 'ProximaNova-SemiBold';
  text-decoration: underline;
  width: 90%;
}

.breakouts-info-video-text {
  font-family: 'ProximaNova-SemiBold';
}

.breakouts-session-text {
  font-size: 1.25em;
  font-family: 'ProximaNova-Bold';
}

.breakouts-selected-session-text {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
}

.breakouts-your-rating-text {
  font-size: 1.25em;
  font-family: 'ProximaNova-Bold';
}

.breakouts-card-main-title {
  font-family: 'ProximaNova-Bold';
  font-size: 1em;
}

.breakouts-session-description-text {
  font-family: 'ProximaNova-Regular';
  font-size: 0.938em;
}

.breakouts-session-questioner-title {
  font-family: 'ProximaNova-Bold';
  font-size: 1em;
  max-lines: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breakouts-session-questioner-title-answer {
  font-family: 'ProximaNova-Bold';
  font-size: 1em;
  max-lines: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breakouts-session-questioner-right-title {
  font-family: 'ProximaNova-Bold';
  font-size: 1em;
  text-align: end;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breakouts-session-questioner-subtitle {
  font-family: 'ProximaNova-Regular';
  font-size: 0.75em;
  color: #2c2c2c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breakouts-session-questioner-subtitle-end {
  font-family: 'ProximaNova-Regular';
  font-size: 0.75em;
  color: #2c2c2c;
  text-align: end;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breakouts-session-questioner-subtitle-answer {
  font-family: 'ProximaNova-Regular';
  font-size: 0.75em;
  color: #2c2c2c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 10px;
}

.breakouts-session-questioner-date-posted {
  font-family: 'Lato-Regular', sans-serif;
  font-size: 0.75em;
  color: #595959;
  text-align: center;
  width: 27%;
}

.breakouts-session-questioner-date-posted-answer {
  font-family: 'Lato-Regular', sans-serif;
  font-size: 0.75em;
  color: #595959;
  line-height: 10px;
}

.breakouts-questioner-answerer-line {
  height: 1px;
  background: #737373;
  width: 100%;
}

.session-questioner-answerer-block {
  width: 100%;
  justify-content: space-between;
}

.questioner-answerer-block {
  width: 35%;
}

.questioner-answerer-name-position {
  width: 90%;
}

.breakouts-session-title {
  font-size: 0.938em;
  font-family: 'ProximaNova-Regular';
}

.breakouts-dropdown-style {
  width: 400px;
}

.breakouts-rating-view {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.breakouts-bg-view {
  position: absolute;
  bottom: 0;
  right: 15px;
  background-color: red;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.breakouts-profile-image {
  height: 60px;
  width: 60px;
  border-radius: 30px;
}

.breakouts-donor-item {
  margin-top: 10px;
  margin-bottom: 10px;
}

.breakouts-normal-icon {
  width: 30px;
  height: 30px;
}

.breakouts-normal-icon-yt {
  width: 28px;
  height: 28px;
}

.breakouts-normal-icon-link {
  width: 25px;
  height: 25px;
}

.breakouts-rate-icon {
  width: 20px;
  height: 20px;
}

.breakouts-chat-list {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 500px !important;
}

.breakouts-send-button {
  background-color: #ec1c24;
  border: 5px #ec1c24;
  color: #fff;
  font-size: 1em;
  height: 100%;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
}

.breakouts-dropdown {
  width: 25%;
}

.breakouts-upload-icon {
  height: 80px;
  width: 80px;
}

.breakouts-images-got-uploaded {
  color: #000;
  font-family: 'ProximaNova-Bold';
  font-size: 1.125em;
}

.breakouts-images-under-review {
  color: #000;
  font-family: 'ProximaNova-Regular';
  font-size: 1.125em;
}

.breakouts-images-reviewed-by {
  color: #8f8f8f;
  font-family: 'ProximaNova-Regular';
  font-size: 0.75em;
}

.breakouts-bottom-view {
  align-items: center;
  justify-content: center;
}

.breakouts-upload-photos-btn {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  min-width: 120px;
  height: 35px;
  font-family: 'ProximaNova-Regular';
}

.breakouts-team-micro-view {
  display: flex;
  justify-content: center;
}

.breakouts-upload-text {
  font-size: 1.125em;
  font-family: 'ProximaNova-Regular';
}

.breakouts-agree-tc {
  font-size: 0.938em;
  color: #8f8f8f;
}

.breakouts-outer-view {
  display: flex;
  align-items: center;
}

.speaker-items {
  width: 100%;
}

.breakouts-profile-image-big {
  height: 50px;
  width: 50px;
  border-radius: 40px;
  overflow: 'hidden';
}

.breakouts-profile-image-small {
  height: 40px;
  width: 40px;
  border-radius: 30px;
  overflow: 'hidden';
}

.breakouts-latest-reply {
  margin-right: 30px;
  color: #88a8d8;
  font-family: 'ProximaNova-SemiBold';
  font-size: 12px;
}

.breakout-gallery-image {
  width: 100%;
}

.breakout-gallery-full-container {
  background-color: #000;
}

.breakout-gallery-full-image {
  height: 630px;
  width: 75%;
  object-fit: contain;
}

.breakout-content-html .embeddedContent {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  float: none;
}

.new-joinees-description-conatiner {
  overflow: hidden;
  max-width: 100%;
  height: auto;
  float: none;
}

.breakout-content-html iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.breakout-video-button-image {
  width: 28px;
  height: 28px;
}

.breakout-video-foreground-container {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.breakout-video-overview-image {
  width: 100%;
  object-fit: cover;
}

/* --------------- ANNIVERSARY SCREEN --------------- */
.anniversary-day-outer-view {
  height: 40px;
  width: 330px;
  border-width: 1px;
  background-color: #8aaad9;
  border-color: #8aaad9;
  border-radius: 50px;
  border-style: solid;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  align-items: center;
}

.anniversary-left-selected {
  background-color: #fff;
  border-radius: 50px;
  height: 30px;
}

.anniversary-right-selected {
  background-color: #fff;
  border-radius: 50px;
  height: 30px;
}

.anniversary-left-selected-text {
  color: #8aaad9;
  font-family: 'ProximaNova-SemiBold';
}

.anniversary-right-selected-text {
  color: #fff;
}

.anniversary-timer-upper-text {
  font-size: 22px;
  font-family: 'Poppins-SemiBold';
  color: #fff;
}

.anniversary-timer-lower-text {
  font-size: 11px;
  font-family: 'Poppins-SemiBold';
  color: #fff;
}

.anniversary-bg-view {
  position: absolute;
  width: 440px;
  bottom: 170px;
}

.anniversary-gallery-full-image {
  height: 630px;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.anniversary-gallery-full-container {
  background-color: #e9e9e9;
}

.anniversary-social-icon-content {
  position: absolute;
  bottom: 40px;
  right: 20px;
  z-index: 1;
}

.anniversary-gallery-count-container {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1;
  background-color: #ec1c24 !important;
  padding: 10px;
}

.anniversary-count-title {
  font-size: 1em;
  font-family: 'Poppins-SemiBold';
  color: #fff;
}

.anniversary-gallery-image {
  width: 100%;
  height: 186px;
}

.anniversary-wallpaper-view-content {
  width: 440px;
  position: relative;
  justify-content: center;
  display: flex;
}

.anniversary-year-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.anniversary-calender-button-style {
  background: #fff;
  border: #fff;
  color: #ec1c24;
  font-family: 'ProximaNovaCond-Bold';
  font-size: 1em;
}

.anniversary-calender-button-style:hover {
  text-decoration: none !important;
  color: #ec1c24 !important;
}

.anniversary-arrow-icon {
  width: 18px;
  height: 18px;
}

.anniversary-icon {
  height: 30px;
  width: 30px;
}

.anniversary-title-style {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
}

.anniversary-event-title {
  font-size: 18px;
  font-family: 'ProximaNova-Bold';
  color: #ec1c24;
}

.anniversary-reverse-time {
  background-color: #8f8f8f;
  font-size: 17;
  font-family: 'ProximaNova-Regular';
  font-weight: bold;
  color: #fff;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-self: center;
}

.anniversary-refresh-button {
  background-color: #8aaad9;
  align-self: flex-end;
  justify-content: flex-end;
}

.anniversary-your-position {
  color: #8aaad9;
  background-color: #fff;
  padding: 5px;
  display: flex;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  font-family: 'ProximaNova-Regular';
}

.anniversary-super-name {
  font-family: 'ProximaNova-Bold';
  color: #fff;
  font-size: 18px;
}

.cheers-points-text {
  font-family: 'ProximaNova-Regular';
  color: #fff;
  font-size: 14px;
}

.centered-placeholder {
  text-align: center;
}

.lock-icon-padding {
  margin-left: 5px;
}

.anniversary-super-bottom-view {
  background-color: #8aaad9;
}

.anniversary-message-item {
  font-family: 'ProximaNova-Regular';
  color: #1c1c1e;
  font-size: 1.25em;
  line-height: 30px;
}

.anniversary-dear-name-item {
  font-family: 'ProximaNova-Bold';
  font-size: 1.25em;
  color: #3a3a3a;
  font-weight: 400;
  margin-top: 20px;
}

.anniversary-sign-image {
  height: 100px;
  width: 100px;
}

.chief-regards-text {
  font-family: 'ProximaNova-Regular';
  font-size: 1.25em;
}

.chief-name-text {
  font-family: 'ProximaNova-Bold';
  font-size: 1.2em;
  font-weight: 400;
}

.anniversary-message-text {
  font-size: 0.813em;
  font-family: 'ProximaNova-Bold';
  font-weight: bold;
  color: #8aaad9;
}

.anniversary-watch-live-text {
  font-size: 1em;
  font-family: 'Poppins-SemiBold';
  color: #fff;
}

.anniversary-watch-2-live-text {
  font-size: 1em;
  font-family: 'Poppins-SemiBold';
  color: #ec1c24;
}

.anniversary-name-position-view {
  min-width: 500px;
}

.anniversary-take-pledge-btn {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
  margin: 0 auto;
  display: block;
}

.anniversary-pledge-header {
  background-color: #8aaad9;
  align-items: center;
  justify-content: center;
}

.anniversary-button {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
  margin: 0 auto;
  display: block;
}

.anniversary-watch-now {
  border-radius: 5px;
  background-color: #8aaad9;
  align-items: center;
  display: flex;
  justify-content: center;
}

.anniversary-play-video-icon {
  height: 25px;
  width: 25px;
}

.anniversary-live-now {
  border-radius: 5px;
  background-color: #ec1c24;
  align-items: center;
  display: flex;
  justify-content: center;
}

.anniversary-like-icon {
  height: 30px;
  width: 30px;
}

.anniversary-chat-list-live {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 250px !important;
}

.anniversary-wallpaper-view {
  height: 350px;
  width: 250px;
}

.anniversary-social-icon {
  position: absolute;
  bottom: 20px;
  right: 30px;
  z-index: 1;
}

.anniversary-wallpaper-item {
  position: relative;
  display: flex;
  justify-content: center;
}

.wallpaper-image-screenshot {
  height: 522px;
  width: 348px;
}

.anniversary-info-view {
  position: absolute;
  bottom: 100px;
}

.anniversary-info-view-content {
  position: absolute;
  bottom: 130px;
}

.anniversary-wallpaper-full-image {
  width: 440px;
}

.anniversary-employee-view {
  height: 400px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.anniversary-watch-live-view {
  background-color: #8f8f8f;
}

.anniversary-donor-item {
  margin-top: 10px;
  margin-bottom: 10px;
}

.anniversary-profile-image {
  height: 80px;
  width: 80px;
  border-radius: 40px;
  overflow: 'hidden';
}

.anniversary-outer-view {
  display: flex;
  align-items: center;
}

.anniversary-center-view {
  width: 100%;
}

.anniversary-user-name {
  font-size: 1.125em;
  font-family: 'ProximaNova-Bold';
  color: #000;
  font-weight: bold;
}

.anniversary-user-position {
  font-size: 1em;
  font-family: 'ProximaNovaCond-Bold';
  color: #323232;
}

.anniversary-view-style {
  background-color: #fff;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.anniversary-title {
  margin-top: 0.313rem;
  font-size: 0.938em;
  font-family: 'ProximaNovaCond-Bold';
}

.anniversary-short-description {
  margin-top: 0.313rem;
  font-size: 0.813em;
  font-family: 'ProximaNovaCond-Bold';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.anniversary-btn-wallpaper-ecards {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
}

.anniversary-btn-view {
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
}

.anniversary-chat-list {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 500px !important;
}

.anniversary-send-button {
  background-color: #ec1c24;
  border: 5px #ec1c24;
  color: #fff;
  font-size: 1em;
  height: 100%;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
}

.anniversary-date-view-style {
  display: flex;
}

.anniversary-name {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
}

.anniversary-position-text {
  font-size: 0.813em;
  font-family: 'ProximaNova-Regular';
  margin-top: 0.313rem;
  color: #aaa;
}

.anniversary-profile-image-small {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  overflow: 'hidden';
}

.anniversary-profile-image-small-overlay {
  margin-left: -16px;
}

.anniversary-tag-count-text {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
  color: #fff;
  background-color: red;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  text-align: center;
  margin-left: -16px;
}

.anniversary-normal-icon {
  width: 25px;
  height: 25px;
}

.anniversary-team-micro-view {
  display: flex;
  justify-content: center;
}

.anniversary-upload-icon {
  height: 80px;
  width: 80px;
}

.anniversary-image-to-upload {
  font-size: 1.125em;
  font-family: 'ProximaNova-Regular';
}

.anniversary-images-review {
  font-size: 0.75em;
  font-family: 'ProximaNova-Bold';
  color: #8f8f8f;
}

.anniversary-button-style {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
  margin: 0 auto;
  display: block;
}

.anniversary-moments-image {
  height: 400px;
  width: 100%;
}

.anniversary-agree-tc {
  font-size: 0.938em;
  color: #8f8f8f;
}

.anniversary-pledge-count {
  font-family: 'ProximaNova-Bold';
  color: #fff;
  font-size: 1.563em;
  font-weight: bold;
}

.anniversary-be-next-text {
  font-family: 'ProximaNova-Regular';
  color: #fff;
  font-size: 0.813em;
}

.anniversary-i-pledge-text {
  font-family: 'ProximaNova-Regular';
  color: #8f8f8f;
  font-size: 0.875em;
}

.anniversary-a-tag {
  color: #fff;
}

.anniversary-moment-date {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
  color: #626262;
  width: 100px;
}

.anniversary-tagged-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
  color: #626262;
}

.anniversary-hash-tag {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
  color: #626262;
}

.anniversary-likes-count-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
}

.anniversary-moment-read-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
  color: #ec1c24;
}

.anniversary-categories {
  border-color: #8aaad9;
  border-width: 1px;
  border-style: solid;
  margin-left: 0.313rem;
  margin-right: 0.313rem;
  padding-top: 0.313rem;
  padding-bottom: 0.313rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  cursor: pointer;
  font-size: 0.75em;
  color: #8aaad9;
  font-family: 'ProximaNova-Bold';
  border-radius: 20px;
}

.anniversary-categories-selected {
  border-color: #8aaad9;
  border-width: 1px;
  border-style: solid;
  margin-left: 0.313rem;
  margin-right: 0.313rem;
  padding-top: 0.313rem;
  padding-bottom: 0.313rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  background-color: #8aaad9;
  cursor: pointer;
  font-size: 0.75em;
  font-family: 'ProximaNova-Bold';
  color: #fff;
  border-radius: 20px;
}

.anniversary-search-div {
  background-color: red;
}

.anniversary-emp-image-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.625rem;
}

.anniversary-main-container {
  height: 100%;
  width: 100%;
  background-color: #fff;
}

.anniversary-body-container {
  padding: 1rem;
}

.anniversary-profile-name {
  text-align: center;
  margin-bottom: 0;
  font-size: 1.125em;
  font-family: 'ProximaNovaCond-SemiBold';
}

.anniversary-check-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.anniversary-employee-check-box {
  height: 16px;
  width: 16px;
  border: 2px solid #bdbcb9;
  margin-right: 0.313rem;
}

.anniversary-cd-title {
  font-size: 0.875em;
  font-family: 'ProximaNova-Bold';
  color: #000;
}

.anniversary-cd-desc {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
  color: #626262;
}

.anniversary-end-date {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
  color: #626262;
}

.anniversary-completed-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
  color: #fff;
  background-color: #000;
  border-radius: 15px;
  padding: 5px;
}

.anniversary-title-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Bold';
  color: #000;
}

.anniversary-start-date {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
  color: #626262;
}

.anniversary-contest-title {
  font-size: 0.875em;
  color: #000;
  font-weight: bold;
  font-family: 'ProximaNova-Bold';
}

/* --------------- CEO CHAT SCREEN --------------- */
.ceo-chat-add-comment {
  margin: 0.625rem;
}

.ceo-chat-reply-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
}

.ceo-chat-employee-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.ceo-chat-answer-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ceo-chat-answer-row-container {
  justify-content: space-between;
  margin-bottom: 0.625rem;
}

.ceo-chat-employee-details {
  margin-left: 1.25rem;
  width: 100%;
}

.ceo-chat-like-comment-row {
  margin-top: 0.65rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1.25rem;
  margin-bottom: 0.625rem;
}

.ceo-chat-icon-row {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: center;
}

.ceo-chat-icon-row-container {
  margin-right: 0.625rem;
}

.ceo-chat-icon-text {
  margin-left: 0.313rem;
  color: #88a8d8;
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
}

.ceo-chat-liked-view-spacing {
  margin-right: 0.625rem;
  margin-left: 0.625rem;
}

.ceo-chat-icon-container {
  margin-right: 0.313rem;
  margin-left: 0.313rem;
}

.ceo-chat-ceo-question-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ceo-chat-row-container {
  justify-content: space-between;
}

.ceo-chat-image-container {
  margin-bottom: 0.625rem;
}

.ceo-chat-image-div {
  border-radius: 50%;
}

.ceo-chat-ask-question {
  margin-right: 2.5rem;
  margin-left: 2.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.ceo-chat-ask-question-field textarea {
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 0;
  padding: 10px 10px 10px 10px;
  color: #676364;
  font-size: 0.813em;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  height: 150px;
}

.ceo-chat-ask-question-field {
  width: 100%;
}

.ceo-chat-ask-question-text-container {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.ceo-chat-ask-question-field textarea:focus {
  outline: 0 !important;
}

.ceo-chat-ceoBorder {
  background-color: #d5d5d5;
  height: 2px;
  margin-right: 1.25rem;
  margin-left: 1.25rem;
}

.ceo-chat-employee-image {
  height: 40px;
  width: 40px;
  border-radius: 100px;
}

.ceo-chat-answer-view {
  margin-right: 1.25rem;
  margin-left: 1.25rem;
}

.ceo-chat-first-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.625rem;
}

.ceo-chat-item-row {
  padding-right: 20px;
  border-bottom: 1px solid #dfdddd;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: 'center';
}

.ceo-chat-comment-row {
  width: 85%;
}

.ceo-chat-image {
  height: 100%;
  width: 100%;
  border-radius: 100px;
}

.ceo-chat-noContentView {
  width: 100%;
  height: 50%;
  justify-content: flex-start;
  align-items: center;
}

.ceo-chat-container {
  flex: 1;
  background-color: #f7f6f6;
}

.ceo-chat-ceo-comment-box {
  position: absolute;
  bottom: 20px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  width: 100%;
}

.ceo-chat-ceo-border {
  background-color: #ccc;
  height: 1px;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.ceo-chat-reply-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.ceo-chat-question-head {
  background-color: #dfdddd;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.ceo-chat-answer-item-view {
  border-top: 1px solid #cfcfcf;
  padding: 1rem;
}

.ceo-chat-border-empty-ans {
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 10px;
}

.ceo-chat-ceo-tagged-view {
  margin-bottom: 0.625rem;
  width: '100%';
  margin-top: 10px;
}

.ceo-chat-reply-button {
  color: #fff;
  font-size: 1em;
  display: inline-block;
  cursor: pointer;
}

.ceo-chat-header {
  height: 40px;
  margin-right: 1.25rem;
  margin-left: 1.25rem;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.ceo-chat-infoIcon {
  padding-right: 20px;
  cursor: pointer;
}

.ceo-chat-html-content p {
  font-size: 0.938em;
  font-family: 'ProximaNova-Regular';
}

.ceo-chat-header-view {
  display: flex;
  justify-content: flex-end;
}

.ceo-chat-ceo-container {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 1.25rem;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.ceo-chat-border {
  height: 2px;
  background-color: #ebebeb;
  margin-bottom: 0.625rem;
  margin-top: 1.25rem;
}

.ceo-chat-admin-container {
  margin-top: 0.313rem;
  margin-bottom: 0.313rem;
}

.ceo-chat-bottom-button {
  position: fixed;
  bottom: 0;
  height: 50px;
  width: 100%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eb2027;
  font-family: 'Poppins-SemiBold';
  cursor: pointer;
}

.ceo-chat-custom-btn-container {
  margin-top: 1.25rem;
  text-align: right;
}

.ceo-chat-main-container {
  height: 100%;
  width: 100%;
  background-color: #fff;
  margin-top: 20px;
}

.ceo-chat-body-container {
  padding: 1rem;
}

.ceo-chat-profile-name {
  text-align: center;
  margin-bottom: 0;
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
}

.ceo-chat-icon {
  cursor: pointer;
  width: 20px;
  margin-right: 2.5rem;
}

.ceo-chat-search-form {
  width: 60%;
}

.ceo-chat-search-bar {
  border: none;
  outline: 0;
  background: none;
  width: 100%;
  transition: width 0.4s linear;
}

.ceo-chat-search-div {
  margin-top: 20px;
  background-color: #eb2027;
  margin-right: 10px;
  margin-left: 10px;
  height: 40px;
  align-items: center;
}

.ceo-chat-name-text-container {
  text-align: center;
  width: 70px;
}

.ceo-chat-selected-employee-view {
  justify-content: center;
  align-items: flex-start;
  margin-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.ceo-chat-row {
  display: flex;
  flex-direction: row;
}

.ceo-chat-image-size {
  width: 35px;
  height: 35px;
}

.ceo-chat-emp-image-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.ceo-chat-check-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ceo-chat-employee-check-box {
  height: 16px;
  width: 16px;
  border: 2px solid #bdbcb9;
  margin-right: 5px;
}

.ceo-chat-load-more {
  margin-bottom: 3.75rem;
}

input#ceo-chat-employee-check-box input:checked + label:before {
  background-color: #eb2027;
}

.ceo-chat-read-style {
  color: #8aaad9;
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
}

.ceo-chat-question-text {
  font-size: 0.875em;
  color: #383839;
  font-family: 'ProximaNova-Regular';
}

.ceo-chat-question-emp-name-text {
  font-size: 0.875em;
  font-family: 'Lato-Bold';
}

.ceo-chat-ques-emp-date-text {
  font-size: 12px;
  font-family: 'ProximaNova-Regular';
  color: #383839;
}

.ceo-chat-ceo-like-icon {
  width: 20px;
  height: 20px;
}

.ceo-chat-ceo-comment-icon {
  width: 20px;
  height: 20px;
}

.ceo-chat-ceo-reply-icon {
  width: 20px;
  height: 20px;
}

.ceo-chat-latest-reply-text {
  font-size: 0.75em;
  color: #8aaad9;
  font-family: 'Lato-Regular', sans-serif;
}

.ceo-chat-reply-waiting-text {
  font-size: 0.75em;
  color: #ec1b25;
  font-family: 'Lato-Regular', sans-serif;
}

.ceo-chat-ask-question-text {
  text-align: center;
  font-family: 'ProximaNovaCond-SemiBold';
  font-size: 1.125em;
}

.ceo-chat-ceo-comment-date {
  font-size: 0.75em;
  font-family: 'Lato-Regular', sans-serif;
  color: #4d4d4d;
}

.ceo-chat-ceo-comment-text {
  font-size: 0.813em;
  font-family: 'Lato-Regular', sans-serif;
  color: #4d4d4d;
}

.ceo-chat-ceo-reply-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
}

.ceo-chat-ceo-tag-text {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  color: #4472c4;
}

.ceo-chat-ceo-tag-text-label {
  font-family: 'ProximaNova-SemiBold';
  font-size: 12px;
}

.ceo-chat-tagged-emp-pfp {
  height: 22px;
  width: 22px;
  border-radius: 20px;
  margin-right: 10px;
}

.ceo-chat-ceo-admin-text {
  font-size: 0.75em;
  font-family: 'Poppins-SemiBold';
  text-align: center;
  color: #8aaad9;
}

.ceo-chat-tag-prople-text {
  color: #000;
  font-size: 1.125em;
  font-family: 'Lato-Bold';
  margin-left: 0.625rem;
}

.ceo-chat-question-emp-pronoun-text {
  font-size: 12px;
  color: #757575;
  line-height: 14px;
  font-family: 'ProximaNova-Regular';
}

.ceo-chat-answer-read-style {
  color: #8aaad9;
}

/* --------------- POSH SCREEN --------------- */
.posh-html-content {
  font-size: 0.938em;
}

.posh-custom-modal-header {
  border-bottom: none !important;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.posh-custom-modal-footer {
  border-top: none !important;
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  margin-top: 1.5rem;
  justify-content: center;
}

.posh-modal-btn {
  width: 120px;
  border-radius: 0;
}

.posh-custom-modal-title {
  width: 100%;
  text-align: center;
  font-family: 'ProximaNova-SemiBold';
}

.posh-refer-btn {
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
  font-size: 1em;
  width: 150px;
  height: 40px;
  text-align: center !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.posh-refer-btn:hover {
  text-decoration: none !important;
  color: #fff !important;
}

.posh-concern-btn {
  background-color: #eb2027;
  border: 1px solid #eb2027;
  color: #fff;
  font-size: 1em;
  width: 150px;
  height: 40px;
  text-align: center !important;
}

.posh-concern-btn:focus {
  outline: 0 !important;
}

.posh-custom-modal-body {
  text-align: center;
}

.posh-raise-concern-editable-text-area {
  background-color: #fff !important;
  min-height: 170px;
  border-radius: 4px;
  font-size: 1em;
}

/* --------------- SOM SCREEN --------------- */

.som-title {
  margin-top: 0.313rem;
  font-size: 0.938em;
  font-family: 'ProximaNovaCond-Bold';
}

.som-short-description {
  margin-top: 0.313rem;
  font-size: 0.813em;
  font-family: 'ProximaNova-Regular';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  color: #828282;
  -webkit-box-orient: vertical;
}

.som-outer-view {
  display: flex;
  align-items: center;
}

.som-event-image-container {
  margin-bottom: -5px;
}

.som-description-text {
  font-size: 1em;
  color: #828282;
  font-family: 'ProximaNova-Regular';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.som-tag-main-container {
  height: 100%;
  width: 100%;
  background-color: #fff;
}

.som-tag-emp-image-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.625rem;
}

.som-tag-body-container {
  padding: 1rem;
}

.som-tag-profile-name {
  text-align: center;
  margin-bottom: 0;
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
}

.som-tag-icon {
  cursor: pointer;
  width: 20px;
}

.som-tag-search-form {
  width: 90%;
}

.som-tag-search-bar {
  border: none;
  outline: 0;
  background: none;
  width: 100%;
  transition: width 0.4s linear;
}

.som-tag-search-div {
  background-color: #eb2027;
  height: 40px;
  align-items: center;
}

.som-tag-name-text-container {
  text-align: center;
  width: 70px;
}

.som-tag-selected-employee-view {
  justify-content: center;
  align-items: flex-start;
  margin-left: 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

.som-tag-row {
  display: flex;
  flex-direction: row;
}

.som-tag-image-div {
  border-radius: 100px;
}

.som-tag-image-size {
  width: 55px;
  height: 55px;
}

.som-tag-empimage-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.625rem;
}

.som-tag-check-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.som-tag-employee-check-box {
  height: 16px;
  width: 16px;
  border: 2px solid #bdbcb9;
  margin-right: 0.313rem;
}

.som-tag-bottom-button {
  position: fixed;
  bottom: 0;
  height: 50px;
  width: 100%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eb2027;
  font-family: 'Poppins-SemiBold';
  cursor: pointer;
}

.som-tag-load-more {
  margin-bottom: 60px;
}

input#som-tag-employee-check input:checked + label:before {
  background-color: #eb2027;
}

.som-event-star-image {
  width: 100%;
  object-fit: cover;
}

.som-event-tag-view {
  display: flex;
  height: 70px;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  align-items: center;
}

.som-event-tagged-view {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.som-event-image-outer {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  margin-left: -0.313rem;
  margin-bottom: -0.313rem;
}

.som-event-image-size-style {
  display: flex;
  background-color: #8aaad9;
  justify-content: center;
  align-items: center;
}

.som-event-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.som-foreword-view {
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  margin-right: 1.25rem;
  margin-left: 1.25rem;
  display: flex;
  flex-direction: column;
}

.som-foreword-image-outer {
  width: 60px;
  height: 60px;
  background-color: #ccc;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.som-forward-load-image {
  width: 100%;
  height: 100%;
}

.som-forward-summary-container {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  text-align: center;
}

.som-forward-name-container {
  margin-bottom: 0.625rem;
}

.som-forward-screen-image-outer {
  width: 220px;
  height: 220px;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.som-forward-screen-image {
  width: 100%;
  height: 100%;
}

.som-forward-screen-center-size {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.som-forward-screen-summary-container {
  margin-bottom: 1.25rem;
  text-align: center;
  margin-right: 1.25rem;
  margin-left: 1.25rem;
}

.som-forward-screen-name-container {
  margin-bottom: 10px;
}

.som-main-star-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.som-main-image-text-container {
  padding-right: 0.313rem;
  padding-left: 0.313rem;
  padding-top: 0.313rem;
  padding-bottom: 0.313rem;
}

.som-employee-view {
  margin-right: 0.313rem;
  margin-left: 0.313rem;
  margin-top: 0.313rem;
  margin-bottom: 0.313rem;
}

.som-business-awardee-view {
  margin-right: 0.313rem;
  margin-left: 0.313rem;
  margin-top: 0.313rem;
  margin-bottom: 0.313rem;
  border-bottom: 1px solid #d5d5d5;
}

.som-emp-inner-view {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.som-business-emp-inner-view {
  width: 10%;
  display: flex;
}

.som-emp-image-outer {
  width: 60px;
  height: 60px;
  background-color: #ccc;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.som-business-image-outer {
  width: 90px;
  height: 90px;
  background-color: #ccc;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.som-individual-emp-image-outer {
  width: 90px;
  height: 90px;
  background-color: #ccc;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border-radius: 50%;
}

.som-emp-image {
  width: 100%;
  height: 100%;
}

.som-individual-emp-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.som-emp-name-container {
  margin-bottom: 0.625rem;
  text-align: center;
}

.som-business-name-container {
  margin-bottom: 0.4rem;
}

.som-award-name-container {
  margin-bottom: 0.625rem;
  text-align: center;
}

.som-emp-border {
  border-bottom: 1px solid #d5d5d5;
}

.som-emp-view-inner-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.som-emp-view-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.som-business-awardee-view-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.som-star-view {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.som-testimonial-view {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: #f7f7f7;
  margin-bottom: 1.25rem;
}

.som-star-icon-container {
  margin-top: -20px;
}

.som-title-conatiner {
  margin-top: 0.625rem;
  margin-bottom: 1.25rem;
}

.som-summary-container {
  margin-bottom: 0.625rem;
}

.som-tilde-container {
  font-size: 0.875em;
}

.som-star-icon-size {
  width: 50px;
  height: 50px;
}

.som-employee-screen-web-view {
  margin-bottom: 1.25rem;
  border-bottom: 1px solid #d5d5d5;
}

.som-employee-screen-image-outer {
  width: 180px;
  height: 180px;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.som-employee-screen-border-view {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
  margin-bottom: 1.25rem;
  width: 100%;
  align-items: center;
}

.som-detail-border {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d5d5d5;
  margin-bottom: 1.25rem;
  width: 100%;
}

.som-employee-screen-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.som-employee-screen-image {
  width: 100%;
  height: 100%;
}

.som-employee-screen-scroll-outer-view {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.som-employee-screen-name-container {
  margin-bottom: 0.625rem;
}

.som-employee-screen-position-container {
  margin-bottom: 0.313rem;
}

.som-employee-screen-joining-container {
  margin-bottom: 1.25rem;
}

.som-employee-screen-summary-container {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.som-employee-screen-html-content p {
  font-size: 0.938em;
  font-family: 'ProximaNova-Regular';
}

.som-employee-screen-icon-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.som-employee-screen-icon-row-container {
  margin-right: 0.625rem;
}

.som-testimonial-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.som-employee-screen-icon-text {
  cursor: pointer;
  margin-left: 0.313rem;
  color: #88a8d8;
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
}

.som-gallery-tagged-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  align-items: center;
  height: 70px;
  border-bottom: 1px solid #ebebeb;
}

.som-gallery-row {
  display: flex;
  flex-direction: row;
  height: 70px;
  align-items: center;
  margin-right: 1.25rem;
  margin-left: 1.25rem;
}

.som-gallery-employee-image {
  height: 50px;
  width: 50px;
  border-radius: 100px;
  margin-right: 1.25rem;
}

.som-gallery-image {
  height: 100%;
  width: 100%;
  border-radius: 100px;
}

.som-gallery-screen-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.som-gallery-image-div {
  height: 300px;
}

.som-employee-content-name-text {
  font-size: 1.125em;
  font-family: 'ProximaNova-Bold';
  color: #ec1c24;
}

.som-employee-content-position-text {
  font-size: 0.938rem;
  font-family: 'ProximaNova-Regular';
}

.som-employee-content-joining-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
}

.som-employee-content-summary-text {
  font-size: 1.125em;
  font-family: 'ProximaNova-Bold';
  color: #ec841c;
  text-align: center;
}

.som-tag-icon-size {
  height: 25px;
  width: 25px;
}

.som-gallery-tag-text {
  font-size: 1.063em;
  font-family: 'ProximaNovaCond-Bold';
}

.som-gallery-employee-name-text {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
}

.som-gallery-employee-pos-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
  color: #2c2c2c;
}

.som-forward-summary-text {
  font-size: 1em;
  font-family: 'Lato-Regular', sans-serif;
  color: #8aaad9;
}

.som-foreword-name-text {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
  color: #000;
}

.som-event-tag-text {
  font-family: 'ProximaNova-Bold';
  font-size: 0.875em;
}

.som-tag-more-text {
  color: #fff;
  font-family: 'ProximaNovaCond-SemiBold';
  font-size: 0.875em;
}

.som-event-emp-image {
  width: 100%;
  height: 100%;
  border-radius: 100px;
}

.som-star-employee-name-text {
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
}

.som-business-awardee-name-text {
  font-size: 1.1em;
  font-family: 'ProximaNova-Regular';
}

.som-business-award-text {
  font-size: 18px;
  font-family: 'ProximaNova-Bold';
}

.som-year-name-text {
  font-size: 0.75em;
  color: #828282;
  font-family: 'ProximaNova-Bold';
}

.business-awardee-position-title {
  text-align: center;
  margin-bottom: 0;
  font-size: 14px;
  font-family: 'ProximaNova-Regular';
  color: #757575;
}

.som-testimonial-title {
  font-family: 'Lato-Bold';
  font-size: 0.875em;
}

.som-testimonial-summary-text {
  font-size: 1em;
  font-family: 'Lato-Regular', sans-serif;
  font-style: italic;
  color: #4d4d4d;
}

.som-testimonial-employee-text {
  font-family: 'Lato-Bold';
  font-size: 0.875em;
}

.som-testimonial-designation-text {
  color: #4d4d4d;
  font-family: 'Lato-Bold';
  font-size: 0.875em;
}

.som-year-icon-size {
  height: 35px;
  width: 35px;
}

/* --------------- WOMEN'S DAY SCREEN --------------- */
.womens-day-timer-count-text {
  font-size: 1.25em;
  font-family: 'ProximaNovaCond-SemiBold';
  color: #fff;
}

.womens-day-refresh-size {
  width: 25px;
  height: 25px;
}

.womens-day-youtube-spacing {
  margin-bottom: 20px;
}

.womens-day-timer-view {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d5d5d5;
}

.womens-day-refresh-view {
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #8aaad9;
}

.womens-day-bottom-button {
  color: #fff;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
}

.womens-day-bottom-button:hover {
  text-decoration: none !important;
  color: #fff !important;
}

.womens-day-bottom-button-container {
  width: 100%;
  justify-content: center;
  display: flex;
}

.womens-day-other-view {
  width: 100%;
  display: flex;
}

.womens-day-other-item {
  background-color: #e9e9e9;
  max-width: 80%;
  border-radius: 12px;
  padding: 5px;
  margin: 5px;
  min-width: 40%;
  display: inline-block;
}

.womens-day-divider {
  margin-top: 10px;
  background-color: #d5d5d5;
}

.womens-day-gallery-tab-image {
  width: 100%;
}

.womens-day-more-view {
  position: absolute;
  bottom: 0;
  right: 15px;
  background-color: red;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.womens-day-gallery-container {
  padding: 0px !important;
}

.womens-day-row-container {
  margin-left: 0.65rem;
}

.womens-day-self-view {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.womens-day-self-item {
  background-color: #e9e9e9;
  max-width: 80%;
  border-radius: 12px;
  padding: 0.313rem;
  margin: 0.313rem;
  min-width: 40%;
}

.womens-day-team-view {
  display: flex;
  justify-content: center;
}

.womens-day-upload-container {
  margin-top: 2.5rem;
  align-self: center;
}

.womens-day-uplaod-text-view {
  width: 70%;
  text-align: center;
}

.womens-day-btn-row {
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.womens-day-text-margin {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.womens-day-custom-dialog-style {
  padding: null;
  padding-top: null;
}

.womens-day-date-view-style {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.womens-day-success-icon-container {
  margin-top: 2.5rem;
  align-self: center;
}

.womens-day-button-style {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.womensday-button-style {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
  margin: 0 auto;
  display: block;
}

.womens-day-main-view {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.womens-day-select-images-toupload-container {
  margin-top: 1.25rem;
  text-align: center;
  margin-right: 1rem;
  margin-left: 1rem;
}

.womens-day-images-review-container {
  margin-top: 1.25rem;
  text-align: center;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-bottom: 1.5rem;
}

.womens-day-images-under-review-container {
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 0.625rem;
  margin-bottom: 3.75rem;
  text-align: center;
}

.womens-day-images-reviewedby-container {
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  text-align: center;
}

.womens-day-image-item {
  width: 100%;
}

.womens-day-images-reviewed-by {
  color: #8f8f8f;
  font-family: 'ProximaNova-Regular';
  font-size: 0.75em;
}

.womens-day-images-under-review {
  color: #000;
  font-family: 'ProximaNova-Regular';
  font-size: 1.125em;
}

.womens-day-upload-text {
  font-size: 1.125em;
  font-family: 'ProximaNova-Regular';
}

.womens-day-images-review {
  font-size: 0.75em;
  font-family: 'ProximaNova-Bold';
  color: #8f8f8f;
}

.womens-day-select-images-toupload {
  font-size: 1.125em;
  font-family: 'ProximaNova-Regular';
}

.womens-day-upload-icon {
  height: 80px;
  width: 80px;
}

.womens-day-message-item {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.womens-day-cpo-name-text-container {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  margin-right: 0.938rem;
  margin-left: 0.938rem;
}

.womens-day-signature-container {
  margin-left: 1.25rem;
}

.womens-day-background-image {
  width: 100%;
  height: 100%;
}

.womens-day-convo-tab-comment-box {
  position: absolute;
  bottom: 1.25rem;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  width: 100%;
}

.womens-day-central-image-row {
  height: 90px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.womens-day-image-div {
  border-radius: 100px;
  margin-right: 0.938rem;
}

.womens-day-image {
  width: 100%;
  object-fit: cover;
}

.womens-day-event-text-spacing {
  margin-left: 2.5rem;
  margin-top: 1.875rem;
  margin-bottom: 0.625rem;
}

.womens-day-row-distance {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.womens-day-video-view {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.womens-day-live-view {
  background-color: #eb2027;
  height: 40px;
  padding: 0.188rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-right: 0.313rem;
  padding-left: 0.313rem;
  min-width: 240px;
}

.womens-day-live-now-view {
  background-color: #eb2027;
  height: 70px;
  padding-top: 0.313rem;
  padding-bottom: 0.313rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.313rem;
  flex-direction: row;
}

.womens-emp-name {
  font-size: 0.875em;
  margin-top: 0.313rem;
  color: #000;
  font-family: 'ProximaNova-Bold';
}

.womens-day-central-place-text {
  font-size: 0.875em;
  font-family: 'Lato-Regular', sans-serif;
  color: #626262;
}

.womens-day-event-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
}

.before-streaming-note {
  font-size: 12px;
  font-family: 'ProximaNova-Regular';
}

.womens-day-live-now-spacing {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  margin-left: 0.625rem;
}

.womens-day-central-border {
  height: 1px;
  background-color: #e4e4e4;
  margin-top: 0.625rem;
  margin-bottom: 0.8rem;
}

.womens-day-central-survey-border {
  height: 1px;
  background-color: #e4e4e4;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.womens-day-community-title-spacing {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.womens-day-community-subtitle-spacing {
  margin-bottom: 0.625rem;
}

.womens-day-btn-view {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.womens-day-play-video-container {
  margin-right: 1.25rem;
}

.womens-day-watch-live-icon-container {
  margin-right: 1.25rem;
}

.womens-day-live-video-icon-container {
  margin-right: 0.625rem;
}

.womens-day-emp-name-container {
  margin-bottom: 0.625rem;
}

.womens-day-gallery-date {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
}

.womens-day-discuss-emp-name {
  font-size: 0.813em;
  font-family: 'ProximaNova-Bold';
  color: #626262;
}

.womens-day-central-container {
  margin-bottom: 3.125rem;
}

.womens-day-watch-video-discussion {
  margin-left: 0.65rem;
}

.womens-day-comment-box {
  margin-left: 0.625rem;
}

.womens-day-discussion-date {
  font-size: 0.813em;
  font-family: 'ProximaNova-Regular';
  margin-left: 16px;
  color: #626262;
}

.womens-day-more-view-container {
  background-color: #ec1c24;
}

.womens-day-count-left-text {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
  color: #fff;
}

.womend-day-emp-image {
  width: 100%;
  height: 100%;
  border-radius: 100px;
}

.womens-day-profile-image {
  height: 80px;
  width: 80px;
}

.womens-day-emp-name {
  font-size: 0.875em;
  font-family: 'Lato-Bold';
}

.womens-day-central-place-tex {
  font-size: 0.813em;
  font-family: 'Lato-Regular', sans-serif;
  color: #626262;
}

.womens-day-time-text {
  font-family: 'Lato-Bold';
  font-size: 1.75em;
}

.womens-day-live-video-size {
  width: 20px;
  height: 20px;
}

.womens-day-live-text {
  font-family: 'Poppins-SemiBold';
  font-size: 0.875em;
  color: #fff;
}

.womens-day-play-video-size {
  width: 25px;
}

.womens-day-watch-live-icon-size {
  width: 25px;
}

.womens-day-watch-text {
  font-family: 'Poppins-SemiBold';
  font-size: 1em;
  color: #fff;
}

.womens-day-community-title {
  font-size: 0.875em;
  font-family: 'ProximaNovaCond-Semibold';
}

.womens-day-cpo-message-text {
  font-family: 'Satisfy-Regular', cursive;
  color: #626262;
  font-size: 1.25em;
}

.womens-day-cpo-name-text {
  font-family: 'Satisfy-Regular', cursive;
  color: #626262;
  font-size: 1.25em;
}

.womens-day-cpo-signature-size {
  width: 150px;
  height: 150px;
}

.womens-day-upload-size {
  width: 100px;
  height: 100px;
}

.womens-day-success-icon {
  margin-top: 40px;
  width: 100px;
  height: 100px;
  align-self: center;
}

.womens-day-images-got-uploaded {
  color: #000;
  font-family: 'ProximaNova-Bold';
  font-size: 1.125em;
  margin-right: 16px;
  margin-top: 16px;
  text-align: center;
}

.womens-day-no-of-photos {
  color: #000;
  font-family: 'ProximaNova-Regular';
  font-size: 0.938em;
  display: flex;
  flex: 1;
}

.womens-day-date-recent-text {
  color: #000;
  font-family: 'ProximaNova-Regular';
  font-size: 0.938em;
  display: flex;
  flex: 1;
  text-align: right;
  margin-right: 0.625rem;
}

.womens-day-recent-date-icon {
  height: 25px;
  width: 25px;
}

.womens-day-upload-bottom-button {
  color: #fff;
  font-size: 1em;
  display: inline-block;
  cursor: pointer;
}

.womens-day-timer-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.womens-day-upload-photos-button-text {
  color: #ec1c24;
  font-family: 'Poppins-SemiBold';
  font-size: 0.938em;
}

.womens-day-title-text {
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
}

.womens-day-description-text {
  font-size: 0.875em;
  color: #828282;
  font-family: 'ProximaNova-Regular';
}

.womens-day-gallery-full-container {
  background-color: #000;
}

.womens-day-gallery-full-image {
  height: 630px;
  width: 75%;
  object-fit: contain;
}

.commitment-text-container {
  margin-left: 5px;
  width: 590px;
}

.commitment-text-commited-container {
  width: 600px;
}

.commitment-text-style {
  font-family: 'ProximaNova-Regular';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.commitment-text-area {
  height: 40px;
  padding: 0.5em;
  width: 600px;
  border-color: #e5e5e5;
}

.text-area-number-text {
  font-family: 'ProximaNova-Regular';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.commitment-plus-icon {
  height: 24px;
  width: 24px;
}

.commit-button-container {
  width: 578px;
}

.commit-download-button-container {
  width: 590px;
}

.commit-button-style {
  width: 130px;
  height: 36px;
  border-radius: 4px;
  background-color: #4472c4;
  color: #ffffff;
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.commit-download-style {
  width: 230px;
  height: 36px;
  border-radius: 4px;
  background-color: #4472c4;
  color: #ffffff;
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.commitment-success-modal-confirm {
  width: 124px;
  height: 38px;
  background: #f7f7f9;
  color: #ec1b25;
  text-align: center;
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}

.commitment-library-modal-container {
  width: 650px;
  height: 'auto';
  border-radius: 6px;
  background-color: #ffffff;
}

.library-title-container {
  font-family: 'ProximaNova-Regular';
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
}

.library-modal-cancel-container {
  width: 130px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #909090;
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
}

.library-modal-select-container {
  width: 130px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #909090;
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  background-color: #4472c4;
  color: #ffffff;
}

.library-radio-label {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.01em;
}

/* --------------- BUSINESS DASHBOARD SCREEN --------------- */
.business-dashboard-banner-image {
  width: 100%;
}

/* --------------- CHEERS SCREEN --------------- */
.cheers-reward-count {
  color: #000;
  font-size: 14px;
  font-family: 'ProximaNova-Regular';
}

.cheers-icon-text-view {
  background-color: #8aaad9;
  padding: 7px;
  border-radius: 7px;
}

.cheers-selected-button-style {
  background-color: #8aaad9;
  border-width: 1px;
  border-radius: 5px;
  border: 1px solid #8aaad9;
  color: #fff;
  font-size: 16px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
  margin: 0 auto;
  display: block;
}

.allocate-points-individually {
  cursor: pointer;
  color: #8aaad9;
  font-family: 'ProximaNova-Regular';
}

.cheers-not-selected-button-style {
  background-color: #fff;
  border-width: 1px;
  border-radius: 5px;
  border: 1px solid #8aaad9;
  color: #8aaad9;
  font-size: 16px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
  margin: 0 auto;
  display: block;
}

.cheers-not-selected-button-style:hover {
  text-decoration: none !important;
  color: #8aaad9 !important;
}

.cheers-success-icon {
  height: 80px;
  width: 80px;
}

.cheers-date-view-style {
  display: flex;
}

.cheers-like-comment {
  display: flex;
  justify-content: flex-end;
  align-self: center;
}

.cheers-center-view {
  width: 100%;
}

.cheers-profile-image {
  height: 80px;
  width: 80px;
  border-radius: 40px;
  overflow: 'hidden';
}

.cheers-normal-icon {
  width: 25px;
  height: 25px;
}

.cheers-download-icon {
  width: 20px;
  height: 20px;
}

.eightyg-download-icon {
  width: 15px;
  height: 15px;
}

.cheers-button-style {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
  margin: 0 auto;
  display: block;
}

.cheers-bottom-view {
  background-color: #000;
}

.cheers-team-micro-view {
  display: flex;
  justify-content: center;
}

.cheers-outer-view {
  display: flex;
  align-items: center;
}

.cheers-donate-points-text {
  color: #fff;
  font-size: 14px;
  font-family: 'ProximaNova-Regular';
}

.cheers-emp-name {
  font-size: 14px;
  color: #000;
  font-family: 'ProximaNova-Bold';
}

.cheers-emp-position {
  font-size: 14px;
  color: #000;
  font-family: 'ProximaNova-Regular';
}

.all_induction_emp-position {
  color: #757575;
  font-size: 14px;
  font-family: 'ProximaNova-Regular';
}

.all-induction-session-schedule-text {
  font-size: 15px;
  color: #757575;
  font-family: 'ProximaNova-Regular';
  line-height: 17px;
}

.all-induction-schedule-date {
  font-size: 15px;
  color: #000;
  font-family: 'ProximaNova-Regular';
  line-height: 17px;
}

.cheers-self-position {
  background-color: #ffffff;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  color: #8aaad9;
  font-family: 'ProximaNova-Regular';
  margin-left: 10px;
  padding-left: 5px;
  padding-right: 5px;
  width: 50%;
}

.cheers-desc-text {
  font-size: 12px;
  color: #000;
  font-family: 'ProximaNova-Regular';
  font-weight: bold;
}

.cheers-award-title-text {
  color: #fff;
  font-size: 11px;
  font-family: 'ProximaNova-Bold';
  white-space: nowrap;
}

.cheers-award-min-height {
  min-height: 40px;
}

.cheers-reward-count-remaining {
  background-color: #8aaad9;
  padding: 7;
  border-radius: 20px;
  min-height: 40px;
  min-width: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  font-family: 'ProximaNova-Bold';
  font-size: 11px;
}

.cheers-show-hide-detail-text {
  font-size: 14px;
  font-family: 'ProximaNova-Regular';
}

.cheers-citation-text {
  font-size: 14px;
  font-family: 'ProximaNova-Regular';
  word-break: break-all;
}

.cheers-reward-points-second-text {
  font-size: 13px;
  color: #000;
  font-family: 'ProximaNova-Regular';
}

.cheers-nom-user-text {
  font-size: 13px;
  color: #000;
  font-family: 'ProximaNova-Regular';
}

.cheers-nom-rec-view {
  background-color: #edf2fa;
}

.cheers-nom-rec-detail-view {
  background-color: #ffffff;
}

.cheers-categories-selected {
  border-color: #8aaad9;
  border-width: 1px;
  border-style: solid;
  margin-left: 0.313rem;
  margin-right: 0.313rem;
  padding-top: 0.313rem;
  padding-bottom: 0.313rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  background-color: #8aaad9;
  cursor: pointer;
  font-size: 0.75em;
  font-family: 'ProximaNova-Bold';
  color: #fff;
  border-radius: 20px;
}

.cheers-date-text {
  color: #8f8f8f;
  font-size: 13px;
  font-family: 'ProximaNova-Regular';
}

.cheers-award-type {
  border-color: #8aaad9;
  border-width: 1px;
  margin-left: 0.313rem;
  margin-right: 0.313rem;
  padding-top: 0.313rem;
  padding-bottom: 0.313rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  background-color: #fff;
  cursor: pointer;
  font-size: 0.75em;
  font-family: 'ProximaNova-Bold';
  color: #8aaad9;
  border-style: dashed;
}

.cheers-desc-text-style {
  color: #000;
  font-size: 13px;
  font-family: 'ProximaNova-Bold';
}

.cheers-given-nominate-text {
  color: #000;
  font-size: 13px;
  font-family: 'ProximaNova-Regular';
}

.cheers-show-hide-view {
  display: flex;
  justify-content: flex-end;
  flex: auto;
}

.cheers-show-hide-detail {
  font-size: 14px;
  font-family: 'ProximaNova-Regular';
}

.cheers-upper-view {
  background-color: #f1f3f8;
  height: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cheers-below-view {
  height: 400px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cheers-total-count-text {
  color: #ec1c24;
  font-size: 30px;
  font-family: 'ProximaNova-Bold';
  font-weight: bold;
}

.cheers-total-point-bal {
  color: #000;
  font-weight: bold;
  font-size: 16px;
  font-family: 'ProximaNova-Bold';
}

.cheers-redeem-gift {
  color: #000;
  font-family: 'ProximaNova-Bold';
  font-weight: bold;
}

.cheers-points-tobe-redeemed {
  color: #000;
  font-size: 12px;
  font-family: 'ProximaNova-Bold';
  font-weight: bold;
}

.cheers-redeem-points {
  color: #000;
  font-size: 12px;
  font-family: 'ProximaNova-Bold';
  font-weight: bold;
}

.cheers-register-message-text {
  color: #000;
  font-family: 'ProximaNova-Bold';
  font-size: 1em;
}

.cheers-you-redeeming {
  font-size: 15px;
  font-weight: bold;
  font-family: 'ProximaNova-Regular';
}

.cheers-donate-points-upper-view {
  background-color: #f1f3f8;
  height: 70px;
  align-items: center;
  display: flex;
}

.cheers-available-donate {
  color: #3d3d3d;
  font-size: 16px;
  font-family: 'ProximaNova-Regular';
}

.cheers-donate-balance {
  color: #ec1c24;
  font-size: 20px;
  font-family: 'ProximaNova-Bold';
  font-weight: bold;
}

.cheers-select-option-text {
  color: #000;
  font-size: 16px;
  font-family: 'ProximaNova-Bold';
}

.cheers-select-padding {
  padding-top: 10px;
}

.cheers-person-selected {
  color: #000;
  font-size: 14px;
  font-family: 'ProximaNova-Bold';
}

.cheers-point-awarded-text {
  color: #8f8f8f;
}

.cheers-points-donated-text {
  color: #000;
  font-size: 14px;
  font-family: 'ProximaNova-Bold';
}

.cheers-view-style {
  background-color: #fff;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.cheers-name {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
}

.cheers-position-text {
  font-size: 0.813em;
  font-family: 'ProximaNova-Regular';
  margin-top: 0.313rem;
  color: #aaa;
}

.cheers-available-pts {
  color: #ec1c24;
  font-size: 24px;
  font-family: 'ProximaNova-Bold';
  font-weight: bold;
}

.cheers-points-text-style {
  color: #000;
  font-size: 18px;
  font-family: 'ProximaNova-Regular';
}

.cheers-redeem-last-month {
  color: #000;
  font-size: 10px;
  font-family: 'ProximaNova-Regular';
}

.cheers-points-summary-upper-view {
  background-color: #f1f3f8;
  align-items: center;
  display: flex;
}

.cheers-transactions {
  color: #000;
  font-size: 16px;
  font-family: 'ProximaNova-Regular';
}

.cheers-points-title {
  font-size: 16px;
  color: #000;
  font-family: 'ProximaNova-Bold';
  font-weight: bold;
}

.cheers-date-time {
  font-size: 14px;
  font-family: 'ProximaNova-Regular';
  color: #8f8f8f;
}

.cheers-points-summary-credit {
  color: #25b723;
  font-size: 22px;
  font-family: 'ProximaNova-Bold';
  font-weight: bold;
}

.cheers-points-summary-debit {
  color: #ec1c24;
  font-size: 22px;
  font-family: 'ProximaNova-Bold';
  font-weight: bold;
}

.cheers-pts-text {
  color: #000;
  font-family: 'ProximaNova-Regular';
}

.cheers-click-here {
  color: #8aaad9;
  font-family: 'ProximaNova-Regular';
  text-decoration-line: underline;
}

.cheers-voucher-text {
  font-family: 'ProximaNova-Bold';
  font-weight: bold;
}

.cheers-any-query {
  font-family: 'ProximaNova-Bold';
  font-weight: bold;
}

.cheers-email-text {
  color: #8aaad9;
  font-size: 14px;
  font-family: 'ProximaNova-Bold';
  font-weight: bold;
}

.cheers-order-id-text {
  position: absolute;
  top: 40px;
  right: 50px;
  font-family: 'ProximaNova-Regular';
  color: #fff;
  font-size: 12px;
}

.cheers-gc-id-text {
  position: absolute;
  right: 50px;
  top: 70px;
  font-family: 'ProximaNova-Regular';
  color: #fff;
  font-size: 12px;
}

.cheers-gc-code-text {
  position: absolute;
  right: 50px;
  top: 100px;
  font-family: 'ProximaNova-Regular';
  color: #fff;
  font-size: 12px;
}

.cheer-card-price-text {
  position: absolute;
  bottom: 90px;
  left: 50px;
  font-family: 'ProximaNova-Bold';
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.cheer-card-expiry-text {
  position: absolute;
  bottom: 60px;
  left: 50px;
  font-family: 'ProximaNova-Regular';
  color: #fff;
  font-size: 12px;
}

.cheers-container {
  position: relative;
  text-align: center;
  color: #fff;
}

.cheers-tap-to-copy {
  font-family: 'ProximaNova-Regular';
  color: #8f8f8f;
  font-size: 14px;
}

.cheers-grid-view {
  border-radius: 5px;
  border-width: 1px;
  border-color: #000;
  border-style: solid;
}

.cheers-order-id {
  font-family: 'ProximaNova-Bold';
  color: #000;
  font-size: 12px;
  font-weight: bold;
}

.cheers-pin-text-style {
  font-family: 'ProximaNova-Regular';
  color: #ec1c24;
  font-size: 12px;
  font-weight: bold;
}

.cheers-desclaimer {
  font-family: 'ProximaNova-Bold';
  color: #000;
  font-size: 14px;
}

.cheers-dot-text {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  font-weight: bold;
  color: #000;
}

.cheers-item-text {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  font-weight: bold;
  color: #8f8f8f;
}

.cheers-self-desc-text {
  font-size: 15px;
  color: #000;
}

.cheers-nomination-type {
  font-size: 20px;
  font-family: 'ProximaNova-Bold';
  color: #8f8f8f;
}

.cheers-nomination-text {
  font-size: 20px;
  font-family: 'ProximaNova-Bold';
  color: #000;
}

.cheers-based-on-criteria-text {
  font-size: 16px;
  color: #8f8f8f;
  font-family: 'ProximaNova-Bold';
}

.cheers-award-extra {
  font-size: 16px;
  color: #000;
  font-family: 'ProximaNova-Regular';
}

.cheers-points-individually {
  color: #000;
  font-size: 16px;
  font-family: 'ProximaNova-Bold';
}

.cheers-allocate-name {
  font-size: 16px;
  font-family: 'ProximaNova-Bold';
}

.cheers-point-awarded {
  font-size: 13px;
  font-family: 'ProximaNova-Bold';
}

.cheers-allocate-point-name {
  font-size: 14px;
  color: #000;
  font-family: 'ProximaNova-Regular';
}

.cheers-employee-view {
  border-color: #8aaad9;
  border-width: 1px;
  border-style: solid;
  margin-right: 0.313rem;
  padding-top: 0.313rem;
  padding-bottom: 0.313rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  background-color: #fff;
  cursor: pointer;
  font-size: 0.75em;
  font-family: 'ProximaNova-Bold';
  color: #8aaad9;
  border-radius: 5px;
}

.cheers-my-team-name {
  color: #000;
  font-size: 13px;
  font-family: 'ProximaNova-Bold';
}

.cheers-nomin-text-selected {
  font-size: 18px;
  color: #8f8f8f;
}

.cheers-nomin-text-not-selected {
  font-size: 18px;
  color: #000;
}

.cheers-description-text-selected {
  color: #8f8f8f;
  font-size: 15px;
}

.cheers-description-text-not-selected {
  color: #000;
  font-size: 15px;
}

.cheers-frequency {
  font-size: 12px;
  color: #000;
  font-family: 'ProximaNova-Regular';
}

.cheers-approved-text {
  font-size: 16px;
  color: #25b723;
  font-family: 'Lato-Bold';
}

.cheers-rejected-text {
  font-size: 16px;
  color: #ec1c24;
  font-family: 'Lato-Bold';
}

.cheers-request-date-time {
  color: #000;
  font-size: 12px;
  font-family: 'ProximaNova-Regular';
  font-weight: bold;
}

.cheers-date-time-text {
  font-family: 'ProximaNova-Regular';
  font-size: 13px;
  color: #8f8f8f;
}

.cheers-download-certificate {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  color: #e30613;
  font-family: 'ProximaNova-SemiBold';
  margin-left: 20px;
}

.cheers-download-certificate-disabled {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  color: gray;
  font-family: 'ProximaNova-SemiBold';
  margin-left: 20px;
}

.cheers-download-certificate-view {
  display: flex;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
}

.cheers-certificate-employee-name {
  position: absolute;
  top: 450px;
  left: 175px;
  color: #ed1d23;
  font-size: 57px;
  font-family: 'Lato-Black';
}

.cheers-certificate-employee-long-name {
  position: absolute;
  top: 450px;
  left: 175px;
  color: #ed1d23;
  font-size: 40px;
  font-family: 'Lato-Black';
  white-space: nowrap;
  overflow: hidden;
  width: 980px;
  text-overflow: ellipsis;
}

.hide-certificate {
  position: absolute;
  left: -10000px;
}

.cheers-certificate-date {
  position: absolute;
  bottom: 33px;
  left: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 22px;
  font-family: 'Lato-Regular';
}

.cheers-certificate {
  position: absolute;
  height: 800px;
  width: 1200px;
}

.leadersboard-self-view {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  background-color: bisque;
}

/* --------------- MY PROFILE SCREEN --------------- */
.my-profile-wish-text {
  color: #000;
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
}

.my-profile-cross-icon {
  width: 18px;
  height: 18px;
}

.my-profile-gift-icon {
  width: 20px;
  height: 20px;
}

.my-profile-wishes-icon {
  width: 25px;
  height: 25px;
}

.loadMoreContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.load-more-icon {
  cursor: pointer;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadmoreText {
  cursor: pointer;
  font-family: 'ProximaNova-SemiBold';
  color: #4472c4;
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-profile-image-popup {
  border: 1px dashed #8aaad9;
  border-radius: 9px;
}

.my-profile-year {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
  color: #000;
}

.my-profile-submit-bottom-button-container {
  width: 600px;
}

.my-profile-edit-editable-text-input form-control is-invalid {
  border-color: #dc3545;
}

.app-settings-form-width {
  width: 30%;
}

.my-profile-app-setting-btn {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  height: 35px;
  text-align: center;
  font-family: 'ProximaNova-Regular';
  margin: 0 auto;
  display: block;
}

.my-profile-edit-eye-icon-container {
  height: 45px;
}

.my-profile-editable-phone-input-container {
  width: 110px;
}

.my-profile-editable-phone-text-container {
  width: 81%;
}

.my-profile-edit-divider-style {
  height: 8px;
  background-color: #f6f6f7;
}

.my-profile-edit-tab-container .tab-content {
  display: flex;
  justify-content: center;
}

.my-profile-editable-phone-code .react-tel-input .form-control {
  height: 45px;
}

.common-user-profile-visible-icon {
  width: 40px;
  height: 40px;
  background: #f6f6f7;
  padding: 0.5rem;
  border-radius: 100px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
}

.common-user-profile-info-icon {
  height: 14px;
  width: 14px;
}

.common-profile-edit-camera-container {
  bottom: 0px;
  right: 0px;
}

.common-profile-edit-camera-size {
  width: 40px;
  height: 40px;
}

.my-profile-edit-non-editable-text-input {
  background-color: #f6f6f7 !important;
  height: 45px;
  border-radius: 4px;
  font-family: 'ProximaNova-Regular';
  font-size: 1em;
}

.my-profile-edit-editable-text-input {
  background-color: #fff !important;
  height: 45px;
  border-radius: 4px;
  font-family: 'ProximaNova-Regular';
  font-size: 1em;
}

.my-profile-edit-editable-text-area {
  background-color: #fff !important;
  min-height: 110px;
  border-radius: 4px;
  font-family: 'ProximaNova-Regular';
  font-size: 1em;
}

.my-profile-required {
  font-style: italic;
  color: #757575;
}

.my-profile-image-view {
  width: 100%;
  justify-content: center;
  display: flex;
}

.my-profile-name-tenure-conatiner {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.my-profile-tenure-name-text {
  font-family: 'Lato-Bold';
  font-size: 1.563em;
  color: #fff;
}

.my-profile-tenure-id-text {
  font-family: 'Lato-Bold';
  font-size: 1.25em;
  color: #fff;
}

.my-profile-id-card-style {
  width: 280px;
  height: 320px;
}

.my-profile-star-container {
  position: absolute;
  top: 10px;
  margin-right: 0.65rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.65rem;
  z-index: 100px;
}

.my-profile-id-card-profile-tenure-size {
  width: 220px;
  height: 220px;
}

.my-profile-tenure-profile-picture-view {
  margin-top: 120px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.my-profile-tenure-detail-container {
  position: absolute;
  top: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
}

.my-profile-tenure-name-text-container {
  margin-top: 1.875rem;
}

.my-profile-name-text-container {
  margin-top: 1.875rem;
  margin-left: 1.25rem;
  text-align: center;
}

.my-profile-id-card-name-text {
  font-family: 'Lato-Bold';
  font-size: 1.563em;
}

.my-profile-id-card-longername-text {
  font-family: 'Lato-Bold';
  font-size: 1.2em;
}

.my-profile-picture-view {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.my-profile-id-card-profile-size {
  width: 135px;
  height: 155px;
}

.my-profile-id-card-image-background {
  position: relative;
  width: 323px;
  height: 508px;
  background-color: #fff;
  border: 1px solid #d5d5d5;
}

.my-profile-id-card-content {
  position: absolute;
  top: 100px;
  width: 100%;
}

.my-profile-asset-container {
  cursor: pointer;
}

.my-profile-asset-icon {
  height: 50px;
}

.my-profile-asset-indicator-icon {
  height: 13px;
}

.my-profile-asset-item-title {
  font-size: 1.12em;
  text-transform: uppercase;
  font-family: 'ProximaNovaCond-SemiBold';
  margin-bottom: 0;
}

.my-profile-asset-section-title {
  font-size: 1.125em;
  font-family: 'ProximaNovaCond-Bold';
  text-transform: uppercase;
}

.my-profile-asset-section-container {
  border-bottom: 5px solid #f8f6f9;
}

.my-profile-asset-detail-container {
  padding: 0.938rem;
  border: 1px solid #e8e8e8;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.asset-detail-text-container {
  border-bottom: 1px solid #e8e8e8;
}

.asset-text-container {
  padding-left: 0.938rem;
}

.custom-asset-submit-btn {
  background-color: #eb2027;
  border: 1px solid #eb2027;
  border-radius: 4px;
  color: #fff;
  font-size: 1em;
  width: 100px;
  height: 40px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
  font-weight: 700;
}

.custom-asset-cancel-btn {
  background-color: #eaeaea;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  color: #3a3a3a;
  font-size: 1em;
  width: 100px;
  height: 40px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
  font-weight: 700;
}

.asset-confirmation-text-style {
  font-size: 1.25em;
  color: #000000;
}

.click-here-text {
  font-size: 1.25em;
  color: #e30613;
}

.asset-confirmation-container {
  padding-left: 0.938rem;
}

.asset-last-confirmed-date-style {
  font-size: 1.25em;
  font-family: 'ProximaNova-Bold';
}

.asset-thanks-text {
  text-align: center;
  font-size: 18px;
}

.asset-confirmation-dialog {
  width: 659px;
  height: 360px;
  background: #ffffff;
}

.asset-modal-text {
  padding-left: 0.938rem;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
}

.asset-button-padding {
  padding-left: 0.938rem;
}

.my-profile-asset-detail-main-text {
  font-size: 1.2em;
  font-family: 'ProximaNova-Bold';
  margin-bottom: 0.75rem;
}

.asset-last-confirmed-container {
  padding-right: 0.938rem;
}

.asset-confirmation-comments-text {
  font-size: 18px;
  color: #000000;
}

.asset-thankyou-dialog {
  width: 574px;
  height: 210px;
}

.attendance-warning-description {
  color: #000;
  font-size: 14px;
  font-family: 'ProximaNova-Regular';
  border-bottom: 1px #ddd solid;
  text-align: center;
}

.my-profile-asset-detail-secondary-text {
  font-size: 1.2em;
  font-family: 'ProximaNova-Regular';
  margin-bottom: 0.75rem;
  color: #424242;
}

.my-profile-detail-title {
  font-family: 'ProximaNova-Bold';
  font-size: 1.15em;
  margin-bottom: 0;
}

.my-profile-normal-text {
  font-family: 'ProximaNova-Regular';
  font-size: 1em;
  margin-bottom: 0;
}

.dei-esg-champion-container {
  display: flex;
  flex-direction: row;
  margin-top: 2vh;
  background-color: #f5f5f5;
  padding: 5px 10px;
  border-radius: 5px;
  flex-wrap: wrap;
  width: fit-content;
}

.champion-container {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.esg-champion {
  color: #5591b7;
}

.dei-champion {
  color: #81b141;
}

.champion-text {
  margin-bottom: 0px;
  padding-top: 3px;
  font-family: 'ProximaNova-SemiBold';
  white-space: nowrap;
}

.profile-container-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1%;
  flex-wrap: nowrap;
  width: 100%;
}

.user-details-container-profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}

.user-details-andchampion-container {
  margin-left: 2%;
  width: 100%;
}

.radio-yes-no-text {
  font-family: 'ProximaNova-Regular';
  font-size: 18px;
}

.custom-radio {
  width: 14px;
  height: 14px;
}

.my-profile-highlighted-text {
  font-family: 'ProximaNova-Regular';
  color: #f79738;
  font-size: 1em;
  margin-bottom: 0;
  cursor: pointer;
}

.my-profile-edit-icon {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.esg-dei-champion-star {
  height: 18px;
  width: 18px;
}

.my-profile-show-more-text-key {
  color: #adadad;
  font-family: 'ProximaNova-Regular';
  margin-bottom: 0;
  margin-right: 0.313rem;
}

.my-profile-show-more-text-value {
  color: #000;
  font-family: 'ProximaNova-Regular';
  margin-bottom: 0;
}

.my-profile-social-media-icon {
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.my-profile-edit-background-container {
  position: relative;
  display: inline;
}

.my-profile-edit-image-container {
  width: 100%;
}

.my-profile-edit-foreground-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.my-profile-edit-app-setting-header {
  font-family: 'ProximaNova-Bold';
  color: '#383839';
  text-transform: uppercase;
}

.my-profile-edit-upload-photo-icon {
  height: 80px;
  width: 80px;
}

.my-profile-edit-upload-photo-text {
  font-size: 1.125em;
  font-family: 'ProximaNova-Regular';
}

.my-profile-edit-upload-photo-browse {
  color: #ec1c24;
  font-size: 1.125em;
  font-family: 'ProximaNova-Regular';
}

.my-profile-pronoun-text {
  font-size: 0.875em;
  color: '#6D6E71';
  font-style: italic;
  text-align: center;
  padding-top: 10px;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
}

/* --------------- PANDEMIC SUPPORT SCREEN --------------- */
.pandemic-support-foreground-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.pandemic-support-video-button-image {
  width: 30px;
  height: 30px;
}

.pandemic-support-image {
  width: 100%;
  object-fit: cover;
}

.pandemic-support-article-title {
  font-size: 0.875em;
  font-family: 'ProximaNova-Bold';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.pandemic-support-article-title-spacing {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.pandemic-support-article-subtitle-spacing {
  margin-bottom: 0.625rem;
}

.pandemic-support-calender-style {
  width: 12px;
  height: 12px;
}

.pandemic-support-event-date {
  margin-left: 0.625rem;
  font-size: 0.875em;
  font-family: 'ProximaNovaCond-Bold';
  color: #8aaad9;
  font-weight: bold;
}

.pandemic-support-guide-pdf-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 0.625rem;
}

.pandemic-support-guide-pdf-icon {
  width: 20px;
  height: 20px;
}

.pandemic-support-guide-pdf-title {
  font-family: 'Lato-Regular';
  font-size: 0.813rem;
  margin-left: 0.313rem;
  margin-bottom: 0.625rem;
}

.pandemic-support-guide-title-container {
  margin-top: 0.625rem;
}

.pandemic-support-background-img-container {
  position: relative;
  display: inline;
}

.pandemic-support-youtube-image-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.pandemic-support-bottom-button {
  color: #fff;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
}

.pandemic-support-bottom-button:hover {
  text-decoration: none !important;
  color: #fff !important;
}

/* --------------- NEWS SCREEN --------------- */

.news-content-html .embeddedContent {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  float: none;
}

.news-content-html iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.news-header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.news-calender-style {
  width: 12px;
  height: 12px;
}

.news-event-date {
  margin-left: 0.625rem;
  font-size: 0.875em;
  font-family: 'ProximaNovaCond-Bold';
  color: #8aaad9;
  font-weight: bold;
}

.news-title {
  margin-top: 0.313rem;
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.news-calender-button-style {
  background: #fff;
  border: #fff;
  color: #ec1c24;
  font-family: 'ProximaNovaCond-Bold';
  font-size: 1em;
}

.news-calender-button-style:hover {
  text-decoration: none !important;
  color: #ec1c24 !important;
}

.news-year-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.news-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.news-icon-row {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: center;
}

.news-icon-text {
  margin-left: 0.313rem;
  color: #88a8d8;
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
  cursor: pointer;
}

.news-bottom-button {
  color: #fff;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
}

.news-bottom-button:hover {
  text-decoration: none !important;
  color: #fff !important;
}

.news-search-form {
  width: 90%;
}

.news-search-bar {
  border: none;
  outline: 0;
  background: none;
  width: 100%;
  transition: width 0.4s linear;
}

/* --------------- BROADCASTS SCREEN --------------- */
.broadcast-date-text-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.broadcast-calender-style {
  width: 12px;
  height: 12px;
}

.broadcast-event-date {
  margin-left: 0.625rem;
  font-size: 0.875em;
  font-family: 'ProximaNovaCond-Bold';
  color: #8aaad9;
  font-weight: bold;
}

.broadcast-event-date-new {
  font-size: 0.875em;
  font-family: 'ProximaNovaCond-Bold';
  color: #8aaad9;
  font-weight: bold;
}

.broadcast-title {
  margin-top: 0.313rem;
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.broadcast-header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.broadcast-icon-row {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: center;
}

.broadcast-icon-text {
  margin-left: 0.313rem;
  color: #88a8d8;
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
  cursor: pointer;
}

.broadcast-bottom-button {
  color: #fff;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
}

.broadcast-year-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.broadcast-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.broadcast-calender-button-style {
  background: #fff;
  border: #fff;
  color: #ec1c24;
  font-family: 'ProximaNovaCond-Bold';
  font-size: 1em;
}

.broadcast-search-form {
  width: 90%;
}

.broadcast-search-bar {
  border: none;
  outline: 0;
  background: none;
  width: 100%;
  transition: width 0.4s linear;
}

.broadcast-calender-button-style:hover {
  text-decoration: none !important;
  color: #ec1c24 !important;
}

.broadcast-content-html .embeddedContent {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  float: none;
  text-align: center;
}

.broadcast-content-html iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* --------------- POLICY SCREEN --------------- */
.policy-html .embedpicture .embedimgblock img {
  width: 100%;
}

.policy-region {
  color: #757575;
  font-size: 14px;
  margin-top: 16px;
  font-family: 'ProximaNovaCond-Regular';
  margin-right: 16px;
}

.policy-bg {
  align-items: center;
  min-width: 30%;
  justify-content: 'center';
  align-items: 'center';
}

.header-policy-region {
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  justify-content: space-between;
}

.divider-policies {
  border-bottom: 1px solid #e0e0e0;
}

.policy-title-hover :hover {
  font-weight: 700;
}

.sub-category-title-hover :hover {
  font-weight: 700;
}

.tr-dropdown-item-style:hover {
  background-color: #f5e9e4;
}

.policy-dropdown-button-style {
  background-color: #f5f5f5;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.policy-title {
  color: #000000;
  font-size: 16px;
  font-family: 'ProximaNova-Regular';
}

.policy-title-selected {
  color: #000000;
  font-size: 16px;
  font-family: 'ProximaNova-Bold';
}

.policy-icon {
  width: 20px;
  height: 20px;
}

.region-icon {
  width: 15px;
  height: 15px;
}

.policy-date-view-style {
  display: flex;
}

.policy-search-form {
  width: 90%;
}

.policy-search-bar {
  border: none;
  outline: 0;
  background: none;
  width: 100%;
  transition: width 0.4s linear;
}

.policy-location {
  color: #000;
  font-size: 1.125rem;
  font-family: 'ProximaNova-SemiBold';
  background-color: #f7f7f9;
  border-radius: 5px;
}

.geography-container {
  color: #000;
  background-color: #f7f7f9;
  border-radius: 5px;
}

.geography {
  color: #000;
  font-size: 1.125rem;
  font-family: 'ProximaNova-SemiBold';
}

.policy-category {
  color: #79a0da;
  font-size: 1.125rem;
  font-family: 'ProximaNovaCond-Bold';
}

.policy-details-title {
  color: #000;
  font-size: 1.125rem;
  font-family: 'ProximaNovaCond-Bold';
}

.margin-left-policy {
  margin-left: 2rem;
}

.tab-text {
  font-family: 'Lato-Regular';
}

/* --------------- DEAR EMPLOYER SCREEN --------------- */

.dear-employer-calender-style {
  width: 12px;
  height: 12px;
}

.dear-employer-event-date {
  margin-left: 0.625rem;
  font-size: 0.875em;
  font-family: 'ProximaNovaCond-Bold';
  color: #8aaad9;
  font-weight: bold;
}

.dear-employer-title {
  margin-top: 0.313rem;
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.dear-employer-year-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.dear-employer-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.dear-employer-calender-button-style {
  background: #fff;
  border: #fff;
  color: #ec1c24;
  font-family: 'ProximaNovaCond-Bold';
  font-size: 1em;
}

.dear-employer-calender-button-style:hover {
  text-decoration: none !important;
  color: #ec1c24 !important;
}

.dear-employer-header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.dear-employer-icon-row {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: center;
}

.dear-employer-icon-text {
  margin-left: 0.313rem;
  color: #88a8d8;
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
  cursor: pointer;
}

.table-style table {
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}

.table-style td,
.table-style th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.dear-employer-content-html .embeddedContent {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
}

.dear-employer-content-html iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* --------------- EVENTS SCREEN --------------- */
.event-content-html .embeddedContent {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  float: none !important;
}

.event-content-html iframe {
  width: 100%;
}

.events-summary {
  margin-top: 0.313rem;
  font-size: 0.85em;
  font-family: 'ProximaNovaCond-SemiBold';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.event-desccription-text {
  font-size: 14px;
  color: #8aaad9;
  font-family: 'ProximaNovaCond-Semibold';
}

.event-gallary-icon-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.event_btn_disabled_style {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  min-width: 120px;
  height: 35px;
  font-family: 'ProximaNova-Regular';
  opacity: 0.4;
}

.event-calender-style {
  width: 12px;
  height: 12px;
}

.events-event-date {
  margin-left: 0.625rem;
  font-size: 0.875em;
  font-family: 'ProximaNovaCond-Bold';
  color: #8aaad9;
  font-weight: bold;
}

.event-title {
  margin-top: 0.313rem;
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.event-upload-photos-btn {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  min-width: 120px;
  height: 35px;
  font-family: 'ProximaNova-Regular';
}

.event-content-html .embeddedContent {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  float: none !important;
}

.event-content-html iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.event-header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.event-icon-row {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: center;
}

.event-icon-text {
  margin-left: 0.313rem;
  color: #88a8d8;
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
  cursor: pointer;
}

/* --------------- POLLS & SURVEYS SCREEN --------------- */

.polls-category-container {
  display: flex;
  flex-wrap: wrap;
}

.polls-progress-bar-container {
  height: 20px;
  width: '100%';
  background-color: #d5d5d5;
  border-color: #fff;
  border-top-width: 0.5px;
  position: relative;
}

.polls-progress-bar-inner-container {
  height: 20px;
  background-color: #ec1c24;
}

.polls-progress-bar-style {
  height: 20px;
  border-radius: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.polls-progress-bar-inner-style {
  height: '100%';
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.polls-progress-bar-inner-last-style {
  height: '100%';
  border-radius: 100px;
}

.polls-percentage-view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  color: #fff;
  font-family: 'Poppins-SemiBold';
}

.polls-participated-text {
  font-size: 12px;
  line-height: 12px;
  font-family: 'ProximaNova-Regular';
  color: #8aaad9;
  font-weight: 600;
}

.polls-details-participated-text {
  font-size: 14px;
  line-height: 12px;
  font-family: 'ProximaNova-Regular';
  color: #8aaad9;
  font-weight: 600;
}

.survey-participated-text {
  font-size: 12px;
  line-height: 12px;
  font-family: 'ProximaNova-Regular';
  color: #8aaad9;
  font-weight: 600;
}

.polls-main-axis-view {
  display: flex;
  justify-content: center;
}

.polls-calender-style {
  width: 12px;
  height: 12px;
  margin-top: 2px;
}

.polls-cross-axis-view {
  display: flex;
  align-items: center;
}

.polls-axis-container-view {
  display: flex;
  align-items: center;
  border-right: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  border-left: 1px solid #e4e4e4;
  background: #fbfbfb;
}

.polls-date-text {
  font-size: 12px;
  color: #8aaad9;
  font-family: 'ProximaNova-Regular';
  font-weight: 600;
  line-height: 18px;
}

.polls-participate-button-container {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  color: #ec1b25;
  font-weight: 600;
}

.polls-like-comment-count {
  font-family: 'ProximaNovaCond-Semibold';
  font-size: 12px;
  color: #3a3a3a;
}

.polls-view-count {
  font-family: 'ProximaNovaCond-Semibold';
  font-size: 13px;
  color: #3a3a3a;
}

.polls-title-text {
  font-size: 16px;
  font-family: 'ProximaNovaCond-Semibold';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.polls-details-title-text {
  font-size: 14px;
  font-family: 'ProximaNovaCond-Semibold';
}

.polls-button-style {
  background-color: #cdcdcd;
  border: 1px solid #cdcdcd;
  color: #ec1b25;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
  font-weight: 600;
  margin: 0 auto;
  display: block;
}

.polls-description-text {
  font-size: 14px;
  line-height: 22px;
}

.poll-like-icon {
  height: 18px;
  width: 18px;
}

.polls-categories {
  border-color: #ebebeb;
  border-width: 1px;
  border-style: solid;
  margin-right: 0.313rem;
  padding-top: 0.313rem;
  padding-bottom: 0.313rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  cursor: pointer;
  font-size: 0.75em;
  font-family: 'ProximaNovaCond-Semibold';
}

.polls-inactive-categories {
  border-color: #ebebeb;
  border-width: 1px;
  border-style: solid;
  margin-right: 0.313rem;
  padding-top: 0.313rem;
  padding-bottom: 0.313rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  font-size: 0.75em;
  font-family: 'ProximaNovaCond-Semibold';
}

.polls-title-container {
  border-right: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  border-left: 1px solid #e4e4e4;
  background: #fbfbfb;
}

.polls-question-num-text {
  font-family: 'Lato-Bold';
  font-size: 15px;
}

.polls-question-text {
  font-family: 'Lato-Bold';
  font-size: 13px;
}

.polls-flex {
  display: flex;
}

.polls-prev-next-arrow {
  width: 45px;
  height: 45px;
}

.polls-success-text {
  color: #8aaad9;
  font-size: 30px;
  font-family: 'ProximaNova-SemiBold';
}

.survey-content-container-width {
  width: 60%;
}

/* --------------- PROMOS SCREEN --------------- */

.promo-download-app-container {
  height: 110vh;
}

.promo-download-app-image {
  max-width: 400px;
  width: 100%;
}

.promo-button-style {
  width: 120px;
}

/* --------------- CONTESTS SCREEN --------------- */
.contests-content-html .embeddedContent {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  float: none !important;
}

.contests-content-html iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.contests-summary {
  margin-top: 0.313rem;
  font-size: 0.85em;
  font-family: 'ProximaNovaCond-SemiBold';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.contests-description-text {
  font-size: 14px;
  color: #8aaad9;
  font-family: 'ProximaNovaCond-Semibold';
}

.contests-gallary-icon-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contests_btn_disabled_style {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  min-width: 120px;
  height: 35px;
  font-family: 'ProximaNova-Regular';
  opacity: 0.4;
}

.contests-calender-style {
  width: 12px;
  height: 12px;
}

.contests-event-date {
  margin-left: 0.625rem;
  font-size: 0.875em;
  font-family: 'ProximaNovaCond-Bold';
  color: #8aaad9;
  font-weight: bold;
}

.contests-title {
  margin-top: 0.313rem;
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.contests-upload-photos-btn {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  min-width: 120px;
  height: 35px;
  font-family: 'ProximaNova-Regular';
}

.contests-header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.contests-icon-row {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: center;
}

.contests-icon-text {
  margin-left: 0.313rem;
  color: #88a8d8;
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
  cursor: pointer;
}

/* --------------- GALLERY SCREEN --------------- */
.gallery-main {
  position: relative;
}

.gallery-bg-view {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: red;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.gallery-flex {
  display: flex;
}

.gallery-count-left-text {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
  color: #fff;
}

.gallery-calender-style {
  width: 20px;
  height: 20px;
}

.gallery-event-date {
  margin-left: 0.625rem;
  font-size: 0.875em;
  font-family: 'ProximaNovaCond-Bold';
  color: #8aaad9;
  font-weight: bold;
}

.gallery-location {
  margin-left: 0.625rem;
  font-size: 0.875em;
  font-family: 'ProximaNovaCond-Bold';
  color: #8aaad9;
}

.gallery-title {
  margin-top: 0.313rem;
  font-size: 0.938em;
  font-family: 'ProximaNovaCond-Bold';
}

/* --------------- FACILTIES SCREEN --------------- */
.facility-content-text-view {
  margin-right: 1.25rem;
  margin-left: 1.25rem;
}

.facility-content-picture-container {
  margin-top: 0.313rem;
  margin-bottom: 0.313rem;
}

.facility-name-outer {
  margin-left: 4.375rem;
}

.facility-name-text-container {
  margin-bottom: 1.25rem;
}

.facility-detail-text-container {
  margin-bottom: 0.313rem;
}

.facility-address-container {
  width: 200px;
}

.facility-content-text-style-container {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.facility-content-phone-num-container {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.facility-content-map-container {
  margin-bottom: 0.313rem;
}

.facility-content-link-container {
  margin-bottom: 0.625rem;
  color: #8aaad9;
  cursor: pointer;
}

.facility-content-text-style {
  font-family: 'ProximaNovaCond-Semibold';
  font-size: 1em;
}

.facility-business-card-image-background {
  position: relative;
  width: 300px;
  height: 400px;
}

.facility-business-card-content {
  position: absolute;
  top: 30%;
}

.facility-name-text {
  font-family: 'ProximaNovaCond-Semibold';
  font-size: 0.938em;
}

.facility-detail-text {
  font-family: 'ProximaNovaCond-Semibold';
  font-size: 0.875em;
}

.facility-main-container {
  position: absolute;
  top: 0;
  z-index: 100;
  background: #fff;
}

.facility-bottom-button-container {
  margin-right: 1.25rem;
}

.margin-right-3-rem {
  margin-right: 3rem;
}

.facility-title {
  margin-top: 0.313rem;
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.facility-header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.facility-icon-row {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: center;
}

.facility-icon-text {
  margin-left: 0.313rem;
  color: #88a8d8;
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
  cursor: pointer;
}

.facility-bottom-button {
  color: #fff;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
}

.facility-bottom-button:hover {
  text-decoration: none !important;
  color: #fff !important;
}

.facility-share-card-social-text {
  font-size: 1.125em;
  color: #000000;
}

.facilities-text-input {
  background-color: #fff;
  height: 45px;
  border-radius: 4px;
  font-size: 1em;
}

.facilities-custom-modal-header {
  border-bottom: none;
}

.facilities-custom-modal-title {
  font-family: 'ProximaNova-SemiBold';
}

.facilities-image-input {
  height: 200px;
  border-radius: 4px;
  background-color: #f6f6f7;
  border: 1px solid #e5e5e5;
}

.facilities-business-card-image {
  object-fit: contain;
}

/* --------------- GCDO SCREEN --------------- */

.gcdo-calender-style {
  width: 12px;
  height: 12px;
}

.gcdo-event-date {
  margin-left: 0.625rem;
  font-size: 0.875em;
  font-family: 'ProximaNovaCond-Bold';
  color: #8aaad9;
  font-weight: bold;
}

.gcdo-title {
  margin-top: 0.313rem;
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.gcdo-header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.gcdo-icon-row {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: space-between;
}

.gcdo-icon-text {
  margin-left: 0.313rem;
  color: #88a8d8;
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
  cursor: pointer;
}

.gcdo-bottom-button {
  color: #fff;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
}

.gcdo-year-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.gcdo-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.gcdo-calender-button-style {
  background: #fff;
  border: #fff;
  color: #ec1c24;
  font-family: 'ProximaNovaCond-Bold';
  font-size: 1em;
}

.gcdo-search-form {
  width: 90%;
}

.gcdo-search-bar {
  border: none;
  outline: 0;
  background: none;
  width: 100%;
  transition: width 0.4s linear;
}

.gcdo-calender-button-style:hover {
  text-decoration: none !important;
  color: #ec1c24 !important;
}

.gcdo-content-html .embeddedContent {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  float: none;
}

.gcdo-content-html iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* --------------- ECARDS SCREEN --------------- */
.ecards-message-text {
  font-family: 'ProximaNovaCond-Semibold';
  font-size: 1.125em;
}

.ecards-tabs {
  overflow-x: auto;
  overflow-y: hidden;
  display: -webkit-box;
  display: -moz-box;
  flex-wrap: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ecards-tabs::-webkit-scrollbar {
  display: none;
}

.ecards-image-div {
  position: relative;
}

.ecards-social-icons {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 10px;
  align-items: flex-end;
  width: 100%;
  right: 25px;
}

.ecards-download-icon {
  width: 30px;
  height: 30px;
}

.ecards-gallery-full-image {
  position: relative;
  height: 630px;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.ecards-carousel-social-icons {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 200px;
  align-items: flex-end;
  right: 45px;
}

.ecards-gallery-full-container {
  background-color: #000;
}

.ecards-wallpaper-full-image {
  width: 440px;
}

.ecards-social-icons-landscape {
  bottom: 10px;
  right: 20px;
}

/* --------------- OUR CUSTOMERS SCREEN --------------- */
.ourcustomers-main {
  position: relative;
}

.ourcustomers-bg-view {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  justify-content: center;
  align-items: center;
  padding: 0.625rem;
  cursor: pointer;
}

.ourcustomers-bg-view-detail {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: red;
  justify-content: center;
  align-items: center;
  padding: 0.625rem;
  cursor: pointer;
}

.ourcustomers-bg-view-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ourcustomers-count-left-text {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
  color: #fff;
}

.ourcustomers-cross-axis-view {
  display: flex;
  align-items: center;
}

.ourcustomers-main-axis-view {
  display: flex;
  justify-content: center;
}

.ourcustomers-flex {
  display: flex;
}

.ourcustomers-like-comment {
  width: 30px;
  height: 30px;
}

.ourcustomers-category-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.625rem;
}

.ourcustomers-categories {
  border-color: #d8d6d6;
  border-width: 1px;
  border-style: solid;
  margin-left: 0.313rem;
  margin-right: 0.313rem;
  padding-top: 0.313rem;
  padding-bottom: 0.313rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  cursor: pointer;
  font-size: 0.75em;
  font-family: 'ProximaNova-Bold';
}

.ourcustomers-categories-selected {
  border-color: #d8d6d6;
  border-width: 1px;
  border-style: solid;
  margin-left: 0.313rem;
  margin-right: 0.313rem;
  padding-top: 0.313rem;
  padding-bottom: 0.313rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  background-color: #d8d6d6;
  cursor: pointer;
  font-size: 0.75em;
  font-family: 'ProximaNova-Bold';
}

.ourcustomers-calender-style {
  width: 17px;
  height: 17px;
}

.ourcustomers-event-date {
  margin-left: 0.625rem;
  font-size: 0.875em;
  font-family: 'ProximaNovaCond-Bold';
  color: #8aaad9;
  font-weight: bold;
}

.ourcustomers-item {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.ourcustomers-title {
  font-size: 0.875em;
  font-family: 'Lato-Regular';
  color: #fff;
}

.ourcustomers-title-main {
  font-size: 0.938em;
  font-family: 'Lato-Regular';
  color: #fff;
}

.ourcustomers-author {
  font-family: 'ProximaNovaCond-Bold';
  font-size: 1.063em;
}

.ourcustomers-accolades-designation {
  font-size: 0.75em;
  font-family: 'Lato-Regular';
  color: #383839;
}

.ourcustomers-accolades-title {
  font-size: 0.938em;
  font-family: 'Lato-Regular';
  color: #383839;
}

.ourcustomers-title-text {
  font-size: 1em;
  font-family: 'ProximaNovaCond-Semibold';
}

.ourcustomer-location-text {
  font-size: 0.875em;
  font-family: 'ProximaNovaCond-Semibold';
  color: #595959;
}

.accolade-description-style {
  text-align: center;
}

.our-customer-accolade-year-text {
  font-family: 'ProximaNovaCond-Semibold';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.our-customer-deal-won-year-text {
  margin-top: 10px;
  font-size: 16px;
  line-height: 19px;
  font-family: 'ProximaNovaCond-Semibold';
}

.ourcustomers-account-ownername {
  font-size: 0.813em;
  font-family: 'ProximaNova-Regular';
}

.ourcustomer-owner-text {
  font-size: 0.75em;
  font-family: 'ProximaNova-Regular';
  color: #4d4d4d;
}

.ourcustomer-title {
  font-family: 'Lato-Regular';
  font-size: 0.938em;
  color: #4d4d4d;
}

.ourcustomer-like-comment {
  color: #000;
  font-size: 0.875em;
}

/* --------------- COMMUNITIES SCREEN --------------- */
.communities-image-first {
  right: -20px;
  z-index: 3;
}

.communities-image-second {
  right: -10px;
  z-index: 2;
}

.communities-lock-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.communities-champion-text {
  color: #000;
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
}

.communities-champion-title {
  color: #000;
  font-size: 0.875em;
  font-family: 'ProximaNova-Bold';
}

.communities-post-image {
  max-height: 300px;
}

.communities-tag-member {
  color: #000;
  font-size: 0.875em;
  font-family: 'ProximaNova-Bold';
}

.communities-tag-people-limit {
  color: #ec1c24;
  font-size: 0.875em;
  font-family: 'ProximaNova-Bold';
}

.communities-dialog-title {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
}

.communities-dialog-subtitle {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
}

.communities-cancel-community-button {
  color: #ec1c24;
}

.community-bg-view {
  bottom: 0;
  right: 0;
  background-color: #ec1c24;
  width: 100px;
  height: 40px;
}

.community-bg-view-image {
  top: 0;
  right: 0;
}

.community-images-outer-view {
  height: 250px;
  width: 100%;
}

.community-images {
  height: 100%;
  width: 100%;
}

.community-images-more {
  font-size: 1em;
  color: #fff;
}

.render-tribe-border {
  border-left: 1px solid #e0e0e0;
  width: 30%;
}

.community-posts-width {
  width: 70%;
  padding-right: 3rem;
  padding-top: 1.2rem;
}

.communities-list-item-padding {
  padding-left: 1rem;
}

.community-link-text {
  font-size: 0.875em;
}

.community-video-link-text {
  font-size: 1em;
}

.community-cross-icon {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0px;
  right: 0px;
}

.community-cross-remove-link {
  width: 18px;
  height: 18px;
}

.community-post-image {
  width: 74px;
  height: 74px;
}

.communities-tag-count-text {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
  color: #757575;
}

.communities-tag-people {
  color: #ec1c24;
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
}

.communities-add-image {
  color: #ec1c24;
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
}

.communities-label {
  color: #000;
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
}

.communities-md {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.communities-image {
  height: 100px;
  width: 160px;
}

.communities-main-axis-view {
  display: flex;
  justify-content: center;
}

.communities-cross-axis-view {
  display: flex;
  align-items: center;
}

.communities-flex {
  display: flex;
}

.communities-normal-icon {
  width: 25px;
  height: 25px;
}

.communities-width {
  width: 100px;
}

.communities-comments {
  background-color: #ebebeb;
  height: 40px;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.communities-link-btn {
  background-color: lightgray;
  border: 1px solid lightgray;
  color: #fff;
  font-size: 1em;
}

.communities-title-text {
  font-size: 1em;
  font-family: 'Lato-Bold';
}

.communities-member-text {
  font-size: 0.813em;
  font-family: 'Lato-Regular';
}

.communities-picture-count {
  font-size: 0.875em;
  font-family: 'ProximaNovaCond-Semibold';
}

.communities-member-detail {
  font-size: 0.875em;
  font-family: 'Lato-Regular';
  color: #8aaad9;
}

.communities-gallery-size {
  font-size: 0.875em;
  font-family: 'Lato-Regular';
  color: #8aaad9;
}

.communities-about {
  font-family: 'Poppins-SemiBold';
  font-size: 0.813em;
}

.communities-name-text {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
  line-height: 1.2em;
}

.communities-place-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
}

.communities-about-text {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
}

.communities-search-bar {
  border: none;
  outline: 0;
  background: none;
  width: 100%;
  transition: width 0.4s linear;
}

.communities-count {
  color: #000;
  font-family: 'ProximaNovaCond-Semibold';
  font-size: 1em;
}

.communities-post-employee-name {
  font-size: 0.875em;
  font-family: 'ProximaNova-Bold';
}

.see-wishes-sub-text {
  font-size: 14px;
  color: #ec1b25;
  font-family: 'ProximaNovaCond-Semibold';
  align-self: center;
  margin-right: 5px;
}

.right-arrow-icon-wishes {
  font-size: 20px;
  font-size: 20px;
  color: #ec1b25;
}

.wishes-sub-text-self {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.max-two-lines-profile {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.communities-date-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
}

.communities-url {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
  color: #757575;
}

.communities-more {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
  color: #757575;
}

.communities-title {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.communities-description {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
}

.communities-read-color {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
  color: #ec1c24;
  cursor: pointer;
}

.communities-report {
  font-family: 'ProximaNova-Regular';
  font-size: 0.938em;
}

.communities-comment-name {
  font-family: 'ProximaNovaCond-Bold';
  font-size: 0.875em;
}

.communities-comment-date {
  font-size: 0.75em;
  font-family: 'ProximaNova-Regular';
  color: #828282;
}

.communities-comment-text {
  font-family: 'ProximaNova-Regular';
  font-size: 0.938em;
}

.communities-button-style {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
  margin: 0 auto;
  display: block;
}

.communities-search-form {
  width: 90%;
}

.communities-btn-view {
  display: flex;
  justify-content: center;
}

.communities-icon {
  width: 22px;
  height: 22px;
}

.communities-icon-add {
  width: 29px;
  height: 29px;
}

.communities-tag-text {
  font-family: 'ProximaNova-Regular';
  font-size: 0.938em;
}

.communities-pinned-item-outer-container {
  background-color: #f7f7f9;
}

.communities-pinned-item-top-text {
  color: #8aaad9;
  font-family: 'ProximaNova-SemiBold';
  font-size: 0.875em;
}

.communities-pinned-item-inner-container {
  border-left: 2px solid #8aaad9;
}

.communities-post-pin-icon {
  height: 16px;
  width: 16px;
}

.communities-post-marker-text {
  color: #757575;
  font-size: 0.813em;
}

.communities-post-carousel-container {
  background-color: #000;
}

.communities-post-carousel-image {
  height: 600px;
  width: 75%;
  object-fit: contain;
}

.communities-pdf-item-title {
  font-size: 0.875em;
}

.communities-pdf-icon {
  height: 26px;
  width: 26px;
}

.communities-grey-box {
  background-color: #efefef;
  border-radius: 4px;
}

.communities-post-employee-pronoun {
  font-size: 0.813em;
  color: '#6D6E71';
}

.communities-pronoun-text {
  font-size: 0.875em;
  color: '#6D6E71';
}

/* --------------- NOTIFICATIONS SCREEN --------------- */
.notifications-main-axis-view {
  display: flex;
  justify-content: center;
}

.notifications-cross-axis-view {
  display: flex;
  align-items: center;
}

.notifications-flex {
  display: flex;
}

.notifications-title {
  font-size: 0.875em;
  font-family: 'ProximaNova-Bold';
}
.notifications-title1 {
  font-size: 0.75rem;
  font-family: 'ProximaNova-semibold';
}

.notifications-title-active {
  font-size: 0.875em;
  font-family: 'ProximaNova-Bold';
  color: #8aaad9;
  text-decoration: underline;
}
.notifications-title-active1 {
  font-size: 0.75rem;
  font-family: 'ProximaNova-semibold';
  color: #8aaad9;
  text-decoration: underline;
}

.notifications-timeStamp {
  font-size: 0.75em;
  font-family: 'ProximaNova-Regular';
}

.notifications-timeStamp1 {
  font-size: 0.75em;
  font-family: 'ProximaNova-Regular';
  color: #757575;
}
.notification-body {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
}
.notification-body1 {
  font-size: 0.75em;
  font-family: 'ProximaNova-Regular';
}

.notification-count-view {
  position: relative;
}

.notification-bg-view {
  position: absolute;
  top: 0;
  margin-left: 10px;
  margin-top: -7px;
  background-color: red;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-count-text {
  color: #fff;
  font-size: 0.8em;
  font-family: 'ProximaNova-Regular';
  background-color: #000000;
}

/* --------------- THE WALL SCREEN --------------- */
.thewall-content-comment-view {
  background-color: #ebebeb;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.thewall-view-style {
  margin-bottom: 0.625rem;
  padding-bottom: 0.625rem;
}

.thewall-name-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  justify-content: space-between;
}

.thewall-icon-container {
  margin-right: 0.625rem;
}

.thewall-post-image-outer {
  width: 100%;
  height: 300px;
  background-color: #000;
  margin-bottom: 0.625rem;
  margin-top: 0.313rem;
  text-align: center;
  position: relative;
}

.thewall-comment-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1.25rem;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  justify-content: space-between;
}

.thewall-comment-like-view {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.thewall-report-view {
  align-items: flex-end;
  justify-content: flex-end;
}

.thewall-tagged-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.625rem;
}

.thewall-post-pdf-view {
  margin-top: 0.313rem;
  margin-bottom: 0.313rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.thewall-post-pdf-size {
  width: 20px;
  height: 20px;
}

.thewall-gallery-tab-image {
  height: 100%;
  position: relative;
  padding: 0;
  overflow: hidden;
  display: flex;
  margin: auto;
}

.thewall-galleries-tab-image {
  height: 300px;
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.the-wall-video-div {
  position: relative;
}

.thewall-more-view {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: red;
  justify-content: center;
  align-items: center;
  padding: 0.625rem;
  cursor: pointer;
}

.thewall-post-employee-name-text {
  font-family: 'Lato-Bold';
  font-size: 1rem;
}

.thewall-post-date-text {
  font-family: 'Lato-Regular';
  color: #828282;
}

.thewall-post-description {
  font-size: 0.875em;
  color: #6d6d6d;
}

.thewall-post-read-color {
  color: #ec1c24;
}

.thewall-count-text {
  font-size: 1em;
  color: #6d6d6d;
  font-family: 'ProximaNovaCond-Semibold';
}

.thewall-report-text {
  font-size: 1em;
  font-family: 'Lato-Bold';
}

.thewall-post-pdf-text {
  font-family: 'Poppins-SemiBold';
  font-size: 0.875em;
  color: #8aaad9;
}

.thewall-link-text {
  font-size: 1em;
  color: #8aaad9;
  text-decoration: underline;
  font-family: 'ProximaNova-Regular';
}

.thewall-add-text {
  font-size: 1em;
  font-family: 'Lato-Bold';
  color: #8aaad9;
}

.thewall-create-link-text {
  font-size: 0.75em;
  font-family: 'Lato-Bold';
  color: #6d6d6d;
}

.thewall-tag-text {
  font-size: 0.938em;
  color: #828282;
  font-family: 'ProximaNova-Regular';
}

.thewall-name-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
}

.thewall-place-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
}

.thewall-remove-tag-text {
  font-size: 0.75rem;
  font-family: 'Poppins-SemiBold';
  color: #8aaad9;
  cursor: pointer;
}

.thewall-comment-name {
  font-family: 'ProximaNovaCond-Bold';
  font-size: 0.875em;
}

.thewall-comment-date {
  font-size: 0.75em;
  font-family: 'Lato-Regular';
  color: #4d4d4d;
}

.thewall-comment-text {
  font-size: 0.813em;
  font-family: 'Lato-Regular';
  color: #4d4d4d;
}

.thewall-remove-size {
  height: 20px;
  width: 20px;
}

.thewall-arrow-size {
  height: 20px;
  width: 20px;
}

.thewall-gallery-size {
  width: 20px;
  height: 20px;
}

.thewall-photo-size {
  width: 23px;
  height: 23px;
}

.thewall-tag-size {
  width: 20px;
  height: 20px;
}

.thewall-like-style {
  width: 20px;
  height: 20px;
}

.thewall-count-style {
  width: 22px;
  height: 22px;
}

.thewall-arrow-style {
  width: 14px;
  height: 14px;
}

.thewall-add-style {
  height: 25px;
  width: 25px;
}

.thewall-cancel-style {
  height: 20px;
  width: 20px;
}

.thewall-post-description-container {
  font-family: 'ProximaNova-Regular';
  margin-bottom: 0.625rem;
  white-space: pre-line;
}

.thewall-add-view {
  cursor: pointer;
}

.thewall-video-text-input {
  width: 85%;
  border: none;
  border-bottom: 2px solid #ec1c24;
  margin-right: 0.625rem;
}

.thewall-video-text-input :focus {
  outline: 0 !important;
}

.thewall-video-text-input:focus-visible {
  outline: 0 !important;
}

.thewall-video-info-container {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.thewall-link-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #e5edf8;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  padding-top: 0.313rem;
  padding-bottom: 0.313rem;
  border-radius: 5px;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.thewall-next-to-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.thewall-more-view-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.thewall-more-text {
  font-size: 0.75em;
  font-family: 'Lato-Bold';
}

.thewall-image-row {
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.thewall-first-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.thewall-comment-icon-container {
  margin-top: 0.313rem;
  margin-bottom: 0.313rem;
  cursor: pointer;
}

.thewall-tag-icon-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.thewall-no-click-div {
  pointer-events: none;
}

.thewall-arrow-icon {
  width: 20px;
  height: 20px;
}

.thewall-employee-view {
  height: 400px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.thewall-emp-image-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.625rem;
}

.thewall-profile-name {
  text-align: center;
  margin-bottom: 0;
  font-size: 1.125em;
  font-family: 'ProximaNovaCond-SemiBold';
}

.thewall-check-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.thewall-employee-check-box {
  height: 16px;
  width: 16px;
  border: 2px solid #bdbcb9;
  margin-right: 0.313rem;
}

.thewall-search-form {
  width: 90%;
}

.thewall-search-bar {
  border: none;
  outline: 0;
  background: none;
  width: 100%;
  transition: width 0.4s linear;
}

.thewall-body-container {
  padding: 1rem;
}

.thewall-continue-button {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
  margin: 0 auto;
  justify-content: center;
  display: flex;
  align-items: center;
}

.thewall-more-view-container {
  background-color: #ec1c24;
}

.thewall-count-left-text {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
  color: #fff;
}

.thewall-team-view {
  display: flex;
  justify-content: center;
}

.thewall-upload-icon {
  height: 80px;
  width: 80px;
}

.thewall-select-images-toupload {
  font-size: 1.125em;
  font-family: 'ProximaNova-Regular';
}

.thewall-select-images-toupload-container {
  margin-top: 1.25rem;
  text-align: center;
  margin-right: 1rem;
  margin-left: 1rem;
}

.thewall-upload-text {
  font-size: 1.125em;
  font-family: 'ProximaNova-Regular';
}

.thewall-button-style {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
  margin: 0 auto;
  display: block;
}

.thewall-bottom-button {
  color: #fff;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
}

.thewall-report {
  font-family: 'ProximaNova-Regular';
  font-size: 0.938em;
}

.thewall-header-view {
  display: flex;
  justify-content: flex-end;
}

.thewall-reply-button {
  color: #fff;
  font-size: 1em;
  display: inline-block;
  cursor: pointer;
}

.thewall-button {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
  margin: 0 auto;
  display: block;
}

.thewall-divider-style {
  height: 1px;
  background-color: #e7e7e7;
}

.thewall-row {
  display: flex;
  flex-direction: row;
}

/* ============================== GLOBAL SEARCH============================== */

.global-search-tabs {
  overflow-x: auto;
  overflow-y: hidden;
  display: -webkit-box;
  display: -moz-box;
  flex-wrap: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.global-search-image-row {
  display: flex;
  width: '100%';
  flex-direction: row;
  align-items: center;
}

/* ============================== IJPR SEARCH============================== */
.ijpr-subtitle {
  font-style: italic;
  color: grey;
}

/* --------------- MICROKNOW SCREEN --------------- */

.microknow-calender-style {
  width: 12px;
  height: 12px;
}

.microknow-event-date {
  margin-left: 0.625rem;
  font-size: 0.875em;
  font-family: 'ProximaNovaCond-Bold';
  color: #8aaad9;
}

.microknow-title {
  margin-top: 0.313rem;
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.microknow-year-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.microknow-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.microknow-calender-button-style {
  background: #fff;
  border: #fff;
  color: #ec1c24;
  font-family: 'ProximaNovaCond-Bold';
  font-size: 1em;
}

.microknow-calender-button-style:hover {
  text-decoration: none !important;
  color: #ec1c24 !important;
}

.microknow-header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.microknow-icon-row {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: center;
}

.microknow-search-form {
  width: 90%;
}

.microknow-search-bar {
  border: none;
  outline: 0;
  background: none;
  width: 100%;
  transition: width 0.4s linear;
}

/* --------------- DI SCREEN --------------- */
.di-outer-view {
  height: 40px;
  width: 300px;
  border-width: 1px;
  border-color: #ec1c24;
  border-radius: 50px;
  border-style: solid;
}

.di-left-selected {
  background-color: #ec1c24;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
}

.di-right-selected {
  background-color: #ec1c24;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
}

.di-left-selected-text {
  color: #fff;
}

.di-right-selected-text {
  color: #000;
}

.di-icon-text {
  margin-left: 0.313rem;
  color: #88a8d8;
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
  cursor: pointer;
}

.di-title {
  margin-top: 0.313rem;
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.di-content-html iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.di-content-html .embeddedContent {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  float: none;
}

.di-calender-style {
  width: 12px;
  height: 12px;
}

.di-event-date {
  margin-left: 0.625rem;
  font-size: 0.875em;
  font-family: 'ProximaNovaCond-Bold';
  color: #8aaad9;
}

.di-pledge-header {
  background-color: #8aaad9;
}

.di-pledge-count {
  font-family: 'ProximaNova-Bold';
  color: #fff;
  font-size: 1.563em;
  font-weight: bold;
}

.di-be-next-text {
  font-family: 'ProximaNova-Regular';
  color: #fff;
  font-size: 0.813em;
}

.di-i-pledge-text {
  font-family: 'ProximaNova-Regular';
  color: #8f8f8f;
  font-size: 0.875em;
}

.di-calender-button-style {
  background: #fff;
  border: #fff;
  color: #ec1c24;
  font-family: 'ProximaNovaCond-Bold';
  font-size: 1em;
}

.di-calender-button-style:hover {
  text-decoration: none !important;
  color: #ec1c24 !important;
}

.di-year-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* --------------- SALES INCENTIVE SCREEN --------------- */
.sales-incentive-banner-image {
  width: 100%;
}

/* --------------- IJPR SCREEN --------------- */
.ijpr-overview-item-title {
  font-family: 'ProximaNovaCond-Semibold';
  font-size: 1.25em;
}

.ijpr-overview-item-subtitle {
  font-family: 'ProximaNova-Regular';
  font-size: 1em;
}

.ijpr-overview-item-subtitle-grey {
  font-family: 'ProximaNova-Regular';
  font-size: 1em;
  color: #757575;
}

.ijpr-overview-item-location-icon {
  height: 18px;
  width: 18px;
}

.ijpr-overview-item-location-text {
  font-family: 'ProximaNova-SemiBold';
  color: #96b2d3;
  font-size: 1.063em;
}

.ijpr-overview-item-refer-box {
  border: 1px solid #c2c2c2;
  border-radius: 5px;
}

.ijpr-overview-item-linkedin-icon {
  height: 30px;
  width: 30px;
}

.ijpr-overview-confirm-box {
  background-color: #f7f7f9;
}

.ijpr-success-modal-title {
  font-size: 1.75em;
  font-family: 'ProximaNova-Bold';
}

.ijpr-success-modal-subtitle {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
}

.ijp-tab-landing-title {
  font-family: 'ProximaNova-Bold';
  font-size: 1.75em;
}

.ijp-tab-search-outer-container {
  background-color: #f3f3f3;
}

.ijp-search-bar-input {
  border: none;
  outline: 0;
  background: none;
  width: 100%;
  transition: width 0.4s linear;
}

.ijp-search-bar-left-icon {
  height: 24px;
  width: 24px;
}

.ijp-tab-search-bottom-text-title {
  font-family: 'ProximaNova-Regular';
  font-size: 1em;
  color: #757575;
}

.ijp-job-display-type-text {
  font-size: 1.2em;
}

.ijp-tab-search-bottom-text-parent-subtitle {
  font-family: 'ProximaNova-Regular';
  font-size: 1em;
}

.ijp-tab-search-bottom-text-child-subtitle {
  font-family: 'ProximaNova-Regular';
  font-size: 1em;
  text-decoration: underline;
}

.ijp-form-min-width {
  min-width: 80%;
}

.ijp-category-box-container {
  border: 1px solid #dedede;
  border-radius: 4px;
}

.ijp-category-box-title {
  font-family: 'ProximaNova-Regular';
  font-size: 1em;
  color: #000;
}

.ijp-category-box-title-highlighted {
  font-family: 'ProximaNova-SemiBold';
  font-size: 1em;
  color: #000;
}

.ijp-search-result-text {
  font-family: 'ProximaNova-SemiBold';
  font-size: 1.25em;
  color: #000;
}

.ijp-search-item-title {
  font-family: 'ProximaNova-SemiBold';
  font-size: 1em;
  color: #000;
}

.ijp-search-item-subtitle {
  font-family: 'ProximaNova-Regular';
  font-size: 1em;
  color: #757575;
}

.ijp-search-item-right-icon {
  height: 16px;
  width: 16px;
}

.ijpr-detail-title {
  font-size: 1.313em;
  font-family: 'ProximaNova-Bold';
}

.ijpr-detail-subtitle {
  color: #8aaad9;
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
}

.ijpr-application-status-container {
  border-radius: 7px 7px 7px 7px;
  background-color: #f7f7f9;
  width: '100%';
  padding: 10px 10px 10px 10px;
}

.ijpr-application-status-text {
  color: #757575;
}

.ijpr-application-status-value {
  font-weight: bold;
  color: #000000;
}

.ijpr-detail-date {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
  color: #757575;
}

.ijpr-detail-field-box-title {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
  color: #757575;
}

.ijpr-detail-field-box-subtitle {
  font-size: 1.125em;
  font-family: 'ProximaNova-SemiBold';
}

.ijpr-detail-section-title {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
}

.ijpr-detail-referer-box-container {
  background-color: #f7f7f9;
}

.ijpr-detail-referer-box-title {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
}

.ijpr-detail-referer-box-show-more-text {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
  color: #ec1c24;
}

.ijpr-detail-hirer-container {
  background-color: #f7f7f9;
}

.ijpr-detail-hirer-title {
  font-size: 1em;
  font-family: 'ProximaNova-SemiBold';
}

.ijpr-detail-hirer-subtitle {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
  color: #757575;
}

.ijpr-detail-request-help-container {
  background-color: #f7f7f9;
}

.ijpr-detail-request-help-icon {
  height: 36px;
  width: 36px;
}

.ijpr-detail-request-help-title {
  font-family: 'ProximaNova-Regular';
}

.ijpr-detail-request-help-highlighted-text {
  font-family: 'ProximaNova-SemiBold';
  color: #ec1c24;
  text-decoration: underline;
}

.ijpr-overview-item-stage-text {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
  border-radius: 5px;
  color: #fff;
}

.ijpr-overview-item-stage-status-blue-track-style {
  background-color: #6192db;
}

.ijpr-overview-item-stage-status-amber-track-style {
  background-color: #ea8f51;
}

.ijpr-overview-item-stage-status-green-track-style {
  background-color: #5bb246;
}

.ijpr-overview-item-stage-status-default-track-style {
  background-color: #9d9d9d;
}

.ijpr-overview-item-right-icon {
  height: 20px;
  width: 20px;
}

.ijpr-overview-item-indent-status {
  font-size: 1em;
  font-family: 'ProximaNova-SemiBold';
  color: #e9830d;
}

.ijp-apply-modal-title {
  font-family: 'ProximaNova-SemiBold';
}

.ijp-apply-modal-subtitle {
  font-family: 'ProximaNova-Regular';
}

.ijp-applied-modal-title {
  font-family: 'ProximaNova-SemiBold';
}

.ijp-applied-modal-subtitle {
  font-family: 'ProximaNova-Regular';
}

.ijp-refer-consent-modal-title {
  font-family: 'ProximaNova-SemiBold';
}

.ijp-refer-consent-modal-scroll-box {
  height: 310px;
  overflow-y: scroll;
}

.ijp-refer-consent-modal-subtitle {
  font-family: 'ProximaNova-Regular';
}

.ijpr-filter-title {
  font-family: 'ProximaNova-Bold';
  font-size: 1.25em;
}

.ijpr-filter-range-marker {
  font-family: 'ProximaNova-SemiBold';
  font-size: 1em;
  min-width: 50px;
}

.ijpr-timeline-line {
  height: 2px;
  background: #9d9d9d;
}

.ijpr-timeline-line-green-track-style {
  background: #5bb246;
}

.ijpr-timeline-line-default-track-style {
  background: #9d9d9d;
}

.ijpr-timeline-outer-dot {
  border: 3px solid #9d9d9d;
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.ijpr-timeline-outer-dot-blue-track-style {
  border: 3px solid #6192db;
}

.ijpr-timeline-outer-dot-amber-track-style {
  border: 3px solid #ea8f51;
}

.ijpr-timeline-outer-dot-green-track-style {
  border: 3px solid #5bb246;
}

.ijpr-timeline-outer-dot-default-track-style {
  border: 3px solid #9d9d9d;
}

.ijpr-timeline-inner-dot {
  background-color: #9d9d9d;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: translate(20%, 20%);
}

.ijpr-timeline-inner-dot-blue-track-style {
  background-color: #6192db;
}

.ijpr-timeline-inner-dot-amber-track-style {
  background-color: #ea8f51;
}

.ijpr-timeline-inner-dot-green-track-style {
  background-color: #5bb246;
}

.ijpr-timeline-inner-dot-default-track-style {
  background-color: #9d9d9d;
}

.ijpr-timeline-title {
  font-size: 1em;
  font-family: 'ProximaNova-SemiBold';
}

.ijpr-timeline-subtitle {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
  color: #757575;
}

.ijpr-timeline-highlighted-text {
  font-size: 1em;
  font-family: 'ProximaNova-SemiBold';
  color: #ec1b25;
  text-decoration: underline;
}

.ijpr-refer-form-dialog-input-title {
  font-family: 'ProximaNova-Regular';
  font-family: 1em;
}

.ijpr-refer-form-dialog-doc-icon {
  height: 32px;
  width: 32px;
}

.ijpr-refer-form-dialog-uploaded-item-container {
  background-color: #f7f7f9;
}

.ijpr-refer-form-dialog-uploaded-item-title {
  font-family: 'ProximaNova-SemiBold';
  color: #757575;
}

.ijpr-refer-form-dialog-uploaded-item-remove-text {
  font-family: 'ProximaNova-Regular';
  color: #ec1c24;
}

.ijpr-tab-top-banner {
  background-color: #f7f7f9;
  font-family: 'ProximaNova-Regular';
  color: #757575;
}

.ijpr-border-radius {
  border-radius: 7px 7px 7px 7px;
}

.ijpr-saved-jobs-icon {
  width: 24px;
  height: 24px;
}

.ijpr-saved-jobs-title {
  font-size: 1.125em;
}

.ijpr-saved-job-item-right-icon {
  height: 24px;
  width: 24px;
}

.ijpr-detail-saved-job-icon {
  height: 24px;
  width: 24px;
}

/* --------------- MICROBIZ SCREEN --------------- */
.microbiz-calender-style {
  width: 12px;
  height: 12px;
}

.microbiz-event-date {
  font-size: 0.875em;
  font-family: 'ProximaNovaCond-Bold';
  color: #8aaad9;
}

.microbiz-title {
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.microbiz-icon-text {
  color: #88a8d8;
  font-size: 1em;
  font-family: 'ProximaNovaCond-SemiBold';
  cursor: pointer;
}

.microbiz-calender-button-style {
  background: #fff;
  border: #fff;
  color: #ec1c24;
  font-family: 'ProximaNovaCond-Bold';
  font-size: 1em;
}

.microbiz-search-form {
  width: 90%;
}

.microbiz-search-bar {
  border: none;
  outline: 0;
  background: none;
  width: 100%;
  transition: width 0.4s linear;
}

.microbiz-calender-button-style:hover {
  text-decoration: none !important;
  color: #ec1c24 !important;
}

.microbiz-content-html .embeddedContent {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  float: none;
}

.microbiz-content-html iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* --------------- PODCAST SCREEN --------------- */
.podcast-title {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
  color: #000;
}

.podcast-description {
  font-size: 0.875em;
  font-family: 'ProximaNova-Regular';
  color: #000;
}

.podcast-see-more-less {
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
  color: #ec1c24;
}

.podcast-host-name {
  font-size: 14px;
  font-family: 'ProximaNova-Bold';
  color: #000;
}

.podcast-host-location {
  font-size: 14px;
  font-family: 'ProximaNova-Regular';
  color: #757575;
}

.podcast-play-icon {
  height: 30px;
  width: 30px;
}

.podcast-play-pause {
  height: 25px;
  width: 25px;
}

.podcast-published-duration {
  font-size: 14px;
  font-family: 'ProximaNova-Regular';
  color: #757575;
}

.podcast-published-duration-value {
  font-size: 14px;
  font-family: 'ProximaNova-Regular';
  color: #000;
}

.podcast-timer-text-style {
  font-size: 13px;
  font-family: 'ProximaNova-Regular';
  color: #606060;
}

.podcast-participants-location {
  font-size: 12px;
  font-family: 'ProximaNova-Regular';
  color: #93abd6;
}

.podcast-new-view {
  background-color: #75aaf8;
  border-radius: 3px;
}

.podcast-new-text {
  font-size: 13px;
  font-family: 'ProximaNova-Bold';
  color: #fff;
}

.podcast-linked-in {
  display: flex;
  left: 62%;
  bottom: 0;
  height: 2.125rem;
  width: 2.125rem;
  position: absolute;
}

.podcast-host-pronoun {
  font-size: 0.813em;
  color: #6d6e71;
}

.podcast-content-html .embeddedContent {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  float: none;
}

.podcast-content-html iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.podcast-episode-media-type {
  color: gray;
  font-size: 12px;
  text-align: center;
}

/* --------------- CHAT SCREEN --------------- */
.chat-divider-thin {
  height: 1px;
}

.chat-bold-text {
  font-family: 'ProximaNova-Bold';
}

.chat-semi-bold-text {
  font-family: 'ProximaNova-SemiBold';
}

.chat-base-text-sm {
  font-size: 0.875em;
}

.chat-base-text-md {
  font-size: 1em;
}

.chat-grey-text {
  color: #757575;
}

.chat-delete-text {
  color: red;
}

.chat-header {
  background-color: #e8e8e8;
  min-height: 58px;
}

.chat-icon-search {
  height: 20px;
  width: 20px;
}

.chat-sidebar-container {
  border-left: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  height: calc(100vh - 180px);
}

.chat-sidebar-border-right {
  border-right: 1px solid #e7e7e7;
}

.chat-count-view {
  background: #f00;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  color: #fff;
  font-family: 'ProximaNova-SemiBold';
  font-size: 0.875em;
}

.chat-dialog-highlighted-container {
  background-color: #f7f7f7;
}

.chat-sidebar-item:hover {
  background-color: #f7f7f7;
}

.chat-base-bubble {
  max-width: 65%;
}

.chat-self-bubble {
  background-color: #d1e3fe;
  border-radius: 10px 10px 0 10px;
}

.chat-other-bubble {
  background-color: #fff;
  border-radius: 10px 10px 10px 0;
}

.chat-left-panel-container {
  overflow-y: auto;
  height: calc(100vh - 240px);
}

.chat-conversation-container {
  overflow-y: auto;
  height: calc(100vh - 300px);
}

.chat-texinput-outer-container {
  background-color: #f5f5f5;
  border-radius: 15px;
}

.chat-texinput-outer-container {
  background-color: #fff;
}

.chat-textinput {
  width: 100%;
  overflow: hidden;
  outline: none;
  border: none;
  text-indent: 5px;
  min-height: 20px;
  border-radius: 15px;
}

.chat-emoji-outer-container {
  bottom: 30px;
  right: 0;
}

.chat-search-bar {
  border: none;
  outline: 0;
  background-color: #fafafa;
  width: 100%;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  transition: width 0.4s linear;
}

.chat-search-cross-container {
  background-color: #fafafa;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.chat-theme-highlighted-text {
  color: #ec1c24;
}

.chat-theme-highlighted-text-disabled {
  color: #8b8b8b;
}

.chat-selected-employee-container {
  background-color: #eee;
  border-radius: 20px;
}

.chat-selected-employee-close-icon {
  height: 18px;
  width: 18px;
  right: 0;
}

.chat-employee-selected-outer-container {
  max-height: 150px;
}

.chat-main-box {
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 16%);
}

.chat-emoji-icon {
  height: 20px;
  width: 20px;
}

.chat-plus-icon {
  height: 26px;
  width: 26px;
}

.chat-send-icon {
  height: 24px;
  width: 24px;
}

.chat-new-group-icon {
  height: 24px;
  width: 24px;
}

.chat-group-upload-image-icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.chat-group-group-create-textinput {
  border: 1px solid #e5e5e5;
}

.chat-avatar-box {
  height: 160px;
}

.chat-rounded-avatar {
  width: 100%;
  height: 100%;
  clip-path: circle();
  object-fit: contain;
}

.chat-rounded-avatar-option {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #393a3980;
  color: #fff;
  border-radius: 500px;
  font-size: 0.875em;
}

.chat-group-detail-edit {
  height: 16px;
  width: 16px;
}

.chat-reply-container {
  background-color: #e8e8e8;
  border-left: 1px solid #ec1c24;
}

.chat-outgoing-reply-container {
  background-color: #f4f5f5;
  border-left: 1px solid #ec1c24;
}

.chat-outgoing-reply-icon {
  height: 46px;
  width: 46px;
  object-fit: cover;
}

.chat-image-chat-view-container {
  max-width: 35vmin;
}

.chat-limit-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.chat-limit-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.chat-message-item-more-options-container {
  right: 0;
  background-color: #d1e3fe;
}

.chat-message-incoming-item-more-options-container {
  right: 0;
  background-color: #fff;
}

.chat-message-item-more-options-icon {
  height: 16px;
  width: 16px;
  transform: rotate(-90deg);
}

.chat-notification-cell-container {
  background-color: #393a39;
  border-radius: 20px;
  color: #e8e8e7;
}

.chat-input-main-container {
  --translateY: 0;
  --padding-bottom: 0.5rem;
  --bottom: var(--padding-bottom);
  display: flex;
  width: 100%;
  max-width: 100%;
  padding-top: 0.25rem;
  flex-direction: column;
  flex: 0 0 auto;
  position: relative;
  transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform var(--transition-standard-out);
  transform: translate3d(0, var(--translateY), 0);
}

.chat-input-main-body-container {
  --translateY: 0;
  overflow-y: auto;
  width: 100%;
  flex: 1 1 auto;
  position: relative;
  transform: translate3d(0, var(--translateY), 0);
  transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform var(--transition-standard-out);
}

.chat-convo-room-main-container {
  display: flex;
  flex-direction: column;
  grid-row-start: 1;
  grid-column-start: 1;
  background-color: #f4f4f5;
  background-color: var(--background-color);
  overflow: hidden;
  background-size: contain;
  background-color: #f5f5f5;
}

.chat-doc-icon {
  height: 24px;
  width: 24px;
}

.chat-floating-icon {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #e30613;
  border-radius: 30px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 1;
}

.chat-icon {
  width: 75%;
  height: 75%;
}

.chat-floating-icon-text-bubble {
  background-color: #fff;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  top: 0;
  right: 0;
  color: #ec1c24;
  font-size: 0.875em;
}

.chat-default-icon {
  height: 60px;
  width: 60px;
}

.chat-default-message {
  color: #6d6e71;
}

.chat-default-emoji-container {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #fff;
  font-size: 2em;
}

/* --------------- WALLPAPER SCREEN --------------- */

.wallpaper-theme-text-semi-bold {
  font-family: 'ProximaNova-SemiBold';
  color: #ec1c24;
}

.wallpaper-info-icon {
  height: 14px;
  width: 14px;
}

.business-card-share-icon {
  height: 28px;
  width: 28px;
}

.wallpaper-info-html {
  font-size: 0.875em;
}

.wallpaper-info-popover-container {
  max-width: 320px;
}

.wallpaper-image:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 65.1%, rgba(0, 0, 0, 0.46) 100%);
}

.wallpaper-switch .custom-control-input ~ .custom-control-label::before {
  color: #fff;
  border-color: #ec1c24;
  background-color: #ec1c24;
  cursor: pointer;
}

.wallpaper-switch .custom-control-input ~ .custom-control-label::after {
  background-color: #fff;
  cursor: pointer;
}

.wallpaper-faded-element {
  opacity: 0.5;
}

/* --------------- TRAVEL REQUEST SCREEN --------------- */

.travel-request-dropdown-style {
  width: '100%';
}

.travel-request-selected-type {
  background-color: #f5f5f5;
  height: 50px;
  width: 50%;
}

.travel-request-un-selected-type {
  height: 50px;
  width: 50%;
}

.travel-request-selected-type-form {
  background-color: #f5f5f5;
}

.travel-request-type {
  width: 150px;
}

.travel-request-add-traveller {
  color: #ec1c24;
  font-size: 14px;
  font-family: 'ProximaNova-Bold';
}

.travel-request-add-traveller-view {
  background-color: #fff;
  border-style: dashed;
  border-color: #a7a7a7;
  border-radius: 6px;
}

.travel-request-travellers-view {
  background-color: #e8e8e8;
  height: 50px;
}

.travel-request-plus-minus {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  border: 1px solid rgb(223, 223, 223);
}

.travel-request-count {
  height: 30px;
  width: 30px;
  border-radius: 3px;
  border: 1px solid rgb(223, 223, 223);
}

.travel-request-proceed-btn {
  background-color: #ec1c24;
  border: 1px solid #ec1c24;
  color: #fff;
  font-size: 1em;
  min-width: 100px;
  height: 35px;
  text-align: center !important;
  font-family: 'ProximaNova-Regular';
}

.travel-request-wrapper {
  position: relative;
  width: 100%;
  user-select: none;
}

.travel-request-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border: 1px solid rgb(223, 223, 223);
  border-radius: 3px;
  background-color: white;
  cursor: default;
  cursor: pointer;
  width: 100%;
  height: 40px;
}

.travel-request-list {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 175px;
  border: 1px solid rgb(223, 223, 223);
  border-radius: 3px;
  background-color: white;
  overflow: scroll;
  box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
}

.travel-request-list-item {
  width: 100%;
  text-overflow: ellipsis;
  cursor: default;
  cursor: pointer;
  border: transparent;
  background-color: white;
  height: 40px;
}

.travel-request-review-tickets {
  background-color: #f5f5f5;
  border-radius: 3px;
  border-style: dashed;
  border-color: #6d6e71;
}

.travel-request-comments {
  background-color: #fff;
  border-radius: 6px;
}

.travel-request-mode {
  background-color: #fff;
  border-radius: 50px;
  border-color: #6d6e71;
  border-width: 1px;
  border-style: solid;
}

.travel-request-mode-divider {
  height: 1px;
  width: 150px;
  border-color: #6d6e71;
  border-width: 1px;
  border-style: dashed;
}

.travel-request-mode-dot {
  height: 8px;
  width: 8px;
  background-color: #6d6e71;
  border-radius: 50px;
}

.travel-request-modification {
  background-color: rgba(248, 152, 56, 0.2);
  border-radius: 5px;
  border-color: #f89838;
  border-width: 1px;
  border-style: dashed;
}

.travel-request-rejected {
  background-color: rgba(237, 28, 36, 0.1);
  border-radius: 5px;
  border-color: #ed1c24;
  border-width: 1px;
  border-style: dashed;
}

.travel-request-choose-profile {
  background-color: #f7f7f9;
  height: 70px;
}

.travel-request-delete-travel-accommodation {
  color: #ec1c24;
  font-family: 'ProximaNova-Bold';
}

.travel-request-delete-icon {
  height: 20px;
  width: 15px;
}

.travel-request-plan-travel-selected-label {
  color: #000;
  font-size: 16px;
  font-family: 'ProximaNova-SemiBold';
}

.travel-request-plan-travel-label {
  color: #595959;
  font-size: 16px;
  font-family: 'ProximaNova-Regular';
}

.travel-request-purpose {
  color: #000;
  font-size: 16px;
  font-family: 'ProximaNova-Bold';
}

.travel-request-request-id-date {
  color: #757575;
  font-size: 14px;
  font-family: 'ProximaNova-Regular';
}

.travel-request-status {
  color: #3a3a3a;
  font-size: 14px;
  font-family: 'ProximaNova-Bold';
}

.travel-request-plan-travel-label-view {
  color: #757575;
  font-size: 14px;
  font-family: 'ProximaNova-Regular';
}

.travel-request-review-title {
  color: #000;
  font-size: 16px;
  font-family: 'ProximaNova-Bold';
}

.travel-request-general-message-box {
  background-color: #f5f5f5;
  border: 1px dashed #a7a7a7;
}

.travel-request-light-grey-text {
  color: #757575;
}

.travel-request-request-indicator-base {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.travel-request-request-indicator-approved {
  background-color: #81b141;
}

.travel-request-request-indicator-cancelled {
  background-color: #ed1c24;
}

.travel-request-request-indicator-awaiting-approval {
  background-color: #5591b7;
}

.travel-request-request-indicator-mod-requested {
  background-color: #5591b7;
}

.travel-request-comment-box-base {
  border-radius: 5px;
  border-width: 1px;
  border-style: dashed;
  background-color: #5591b71a;
  border-color: #5591b7;
}

.travel-request-comment-box-approved {
  background-color: #81b1411a;
  border-color: #81b141;
}

.travel-request-comment-box-cancelled {
  background-color: #ed1c241a;
  border-color: #ed1c24;
}

.travel-request-comment-box-awaiting-approval {
  background-color: #5591b71a;
  border-color: #5591b7;
}

.travel-request-comment-box-mod-requested {
  background-color: #5591b71a;
  border-color: #5591b7;
}

.travel-request-dashed-divider {
  border-bottom: 1px dashed #000;
}

.travel-request-doc-icon {
  height: 18px;
  width: 18px;
}

.travel-request-document-title {
  font-family: 'ProximaNova-SemiBold';
  text-decoration: underline;
}

.travel-request-traveller-title {
  font-family: 'ProximaNova-Bold';
  font-size: 1em;
}

.travel-request-employee-box {
  background-color: #f7f7f9;
}

.travel-request-employee-box-title {
  font-family: 'ProximaNova-SemiBold';
}

.travel-request-employee-box-subtitle {
  color: #757575;
}

.travel-request-primary-text-semi-bold {
  color: #ec1c24;
  font-family: 'ProximaNova-SemiBold';
}

.travel-request-close-icon {
  height: 22px;
  width: 22px;
}

.travel-request-updates-container {
  background: #f5f5f5;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  min-height: 450px;
  overflow-y: scroll;
  white-space: nowrap;
}

.action-request-item-style {
  background: #fff;
  border-radius: 8px;
}

.travel-request-updates-style {
  color: #000;
  font-family: 'ProximaNova-SemiBold';
  font-size: 1.125em;
}

.travel-quick-request-purpose {
  color: #000;
  font-size: 0.875em;
}

.travel-empty-updates {
  color: #5591b7;
  font-family: 'ProximaNova-SemiBold';
  font-size: 0.875em;
}

.travel-request-filter-wrapper {
  background-color: #f5f5f5;
}

.tr-dropdown-button-style {
  background-color: #f5f5f5;
}

.tr-dropdown-item-style {
  background-color: #f5f5f5;
  color: #000;
  min-width: 112px;
}

.tr-dropdown-item-active {
  background-color: #c4c4c4;
}

.tr-dropdown-button-text {
  color: #000000;
  font-family: 'ProximaNova-SemiBold';
  background-color: #f5f5f5;
  margin-right: 10%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tr-dropdown-popover {
  background-color: #f5f5f5;
}

.tr-dropdown-popover .arrow {
  display: none;
}

.tr-dropdown-arrow {
  font-size: 0.625em;
}

.mi-hr-activity-btn-container {
  position: absolute;
  top: 0px;
  margin-top: 5px;
  right: 0px;
}

.mi-hhr-checlist-title {
  font-family: 'ProximaNova-Bold';
  font-size: 1.125em;
  left: 15px;
}

.view-answers-block {
  justify-content: space-between;
}

.ans-count-answer-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.ans-count-view {
  font-family: 'ProximaNova-Regular';
  font-size: 1rem;
  border-color: lightgray;
  border-width: 1px;
  border-style: solid;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}

.mlf-testimony-card {
  font-family: 'ProximaNova-Regular';
  color: #757575;
  border: 1px solid #e0e0e0;
}

.dynamic-text-holiday-container {
  margin-right: 15px;
  margin-left: -10px;
}

.holiday-text-container {
  margin-right: 15px;
  margin-left: -10px;
}

.dynamic-columns-holiday-day-text {
  width: 15%;
  line-height: 18px;
  font-size: 0.813em;
  font-family: 'Lato-Regular';
}

.dynamic-columns-holiday-title-text {
  font-family: 'ProximaNova-Bold';
  width: 100%;
  line-height: 18px;
  font-size: 14px;
}

.holiday-item-line-height {
  line-height: 26px;
}

.dynamic-column-date-text-item {
  font-family: 'ProximaNova-Bold';
  line-height: 18px;
  width: 15%;
}

.holiday-container {
  padding: 20px;
}

.employee-location-text-item {
  font-family: 'ProximaNova-Regular';
  font-weight: 600;
  font-size: 16px;
  color: #4472c4;
  position: absolute;
  top: 168px;
}

.testimonial-text-container {
  border-left: 5px solid #e0e0e0;
  padding-left: 1rem;
  padding-bottom: 2px;
}

.mlf-testimony-card-text {
  color: #757575;
  font-family: 'ProximaNova-Regular';
  font-size: 13px;
}

.mlf-testimony-html-text {
  font-size: 1em;
  font-family: 'ProximaNova-Bold';
  white-space: pre-line;
}

.custom-blockquote {
  font-weight: 100;
  font-size: 1rem;
  line-height: 1.4;
  position: relative;
  padding: 1.5rem 4rem 0rem 4rem;
  text-align: center;
  align-self: center;
}

.custom-blockquote:before,
.custom-blockquote:after {
  position: absolute;
  color: #f1efe6;
  font-size: 8rem;
  width: 4rem;
  height: 4rem;
}

.custom-blockquote:before {
  content: '“';
  left: 0rem;
  top: -2rem;
}

.custom-blockquote:after {
  content: '”';
  right: 0rem;
  bottom: 0rem;
}

.custom-blockquote-text-bold {
  font-weight: bold;
}

.bottom-border-testimobial {
  border-bottom: 1px solid #e0e0e0;
}

.bold-and-underline-text {
  font-weight: bold;
  text-decoration: underline;
}

.search-bar-with-icon-ask_ml {
  width: 50%;
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-self: center;
}

.search-bar-with-icon {
  min-width: 100%;
  padding: 4px;
  display: flex;
  flex-direction: row;
}

.search-bar-with-icon-stoore {
  min-width: 80%;
  padding: 4px;
  display: flex;
  flex-direction: row;
}

.search-bar-border {
  border: #d5d5d5 solid 1px;
}

.billing-note {
  font-size: small;
}

.ceo-chat-ask-button {
  background-color: green !important;
  border: 1px solid #88a8d8;
  color: #fff;
  font-size: 1em;
  font-family: 'ProximaNova-Regular';
}

.chief-profile-container {
  margin-top: 20px;
  align-items: center;
}

.fallback-color {
  background-color: #f8f9fa;
}

.variable-pay-table-block {
  border-left-width: 1px;
  border-left-color: #d9d9d9;
  border-left-style: solid;
}

.variable-pay-table {
  width: 100%;
  border-color: #d9d9d9;
  margin-top: 5px;
  margin-bottom: 10px;
}

.vp-detail-title {
  font-family: 'ProximaNova-SemiBold';
  color: #5c5c5c;
  margin-right: 5px;
  font-size: 1.2em !important;
}

.vp-detail-value {
  font-family: 'ProximaNova-SemiBold';
  color: #000;
  font-size: 1.2em !important;
}

.vp-currency-note {
  text-align: right;
  font-family: 'ProximaNova-Regular';
  color: #000;
}

.vp-foot-note {
  font-size: 12px;
  color: #000;
  font-family: 'ProximaNova-Regular';
  line-height: 16px;
}

.vp-special-incentives {
  color: #4472c4;
  font-family: 'ProximaNova-SemiBold';
  font-size: 16px;
}

.play-icon-container {
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 100;
  padding-right: 30px;
  padding-bottom: 30px;
}

.play-icon-container-paddings {
  padding-right: 5px;
  padding-bottom: 60px;
}

.video-play-icon {
  width: 40px;
}

.find-your-tribe-container {
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
}

.find-tribe-text {
  font-family: 'ProximaNovaCond-Bold';
  font-size: 16px;
}

.vp-table-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.vp-border-top {
  border-top: 1px;
  border-color: #d9d9d9;
  border-top-style: solid;
}

.vp-currency-note-container {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.variable-pay-cell {
  padding: 4px 8px;
  font-size: 0.8em;
}

.holiday-filter-style {
  margin-left: auto;
}

/* ASK ORG */

.ask-org-string {
  font-family: 'ProximaNova-Regular';
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}

.ask-ml-blue-text {
  font-family: 'ProximaNova-Bold';
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #5591b7;
}

.ask-org-question-text-container {
  background-color: #f9f9f9;
  font-family: 'ProximaNova-Regular';
  font-weight: 600;
  font-size: 16px;
}

.ask-question-results {
  border-bottom: 1px solid #efefef;
}

.ask-org-answer-container {
  border: 1px solid #efefef;
}

.ask-org-pdf-icon {
  height: 25px;
  width: 25px;
}

.custom-carousel-indicators {
  width: 100%;
}

.custom-carousel-indicators .carousel-control-prev-icon,
.custom-carousel-indicators .carousel-control-next-icon {
  filter: invert(100%);
  display: none;
}

.custom-carousel-indicators .carousel-indicators li {
  background-color: black;
  display: none;
}

.ask-org-feedback-container {
  background-color: #f9f9f9;
}

.ask-org-request-modal-container {
  width: 100%;
  height: 100px;
}

.ask-org-custom-modal {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.ask-org-request-modal-title {
  font-size: 16px;
}

.ask-org-modal-no-container {
  height: 35px;
  width: 120px;
  background-color: #f7f7f9;
  color: #5591b7;
  font-weight: 700;
}

.ask-org-modal-yes-container {
  height: 35px;
  width: 120px;
  background-color: #5591b7;
  color: #ffffff;
  font-weight: 700;
}

.ask-org-feedback-icon {
  height: 22px;
  width: 22px;
}

.ask-org-chat-bot-icon {
  height: 100px;
  width: 100px;
}

.ask-org-chat-widget-bot-icon {
  height: auto;
  width: 80px;
}

.chat-bot-floating-icon {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  border-radius: 30px;
  box-shadow: 0 2px 10px 1px #b5b5b5;
  z-index: 1;
}

.chat-bot-floating-close-icon {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  border-radius: 30px;
  box-shadow: 0 2px 10px 1px #b5b5b5;
  z-index: 1;
  background-color: #3e81ab !important;
}

.ask-org-chat-bot-float-icon {
  height: 162%;
  width: 162%;
}

.ask-org-close-float-icon {
  height: 50%;
  width: 50%;
}

.ask-org-common-custom-button-inner-container {
  padding: 0.25rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
}

.ask-org-carousel-indicators {
  width: 1%;
}

.widget-container {
  position: absolute;
  width: 370px;
  max-width: 370px;
  right: 0px;
  z-index: 1030;
  box-shadow: 0 1px 1.5px -1px rgba(0, 0, 0, 0.048), 0 2.5px 3.7px -1px rgba(0, 0, 0, 0.069),
    0 5px 7px -1px rgba(0, 0, 0, 0.085), 0 9.7px 12.5px -1px rgba(0, 0, 0, 0.101),
    0 19.7px 23.4px -1px rgba(0, 0, 0, 0.122), 0 54px 56px -1px rgba(0, 0, 0, 0.17);
  border-radius: 10px;
  bottom: 0;
  margin: 0px 0px 70px 20px;
  overflow: hidden;
  min-height: 450px;
  max-height: 550px;
  background-color: #f4f4f5;
  background-color: var(--background-color);
  overflow: hidden;
  background-size: contain;
  background-color: #f5f5f5;
  cursor: auto;
}

.ask-ml-welcome-note-text-style {
  font-size: 0.875em;
  text-align: center;
  cursor: text;
}

.askml-chatbot-header {
  color: white;
  background-color: #5591b7;
}

.askml-chat-widget-header-icon {
  height: 40px;
  width: 40px;
}

.askml-chat-widget-header-right-icon {
  height: 35px;
  width: 35px;
}

.ask-ml-chat-bot-header-title {
  font-size: 14px;
  font-family: 'ProximaNova-Bold';
  cursor: text;
}

.ask-ml-chat-bot-header-subtitle {
  font-size: 0.938em;
  font-family: 'Lato-Regular';
  cursor: text;
}

.ask-ml-chat-body-container {
  max-height: 320px;
  overflow-y: auto;
}

.ask-ml-chat-profile-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.ask-ml-chat-bubble {
  border-radius: 5px;
  padding: 10x;
  margin: 10px;
}

.ask-ml-chat-textinput {
  width: 100%;
  overflow: hidden;
  outline: none;
  border: none;
  text-indent: 5px;
  min-height: 20px;
  border-radius: 15px;
  font-size: 0.875em;
}

.ask-ml-chat-bot-qa-text {
  font-size: 0.875em;
  cursor: text;
}

.ask-ml-chat-send-icon {
  height: 20px;
  width: 20px;
}

.errorViewText {
  color: red;
  width: 100%;
  padding-top: 5px;
  text-align: center;
  display: inline-block;
}

.ask-ml-prompt-container {
  position: absolute;
  width: 100%;
  max-height: 83%;
  bottom: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.ask-ml-prompt-content-container {
  flex: 1;
  overflow-y: auto;
}

.ask-ml-prompt-header {
  font-family: 'ProximaNova-Regular';
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
}

.ask-ml-prompt-title-container {
  background-color: #f2f2f2;
}

.ask-ml-prompt-border {
  border-bottom: 1px solid #f2f2f2;
}

.ask-ml-prompt-text {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
}

/* --------------- MI AUCTION SCREEN --------------- */

.mi-auction-list-item {
  background-color: #fbfbfb;
}

.mi-auction-like-comment-views-category {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.mi-auction-count {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
}

.mi-auction-views-count {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  margin-top: -5px;
}

.mi-auction-normal-icon {
  width: 18px;
  height: 18px;
}

.mi-auction-category-block {
  background-color: #8f8f8f;
  padding: 5px 10px 5px 10px;
  border-radius: 8px;
  font-size: 10px;
  font-family: 'ProximaNova-SemiBold';
  color: #fff;
}

.mi-auction-item-details-container {
  border: 1px solid #e4e4e4;
  margin-bottom: 20px;
}

.mi-auction-name-views-likes-category-block {
  padding: 10px 15px;
  border-bottom: 1px solid #e4e4e4;
}

.price-bids-timer-view {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 10px 15px;
}

.mi-auctionbids-timer-view {
  display: flex;
  flex-direction: row;
  width: 30%;
  justify-content: space-between;
}

.mi-auction-regular-text {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-family: 'ProximaNova-Regular';
}

.mi-auction-semibold-text {
  font-size: 12px;
  font-family: 'ProximaNova-SemiBold';
}

.auction-bid-now-button {
  display: flex;
  height: 45px;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  font-size: 16px;
  font-family: 'ProximaNova-SemiBold';
  border-top: 1px solid #e4e4e4;
}

.action-active-color {
  color: #000;
}

.action-expired-color {
  color: #ec1b25;
}

.mi-auction-title {
  font-family: 'ProximaNova-SemiBold';
  font-size: 14px;
  width: 70%;
}

.auction-details-container {
  border: 1px solid #e4e4e4;
  background-color: #fbfbfb;
}

.mi-auction-artist {
  color: #757575;
  font-size: 12px;
  font-family: 'ProximaNova-Regular';
}

.mi-auction-artist-value {
  color: #4472c4;
}

.mi-auction-starting-price {
  font-size: 14px;
  font-family: 'ProximaNova-Regular';
  color: #000000;
}

.mi-auction-starting-price-value {
  font-family: 'ProximaNova-SemiBold';
}

.current-highest-bid {
  font-family: 'ProximaNova-SemiBold';
  font-size: 14px;
  line-height: 45px;
  padding-left: 10px;
}

.auction-winner {
  font-family: 'ProximaNova-SemiBold';
  font-size: 14px;
  line-height: 45px;
  padding-left: 10px;
  color: #008000;
  text-align: center;
}

.auction-details-container-top {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.auction-details-top-container-height {
  height: 45%;
}

.auction-details-top-container-height-px {
  height: 130px;
}

.highest-bidder-block {
  background-color: #e6e6e6;
  border-radius: 8px;
  height: 120px;
}

.highest-bidder-details {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #cccccc;
  height: 75px;
  padding-left: 10px;
  align-items: center;
  padding-right: 10px;
}

.highest-bidder-name {
  font-family: 'ProximaNova-SemiBold';
  font-size: 12px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.highest-bidder-location {
  color: #757575;
  font-size: 10px;
  font-family: 'ProximaNova-Regular';
}

.auction-timer-make-bid-block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.auction-make-bid-button {
  display: flex;
  background-color: #4472c4;
  height: 45px;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
}

.expired-bid-button {
  display: flex;
  background-color: #e4e4e4;
  height: 45px;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
}

.make-bid-button-title {
  color: #ffffff;
  font-family: 'ProximaNova-SemiBold';
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.starting-soon-button-title {
  color: #000;
  font-family: 'ProximaNova-SemiBold';
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.expired-button-title {
  color: #ec1b25;
  font-family: 'ProximaNova-SemiBold';
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.thankyou-ok-button-title {
  color: #ffffff;
  font-family: 'ProximaNova-SemiBold';
  font-size: 16px;
  padding-left: 35px;
  padding-right: 35px;
  text-align: center;
}

.auction-details-start-timer {
  font-family: 'ProximaNova-SemiBold';
  text-align: center;
  font-size: 18px;
}

.height-50 {
  height: 50px;
}

.auction-timer-text {
  color: #4472c4;
  font-size: 24px;
}

.mi-auction-description-text {
  font-size: 14px;
  font-family: 'ProximaNova-Regular';
  line-height: 22px;
}

.highest-bidder-bid-price {
  font-size: 12px;
  font-family: 'ProximaNova-SemiBold';
  color: #008000;
}

.mi-auction-tc-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 80px;
  align-items: center;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  background-color: #fbfbfb;
  padding-left: 10px;
  padding-right: 10px;
}

.width-55 {
  width: 55%;
}

.width-45 {
  width: 45%;
}

.timer-bidder-details {
  display: flex;
  flex-direction: row;
  height: max-content;
  align-items: center;
  justify-content: center;
}

.timer-bidder-details-height {
  height: 55%;
}

.timer-bidder-details-height-px {
  height: 160px;
}

.auction-name-category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.auction-thankyou-text {
  font-family: 'ProximaNova-SemiBold';
  font-size: 18px;
  color: #4472c4;
  margin-bottom: 10px;
}

.auction-success-message {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  color: #000;
  margin-bottom: 30px;
}

.auction-thankyou-title {
  color: #000;
  font-size: 16px;
  font-family: 'ProximaNova-SemiBold';
}

.auction-note-title {
  color: #000;
  font-size: 16px;
  font-family: 'ProximaNova-Regular';
}

.auction-terms-and-conditions {
  font-size: 18px;
  color: #4472c4;
  font-family: 'ProximaNova-SemiBold';
  padding: 20px;
  text-align: center;
}

/* --------------- FEEDBACK SCREEN --------------- */

.feedback-success-dialog {
  width: 350px;
  height: 'auto';
}

.feedback-success-title-text {
  color: #000;
  text-align: center;
  font-family: 'ProximaNova-Regular';
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.feedback-success-subtitle-text {
  color: #121212;
  text-align: center;
  font-family: 'ProximaNova-Regular';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.feedback-ok-button-container {
  width: 100px;
  height: 45px;
  border-radius: 24px;
  border-top: 1px solid #909090;
  border-bottom: 1px solid #909090;
  background: #4472c4;
  color: #fff;
  text-align: center;
  font-family: 'ProximaNova-Regular';
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
}

.feedback-title-container {
  width: 600px;
  height: auto;
  background-color: #fbfbfb;
  border-top: 1px solid #e4e4e4;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
}

.feedback-title-container-bottom-border {
  width: 600px;
  height: auto;
  background-color: #fbfbfb;
  border-top: 1px solid #e4e4e4;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
}

.feedback-title-text {
  color: #000;
  font-family: 'ProximaNova-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.feedback-subtitle-text {
  color: #000;
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.feedback-textbox-container {
  width: 600px;
  border: 1px solid #e4e4e4;
  background: #fff;
}

.feedback-text-style {
  color: #000;
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  font-weight: 400;
}

.feedback-star-text-style {
  color: #e30613;
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  font-weight: 400;
}

.feedback-submit-button-style {
  width: 210px;
  height: 45px;
  border-radius: 24px;
  border-top: 1px solid #909090;
  border-bottom: 1px solid #909090;
  background: #4472c4;
  color: #ffffff;
  text-align: center;
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.feedback-success-modal {
  width: 80%;
}

.feedback-participated-text {
  font-size: 14px;
  line-height: 12px;
  font-family: 'ProximaNova-Regular';
  color: #8aaad9;
  font-weight: 600;
  width: 600px;
}

/* ------- MiChildCare */

.michildcare-table {
  width: 100%;
  border-color: #d9d9d9;
  margin-top: 5px;
  margin-bottom: 10px;
}

.michildcare-tr {
  width: 100%;
  border-color: #d9d9d9;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: center;
}

.michildcare-bg {
  background-color: #f9f9f9;
}

.michildcare-column-titles {
  font-family: 'ProximaNova-Regular';
  color: black;
  margin-right: 5px;
  font-size: 14px !important;
  text-align: center;
  font-weight: 600;
}

.michildcare-button {
  background-color: #4472c4;
  border-radius: 5px;
  border-width: 0px;
  color: white;
  font-size: 14px;
}

.michildcare-modal-buttons {
  background-color: #4472c4;
  border-color: #4472c4;
  border: 1px solid #4472c4;
  height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  color: white;
}
.michildcare-modal-buttons1 {
  background-color: #4472c4;
  border-color: #4472c4;
  border: 1px solid #4472c4;
  height: 35px;
  width: 80px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 0px;
  color: white;
}

.michildcare-modal-buttons-disable {
  background-color: #828282;
  border-color: #828282;
  border: 1px solid black;
  height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  color: white;
}

.michildcare-modal-buttons-no {
  background-color: white;
  color: #000;
  border: 1px solid black;
  height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
}
.michildcare-modal-buttons-no1 {
  background-color: white;
  color: #000;
  border: 1px solid black;
  height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 0px;
  width: 80px;
}

.michildcare-confirmation-modal-btn-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.michildcare-modal-title {
  font-family: 'ProximaNova-Regular';
  color: black;
  font-weight: 600;
  margin-bottom: 1rem;
}

.michildcare-acknowledgement-title {
  font-family: 'ProximaNova-Regular';
  color: black;
  font-weight: 600;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
}

.michildcare-td {
  padding: 12px;
  font-size: 14px;
  font-family: 'ProximaNova-Regular';
}

.michildcare-age-container {
  display: flex;
  flex-direction: row;
}

.michildcare-age-items {
  width: 100%;
}

.michildcare-footer-btn-container {
  display: flex;
  flex-direction: row;
  right: 10px;
  justify-content: center;
}

.michildcare-yourrequests-text {
  font-weight: 600;
}

.michildcare-footer-btn-container {
  border-width: 1px;
  align-items: center;
}

.michildcare-no-data {
  border-width: 1px;
  text-align: center;
  align-self: center;
}

.michildcare-text-centre {
  text-align: center;
}

.michildcare-name {
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 40px;
}

.estore-carousel-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.estore-colloections-title {
  font-family: 'ProximaNova-SemiBold';
  font-size: 16px;
  line-height: 16px;
  color: #000;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.e-store-tabs-main {
  background-color: #f2f2f2;
  flex-direction: row;
  display: flex;
}

.border-radius-4 {
  border-radius: 4px;
}

.border-radius-8 {
  border-radius: 8px;
}

.estore-default-address-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
}

.estore-default-select-address-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
  align-items: center;
}

.estore-order-history-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
  background-color: #fff;
}

.shipment-details-main-container {
  background-color: #f2f2f2;
  padding: 10px;
  width: 75%;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-3 {
  margin-bottom: 3px;
}

.order-details-item-list {
  background-color: #fff;
  padding: 10px;
}

.order-item-image-block {
  width: 120px;
  height: auto;
  margin-right: 20px;
  border-radius: 8px;
}

.estore-add-edit-address-label {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  color: #000;
  margin-bottom: 0px;
  margin-top: 10px;
}

.estore-add-edit-address-input {
  width: 100%;
}

.estore-mark-default-container {
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.estore-addresss-button-block {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  cursor: pointer;
}

.estore-state-picker {
  border: 1px solid #ced4da;
  height: calc(1.5em + 0.75rem + 2px);
  border-radius: 0.25rem;
}

.estore-addresss-3-button-block {
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  cursor: pointer;
}

.store-right-border {
  border-right: 1px solid #dddddd;
}

.store-left-border {
  border-left: 1px solid #dddddd;
}

.store-right-border-fixed {
  border-right: 1px solid #dddddd;
}

.estore-addresss-button-item-text {
  font-size: 12px;
  color: #4472c4;
  font-family: 'ProximaNova-SemiBold';
  line-height: 16px;
}

.e-store-product-list-main {
  background-color: #f2f2f2;
  flex-direction: row;
  display: flex;
}

.estore-items-container {
  width: 60%;
  background-color: #fff;
  padding: 10px;
}

.estore-items-container-2 {
  width: 60%;
  background-color: #fff;
}

.estore-confirm-address-container {
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

.estore-address-items-container {
  width: 60%;
  background-color: #fff;
}

.estore-address-item-bottom-buttons {
  width: 60%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.estore-address-item-bottom-3-buttons {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding: 5px;
  border-bottom: 1px solid #dddddd;
}

.estore-tc-items-container {
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

.e-store-item-image-address {
  height: auto;
  width: 80px;
  border-radius: 8px;
}

.e-store-cart-item-image {
  height: auto;
  width: 140px;
  background-color: lightgray;
  border-radius: 8px;
  cursor: pointer;
}

.e-store-address-confirm-item-image {
  height: auto;
  width: 80px;
  background-color: lightgray;
  border-radius: 8px;
}

.estore-cart-item-block {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #dddddd;
}

.estore-cart-item-block-pos-0 {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
}

.estore-cart-item-title {
  font-family: 'ProximaNova-SemiBold';
  font-size: 14px;
  color: #000000;
  line-height: 18px;
}

.estore-cart-item-description {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  color: #000000;
  line-height: 18px;
}

.estore-confirm-address-item-description {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  color: #000000;
  line-height: 18px;
}

.estore-cart-item-price-text {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  color: #000000;
  line-height: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.ecart-order-details-item-title {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  color: #000000;
  line-height: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 15%;
}

.ecart-order-details-item-colon {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  color: #000000;
  line-height: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 5%;
}

.estore-cart-item-price-text-bold {
  font-family: 'ProximaNova-SemiBold';
  font-size: 14px;
  color: #000000;
  line-height: 16px;
  padding-top: 10px;
  padding-bottom: 5px;
}

.estore-bold-text {
  font-family: 'ProximaNova-SemiBold';
  font-size: 14px;
  line-height: 18px;
}

.estore-bold-text-16 {
  font-family: 'ProximaNova-SemiBold';
  font-size: 16px;
  line-height: 20px;
}

.estore-cart-item-qty {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  line-height: 18px;
  margin-right: 10px;
}

.estore-cart-select-address {
  height: 50px;
  width: 100%;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 2px solid #dddddd;
  cursor: pointer;
  align-self: center;
}

.estore-product-details-select-address {
  height: 50px;
  width: 100%;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 2px solid #dddddd;
  cursor: pointer;
}

.estore-cart-change-or-add-button {
  padding: 5px 20px;
  height: 30px;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  border: 1px solid #909090;
  cursor: pointer;
}

.estore-cart-select-or-default-button {
  padding: 5px 20px;
  height: 30px;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  border: 1px solid #909090;
  cursor: pointer;
}

.other-geo-warning-icon {
  height: 180px;
  width: 180px;
  align-self: center;
}

.delivery-estimate-header {
  border-bottom: 1px solid #dddddd;
}

.selected-shipping-address-radio-ring {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  border: 2px solid #5bb246;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.default-shipping-address-radio-ring {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  border: 2px solid #808080;
  margin-right: 15px;
  cursor: pointer;
}

.selected-shipping-address-radio-dot {
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: #5bb246;
}

.delivery-charges-applicable {
  font-size: 12px;
  font-family: 'ProximaNova-Regular';
  color: #ff0000;
}

.no-delivery-charges {
  font-size: 12px;
  font-family: 'ProximaNova-Regular';
  color: #008000;
}

.shipping-method-header {
  border-bottom: 1px solid #dddddd;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.order-item-name-arrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.estore-order-success-icon {
  height: 24px;
  width: 24px;
  margin-right: 5px;
}

.select-store-address-icon {
  height: 16px;
  width: 16px;
}

.store-cart-empty-message-block {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.store-cart-empty-message-title {
  font-family: 'ProximaNova-SemiBold';
  font-size: 16px;
  margin-bottom: 5px;
  color: #000;
}

.store-order-success-message {
  font-family: 'ProximaNova-SemiBold';
  font-size: 16px;
  margin-bottom: 5px;
  color: #00af52;
}

.store-cart-empty-message-message {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  color: grey;
}

.estore-cart-item-remove {
  font-family: 'ProximaNova-Regular';
  font-size: 22px;
  line-height: 22px;
  height: 25px;
  width: 25px;
  background-color: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.estore-cart-item-count {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  line-height: 14px;
  height: 25px;
  width: 25px;
  background-color: #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.estore-regular-text {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  line-height: 18px;
}

.estore-regular-text-12 {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  line-height: 16px;
}

.estore-regular-text-12 p {
  padding: 0px;
  margin: 0px;
}

.estore-regular-text-16 {
  font-family: 'ProximaNova-Regular';
  font-size: 16px;
  line-height: 20px;
  color: #757575;
}

.estore-regular-text-16-tc {
  font-family: 'ProximaNova-Regular';
  font-size: 16px;
  line-height: 20px;
  color: #000;
}

.shipment-details-bottom-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 100%;
  background-color: #fff;
  margin-top: 10px;
}

.shipment-details-bottom-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 35%;
  cursor: pointer;
  padding-right: 10px;
}

.shipment-screen-view-order-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30%;
  cursor: pointer;
}

.store-cancel-yes {
  background-color: #4472ca;
  width: 70px;
  height: 30px;
  color: #fff;
  border-radius: 4px;
  justify-content: center;
  display: flex;
  font-family: 'ProximaNova-SemiBold';
  font-size: 16px;
  align-items: center;
  cursor: pointer;
}

.store-continue-shopping {
  background-color: #4472ca;
  padding: 5px;
  color: #fff;
  border-radius: 4px;
  justify-content: center;
  display: flex;
  font-family: 'ProximaNova-SemiBold';
  font-size: 16px;
  align-items: center;
  cursor: pointer;
  width: 60%;
}

.store-cancel-no {
  background-color: lightgray;
  width: 70px;
  height: 30px;
  color: #fff;
  border-radius: 4px;
  justify-content: center;
  display: flex;
  font-family: 'ProximaNova-SemiBold';
  font-size: 16px;
  align-items: center;
  cursor: pointer;
}

.cancel-order-dialog-button-block {
  width: 160px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  float: right;
}

.shipment-details-bottom-item-arrow {
  height: 15px;
  width: 10px;
  margin-left: 15px;
}

.estore-cart-item-delete-icon {
  height: 16px;
  width: 16px;
  margin-left: 10px;
  cursor: pointer;
}

.estore-confirm-address-button {
  height: 75px;
  width: 60%;
  background-color: #fff;
  margin-top: 5px;
  text-align: center;
  border: 1px solid #e4e4e4;
  display: flex;
  justify-content: center;
}

.estore-cart-place-order-button {
  align-self: center;
  align-items: center;
}

.estore-tc-place-order-button {
  height: 75px;
  border: 1px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
  background-color: #fff;
}

.estore-all-products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 10px;
}

.product-details-screen-images {
  width: 50%;
  padding-right: 10px;
}

.estore-produt-details-and-images {
  display: flex;
  flex-direction: row;
}

.product-details-screen-details {
  width: 50%;
}

.estore-cart-place-order-button-container {
  height: 100px;
  width: 60%;
  background-color: #fff;
  margin-top: 5px;
  text-align: center;
  border: 1px solid #e4e4e4;
}

.estore-cart-price-details-block {
  background-color: #fff;
  width: 60%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.estore-shipping-details-container {
  width: 100%;
  background-color: #fff;
}

.table-right-border {
  border-right: 1px solid #ddd;
}

.estore-shipping-details-block {
  width: 50%;
  vertical-align: top;
}

.store-top-border {
  border-top: 1px solid #dddddd;
}

.estore-confirm-address-note-block {
  background-color: #fff;
  width: 60%;
  margin-bottom: 5px;
}

@media (max-width: 991px) {
  .product-details-screen-images {
    width: 100%;
    padding-right: 0px;
  }

  .estore-produt-details-and-images {
    display: flex;
    flex-direction: column;
  }

  .product-details-screen-details {
    width: 100%;
    margin-top: 10px;
  }

  .estore-items-container {
    width: 100%;
  }

  .estore-items-container-2 {
    width: 100%;
  }

  .estore-confirm-address-button {
    width: 100%;
  }

  .estore-cart-price-details-block {
    width: 100%;
  }

  .estore-cart-place-order-button-container {
    width: 100%;
  }

  .estore-shipping-details-container {
    display: flex;
    flex-direction: column;
  }

  .estore-shipping-details-block {
    width: 100%;
  }

  .store-right-border {
    border-right: 0px solid #dddddd;
  }

  .shipment-details-bottom-container {
    flex-direction: column;
  }

  .shipment-details-bottom-item {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-right: 0px;
    height: 30px;
  }

  .shipment-screen-view-order-details {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: 30px;
  }

  .estore-address-items-container {
    width: 100%;
  }

  .estore-address-item-bottom-buttons {
    width: 100%;
  }

  .estore-confirm-address-note-block {
    width: 100%;
  }
}

.estore-product-grid-item {
  width: 200px;
  cursor: pointer;
  position: relative;
  border: 1px solid #ddd;
  background-color: #fff;
}

.estore-selected-items-strip {
  background-color: #ffffa3;
  font-size: 12px;
  color: #000;
  text-align: center;
}

.ecart-prices-list-container {
  padding-bottom: 5px;
}

.e-store-cart-block {
  background-color: #f2f2f2;
  padding: 8px;
}

.order-tc-content-container {
  width: 560px;
}

.order-tc-container {
  padding-top: 20px;
  justify-content: center;
  display: flex;
  width: 100%;
}

.e-store-tabs-main-account {
  flex-direction: row;
  display: flex;
}

.estore-collection-container {
  padding: 10px 0px 10px 10px;
  border-left: 1px solid #e4e4e4;
  width: 285px;
  background-color: #fff;
}

.estore-collection-container-accounts {
  padding: 10px 0px 5px 10px;
  border-left: 1px solid #fff;
  margin-left: 5px;
  width: 280px;
  background-color: #fff;
}

.estore-collection-item-image {
  height: auto;
  width: 100%;
}

.estore-collection-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  width: 280px;
  margin-bottom: 4px;
  background-color: #ffffff;
}

.estore-all-products-collection-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 270px;
  background-color: #ffffff;
}

.estore-all-products-collection-item-main {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
}

.estore-collection-item-title {
  font-family: 'ProximaNova-Regular';
  font-size: 24px;
  line-height: 29px;
  color: #000;
  text-align: end;
}

.estore-accounts-tab-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 10px;
  padding-top: 10px;
  cursor: pointer;
}

.estore-accounts-tab-icon-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.estore-account-item-icon {
  width: 24px;
  height: 24px;
}

.estore-account-item-title {
  font-family: 'ProximaNova-Regular';
  font-size: 16px;
  line-height: 19px;
  color: #000;
}

.estore-account-item-description {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  line-height: 14px;
  color: #000;
}

.e-store-tab {
  width: 73%;
}

.e-store-tab-account {
  width: 73%;
  background-color: #fff;
}

.estore-account-link-item {
  font-size: 14px;
  font-family: 'ProximaNova-Regular';
  line-height: 17px;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 10px;
  width: fit-content;
  color: #4472c4;
}

.estore-account-links-block {
  margin-top: 10px;
  margin-left: 10px;
}

.estore-home-item-image {
  width: 100%;
}

.estore-home-tab-products-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 8px;
  padding: 6px 0px;
}

.estore-products-block {
  padding: 0px 0px 5px 5px;
}

.estore-home-tab-featured-products {
  padding: 10px;
  background-color: #fff;
}

.estore-product-type-title {
  font-family: 'ProximaNova-SemiBold';
  font-size: 16px;
  line-height: 16px;
  color: #000;
}

.view-all-underline {
  text-decoration: underline;
  cursor: pointer;
}

.home-tab-product-item {
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
  border: 1px solid #ddd;
}

.home-tab-product-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 0.75fr));
  grid-gap: 10px;
}

.home-tab-product-list-widget {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-gap: 10px;
}

.estore-out-of-stock-font {
  font-size: 12px;
  font-family: 'ProximaNova-Regular';
  color: #ec1b25;
}

.estore-price-out-of-stock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.estore-home-item-title-price {
  background-color: #00000066;
  width: 100%;
  padding: 3px 5px 3px 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.estore-home-item-title-price-list {
  background-color: #fff;
  width: 100%;
  padding: 3px 5px 3px 5px;
  position: relative;
  color: #000;
  font-family: 'ProximaNova-SemiBold';
}

.estore-product-name {
  font-family: 'ProximaNova-Regular';
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.estore-product-description {
  font-family: 'ProximaNova-Regular';
  color: #fff;
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 4px;
}

.estore-product-name-list {
  font-family: 'ProximaNova-SemiBold';
  color: #000;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.estore-product-short-description {
  font-family: 'ProximaNova-Regular';
  color: #fff;
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.estore-product-details-block {
  background-color: #fff;
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
}

.estore-order-detail-block {
  background-color: #fff;
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.ecart-price-details-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ecart-price-details-item-total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ddd;
  margin-top: 5px;
}

.ecart-order-details-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.estore-cart-address-note-block {
  background-color: #fff;
  width: 100%;
  margin-bottom: 5px;
}

.estore-cart-address-block {
  width: 100%;
  /* padding: 10px; */
  margin-bottom: 5px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.estore-size-item-inactive {
  border: 1px solid #4472c4;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 2px;
  font-family: 'ProximaNova-SemiBold';
  font-size: 12px;
  cursor: pointer;
  color: #4472c4;
  background-color: #fff;
}

.estore-size-item-active {
  border: 1px solid #4472c4;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 2px;
  font-family: 'ProximaNova-SemiBold';
  font-size: 12px;
  cursor: pointer;
  color: #fff;
  background-color: #4472c4;
}

.estore-size-item-disabled {
  border: 1px solid #eebfc2;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 2px;
  font-family: 'ProximaNova-SemiBold';
  font-size: 12px;
  cursor: not-allowed;
  color: #eebfc2;
  background-color: #fff;
}

.estore-color-item-inactive {
  border: 1px solid #4472c4;
  height: 32px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 2px;
  font-family: 'ProximaNova-SemiBold';
  font-size: 12px;
  cursor: pointer;
  color: #4472c4;
  background-color: #fff;
}

.estore-color-item-active {
  border: 1px solid #4472c4;
  height: 32px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 2px;
  font-family: 'ProximaNova-SemiBold';
  font-size: 12px;
  cursor: pointer;
  color: #fff;
  background-color: #4472c4;
}

.estore-color-item-disabled {
  border: 1px solid #eebfc2;
  height: 32px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 2px;
  font-family: 'ProximaNova-SemiBold';
  font-size: 12px;
  cursor: not-allowed;
  color: #eebfc2;
  background-color: #fff;
}

.estore-title-price-font {
  font-size: 16px;
  color: #000;
  line-height: 16px;
  font-family: 'ProximaNova-SemiBold';
}

.estore-size-color-font {
  font-size: 14px;
  color: #000;
  line-height: 14px;
  font-family: 'ProximaNova-SemiBold';
}

.estore-size-color-chart-font {
  font-size: 14px;
  color: #ec1b25;
  line-height: 14px;
  font-family: 'ProximaNova-SemiBold';
  cursor: pointer;
}

.e-store-colors-block {
  display: flex;
  flex-direction: row;
  padding-left: 14px;
}

.estore-size-color-title-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.estore-order-success-address-block {
  margin-top: 5px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 5px;
}

.estore-order-success-address-block-billing {
  margin-top: 5px;
  padding-bottom: 5px;
}

.estore-order-success-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
}

.estore-shipping-details-header {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.estore-shipping-details {
  padding: 10px;
}

.estore-product-detail-address-block {
  border: 1px solid #dddddd;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-top: 10px;
}

.estore-product-detail-address-text {
  font-family: 'ProximaNova-SemiBold';
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.estore-add-to-cart-button {
  background-color: #4472c4;
  border-radius: 4px;
  height: 36px;
  width: 100%;
  border: 1px solid #4472c4;
  font-family: 'ProximaNova-SemiBold';
}

.estore-tc-place-ordeer-button {
  background-color: #4472c4;
  border-radius: 4px;
  height: 36px;
  width: 350px;
  border: 1px solid #4472c4;
  font-family: 'ProximaNova-SemiBold';
}

.estore-product-details-container {
  background-color: #fff;
}

.order-details-item {
  display: flex;
  flex-direction: row;
}

.seat-booking-divider {
  background-color: #e4e4e4;
  height: 1px;
}

.sb-disabled-opacity {
  opacity: 0.5;
}

.sb-disabled-select {
  opacity: 0.5;
  background-color: #f5f5f5;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  height: 38px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: #a0a0a0;
  font-size: 14px;
}

.booking-home-subtitle {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  color: #00000080;
}

.seat-booking-home-arrow-style {
  height: 10px;
  width: 10px;
}

.seat-booking-book-tab-arrow-style {
  height: 15px;
  width: 15px;
}

.seat-quick-booking-card-container {
  border: 1px solid #0000001a;
  border-radius: 6px;
  width: 100%;
  overflow-wrap: break-word;
}

.seat-booking-carousel-border {
  border-radius: 4px;
}

.seat-booking-carousel .carousel-inner {
  border-radius: 4px;
  overflow: hidden;
}

.seat-carousel-image {
  height: 255px;
  width: 100%;
}

.seat-booking-colored-container {
  background-color: #f2f2f2;
}

.seat-booking-white-background {
  background-color: white;
  border-radius: 2px;
}

.seat-booking-container-height {
  height: 255px;
}

.seat-booking-container-width {
  width: 410px;
}

.vp-home-con-wid {
  width: 380px;
}

.seat-booking-up-quick-container-width {
  width: 400px;
}

.seat-booking-view-on-map-text {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  color: #4472c4;
  text-decoration: underline;
  cursor: pointer;
}

.seat-layout-container {
  position: relative;
  width: 100%;
  height: auto;
}

.layout-image {
  width: 100%;
  height: auto;
}

.seat-booking-quick-emp-container-height {
  height: 200px;
}

.seat-booking-preview-container-width {
  width: 40%;
  max-width: 360px;
}

.seat-booking-gallery-carousel-border {
  border-radius: 6px;
}

.seat-booking-gallery-carousel .carousel-inner {
  border-radius: 6px;
  overflow: hidden;
}

.seat-booking-gallery-carousel-width {
  width: 700px;
  max-height: 200px;
}

.seat-booking-gallery-width {
  width: 100%;
  max-width: 785px;
}

.seat-provision-container-width {
  width: 50%;
  overflow-y: auto;
  max-height: 232px;
}

.seat-booking-pro-container {
  width: 100%;
  min-width: 700px;
}

.seat-booking-form-background {
  background-color: #e4efff;
}

.quick-booking-btn {
  background-color: #4472c4;
  border-radius: 4px;
}

.quick-booking-btn-text {
  font-family: 'ProximaNova-Bold';
  font-size: 12px;
  color: #ffffff;
}

.seat-booking-office-location-icon {
  height: 32px;
  width: 32px;
}

.seat-booking-right-section-container {
  width: 25%;
}

.seat-booking-navigate-text {
  font-family: 'ProximaNova-SemiBold';
  font-size: 12px;
  color: #4472c4;
  text-decoration: underline;
}

.seat_booking_preview_width {
  width: 30%;
}

.seat-booking-provision-icon {
  height: 42px;
  width: 42px;
}

.seat-booking-preview-title-text {
  font-family: 'ProximaNova-SemiBold';
  font-size: 14px;
}

.seat-booking-provision-description {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  color: #00000080;
}

.seat-preview-bottom-con {
  width: 360px;
  height: auto;
}

.map-view-leg-con {
  width: 100%;
  background-color: #e4efff;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  bottom: 60px;
}

.map-view-selected {
  height: 14px;
  width: 14px;
  background-color: #0caa41;
  border-radius: 2px;
  margin-right: 10px;
}

.map-view-available {
  height: 14px;
  width: 14px;
  border-radius: 2px;
  border: 1px solid #0caa41;
  margin-right: 10px;
  background-color: 'white';
}
.map-view-unavailable {
  height: 14px;
  width: 14px;
  background-color: #757575;
  border-radius: 2px;
  margin-right: 10px;
}

.selected-desk-container {
  margin-left: auto;
  background-color: rgba(12, 170, 65, 0.15);
  color: #0caa41;
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 8px;
}

.selected-desk-icon {
  width: 20px;
  height: 20px;
  background-color: #0caa41;
  border-radius: 50%;
}

.vehicle-parking-carousel-container {
  width: 500px;
  height: 236px;
}

.vehicle-parking-right-container-width {
  width: 35%;
  height: 80%;
  max-width: 332px;
}

.helpful-tips-container {
  max-height: 236px;
}

.vehicle-parking-component-height {
  min-height: 236px;
}

.vehicle-preview-left-con-width {
  width: 360px;
}

.vehicle-preview-gallery-image {
  max-height: 213px;
  width: 100%;
}

.vehicle-preview-gallery-con {
  max-height: 230px;
  width: auto;
}

.vehicle-preview-bottom-con {
  width: 360px;
  height: 100%;
}

.vehicle-prov-con-height {
  height: 58.5%;
}

.quick-book-title-container {
  max-width: 150px;
  text-wrap: wrap;
}

.park-qb-icon {
  height: 35px;
  width: 35px;
}

.vph-arrow {
  font-size: 20px;
  color: #89a8d9;
}

.base-10px-font {
  font-family: 'ProximaNova-Regular';
  font-size: 10px;
}

.base-10px-semibold {
  font-family: 'ProximaNova-SemiBold';
  font-size: 10px;
  line-height: 18px;
}

.base-10px-font-bold {
  font-family: 'ProximaNova-Bold';
  font-size: 10px;
  line-height: 18px;
}

.base-12px-font {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
}

.base-12px-font-semi-bold {
  font-family: 'ProximaNova-SemiBold';
  font-size: 12px;
}

.base-12px-font-bold {
  font-family: 'ProximaNova-Bold';
  font-size: 12px;
  line-height: 16px;
}

.base-14px-font {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
}

.base-14px-font-bold {
  font-family: 'ProximaNova-Bold';
  font-size: 14px;
}

.base-14px-font-semi-bold {
  font-family: 'ProximaNova-SemiBold';
  font-size: 14px;
  line-height: 20px;
}

.base-14px-font-underlined {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  text-decoration: underline;
  color: #4472c4;
}

.base-18px-font-bold {
  font-family: 'ProximaNova-Bold';
  font-size: 18px;
}

.base-16px-font-semi-bold {
  font-family: 'ProximaNova-SemiBold';
  font-size: 16px;
}

.base-16px-font {
  font-family: 'ProximaNova-regular';
  font-size: 16px;
}

.seat-booking-primary-container {
  width: 30%;
}

.base-modal-no-button {
  width: 125px;
  height: 38px;
  border: 1px solid #909090;
}

.base-modal-yes-button {
  width: 125px;
  height: 38px;
  background-color: #4472c4;
  color: white;
}

.booking-card-container {
  border: 1px solid #dddddd;
  height: auto;
  border-radius: 8px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.toggle-button-container {
  height: 32px;
  width: 240px;
  border-width: 1px;
  border-color: #e5e5e5;
  border-radius: 50px;
  border-style: solid;
  display: flex;
  align-items: center;
}

.toggle-selected {
  background-color: #4472c4;
  border-radius: 50px;
  height: 32px;
}

.toggle-selected-text {
  font-family: 'ProximaNovaCond-SemiBold';
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-align: center;
  color: white;
}

.toggle-unselected-text {
  font-family: 'ProximaNovaCond-SemiBold';
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-align: center;
}

.seat-booking-divider {
  background-color: #e4e4e4;
  height: 1px;
}

.base-12px-font-semi-bold {
  font-family: 'ProximaNova-SemiBold';
  font-size: 12px;
}

.base-14px-font {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
}

.base-14px-font-bold {
  font-family: 'ProximaNova-Bold';
  font-size: 14px;
}

.base-14px-font-underlined {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  text-decoration: underline;
}

.base-18px-font-bold {
  font-family: 'ProximaNova-Bold';
  font-size: 18px;
}

.base-16px-font-semi-bold {
  font-family: 'ProximaNova-SemiBold';
  font-size: 16px;
}

.seat-booking-primary-container {
  width: 30%;
}

.seat-form-max-width {
  width: 100%;
  max-width: 332px;
}

.base-modal-no-button {
  width: 125px;
  height: 38px;
  border: 1px solid #909090;
}

.base-modal-yes-button {
  width: 125px;
  height: 38px;
  background-color: #4472c4;
  color: white;
}

.booking-card-container {
  border: 1px solid #dddddd;
  height: auto;
  border-radius: 8px;
}

.status-indicator-container {
  border: 1px solid;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-point-pending {
  color: #ff9500;
}

.status-text-pending {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  color: #ff9500;
}

.status-point-approved {
  color: #14ae5c;
}

.status-text-approved {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  color: #14ae5c;
}

.status-point-expired {
  color: #595959;
}

.status-text-expired {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  color: #595959;
}

.status-point-rejected {
  color: #ec1b25;
}

.status-text-rejected {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  color: #ec1b25;
}

.upcoming-booking-timing-text {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  font-weight: 400;
  line-height: 14.62px;
  text-align: left;
  color: #4472c4;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.toggle-button-container {
  height: 32px;
  width: 240px;
  border-width: 1px;
  border-color: #e5e5e5;
  border-radius: 50px;
  border-style: solid;
  display: flex;
  align-items: center;
}

.toggle-selected {
  background-color: #4472c4;
  border-radius: 50px;
  height: 32px;
}

.toggle-selected-text {
  font-family: 'ProximaNovaCond-SemiBold';
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-align: center;
  color: white;
}

.toggle-unselected-text {
  font-family: 'ProximaNovaCond-SemiBold';
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-align: center;
}

.vehicle-parking-left-container {
  width: 75%;
}

.delivery-note-container {
  background-color: #ffffa3;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}

.delivery-note-icon {
  height: 16px;
  width: 18px;
  margin-right: 5px;
}

.delivery-note-text {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  line-height: 18px;
  color: '#000';
  width: '90%';
}

.common-custom-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.common-custom-modal-container {
  background-color: #fff;
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  border-radius: 8px;
}

.ceo-chat-public-private-modal {
  width: 400px;
}

.common-custom-modal-title {
  font-family: 'ProximaNova-SemiBold';
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.common-custom-modal-description {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.common-custom-modal-buttons-block {
  display: flex;
  flex-direction: row;
  width: 65%;
  justify-content: space-evenly;
  align-items: center;
  align-self: center;
}

.ceo-chat-modal-buttons-block {
  display: flex;
  flex-direction: row;
  width: 85%;
  justify-content: space-evenly;
  align-items: center;
  align-self: center;
}

.common-custom-modal-secondary-button {
  cursor: pointer;
  min-width: 125px;
  border: 1px solid #909090;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border-radius: 4px;
}

.common-custom-modal-secondary-button2 {
  cursor: pointer;
  min-width: 85px;
  border: 1px solid #4472c4;
  text-align: center;
  border-radius: 4px;
}

.common-custom-modal-primary-button {
  cursor: pointer;
  min-width: 125px;
  background-color: #4472c4;
  border: 1px solid #4472c4;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border-radius: 4px;
}

.common-custom-modal-primary-button2 {
  cursor: pointer;
  min-width: 85px;
  background-color: #4472c4;
  border: 1px solid #4472c4;
  text-align: center;
  border-radius: 4px;
}

.common-custom-modal-secondary-button-title {
  font-family: 'ProximaNova-SemiBold';
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.common-custom-modal-primary-button-title {
  font-family: 'ProximaNova-SemiBold';
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}

.attendance-modal-close-icon {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 18px;
  margin-bottom: 5px;
  margin-right: 18px;
}

.attendance-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  align-items: center;
}

.attendance-modal-left-container {
  width: 50%;
  padding: 8px 0px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.attendance-modal-right-container {
  width: 50%;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid #ddd;
}

/* .childCareYearContrainer {
  width: 100%;
  border-bottom: 1px solid #ddd;
} */
.childCareYearContainer {
  display: flex;
  align-items: center;
  /* Vertically center the items */
  justify-content: space-between;
  /* Adjust spacing as needed */
}

.childCareYearContainer label {
  margin-right: 13px;
  /* Space between labels */
}

.attendance-green-dot {
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background-color: #5bb246;
}

.attendance-red-dot {
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background-color: #ec1b25;
}

.attendance-white-ring {
  height: 12px;
  width: 12px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: white;
}

.attendance-green-ring {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: #5bb246;
  align-items: center;
  justify-content: center;
  display: flex;
}

.attendance-red-ring {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: #ec1b25;
  align-items: center;
  justify-content: center;
  display: flex;
}

.attendance-last-seen-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.attendance-warning-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
}

.attendance-modal-title {
  font-size: 20px;
  font-family: 'ProximaNova-SemiBold';
  text-align: center;
}

.attendance-modal-title-close-icon {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}

.attendance-close-icon {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.close-icon-business-card-qr {
  height: 26px;
  width: 25px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1000;
}

.business-card-qr-container {
  min-height: 460px;
}

.bc-text-modal-container {
  padding: 15px;
}

.bc-text-close-icon-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.share-business-card-title {
  font-size: 16px;
  font-family: 'ProximaNova-SemiBold';
  color: #000;
  line-height: 20px;
}

.bc-text-modal-text-container {
  width: 80%;
  padding-right: 15%;
}

.bc-text-modal-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.custom-video-close-icon {
  background-color: #000;
  border-radius: 3em;
  padding: 10px;
  opacity: 0.6;
}

.bc-text-modal-close {
  height: 18px;
  width: 18px;
  cursor: pointer;
  align-self: flex-end;
}

.business-card-menu-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100px;
}

.business-card-menu-item:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.attendance-title {
  font-size: 14px;
  font-family: 'ProximaNova-SemiBold';
}

.attendance-date-subtext {
  font-size: 12px;
  font-family: 'ProximaNova-SemiBold';
}

.attendance-last-update-status {
  font-size: 11px;
  font-family: 'ProximaNova-Regular';
}

.attendance-regularise-node {
  font-size: 14px;
  font-family: 'ProximaNova-Regular';
  margin: 15px 0px 15px 0px;
}

.attendance-regularise-button {
  background-color: #4472c4;
  border-radius: 4px;
  height: 36px;
  width: 100%;
  border: 1px solid #4472c4;
  font-family: 'ProximaNova-SemiBold';
}

.attendance-signin-signout-button {
  background-color: #4472c4;
  border-radius: 4px;
  height: 36px;
  border: 1px solid #4472c4;
  font-family: 'ProximaNova-SemiBold';
  font-size: 14px;
}

.attendance-signin-custom-checkbox {
  height: 20px;
  width: 20px;
  margin: 0;
}

.noteFont {
  font-family: 'ProximaNovaCond-Bold';
}

.attendance-signin-custom-checkbox {
  display: none;
}

.auto-login-text {
  font-size: 14px;
  font-family: 'ProximaNova-Regular';
  margin: 5px 0px;
  padding-left: 5px;
}

.checkbox-custom {
  position: relative;
  display: flex;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  vertical-align: middle;
  padding-right: 10px;
  transition: background-color 0.3s, border-color 0.3s;
}

.attendance-signin-custom-checkbox:checked + .checkbox-custom {
  background-color: #5bb246;
  border-color: #5bb246;
}

.checkbox-custom::after {
  content: '';
  position: absolute;
  left: 4px;
  top: 1px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.3s;
}

.attendance-signin-custom-checkbox:checked + .checkbox-custom::after {
  opacity: 1;
}

.geo-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.geo-button {
  width: 25%;
  padding: 0;
  border: none;
  background: none;
}

.geo-button-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
}

.selected-geo-button {
  background-color: rgba(227, 6, 19, 0.5);
}

.geo-button-text {
  color: #fff;
  font-family: 'ProximaNova-Regular';
  font-size: 31px;
  font-weight: 700;
  line-height: 37.76px;
  text-align: center;
}

.anniversary-celebration-video-container {
  margin: 5% 10%;
  margin-bottom: 40px;
}

.anniversary-celebration-video-inner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.anniversary-celebration-play-button {
  position: absolute;
  z-index: 1000;
  cursor: pointer;
}

.anniversary-celebration-play-button-img {
  height: 50px;
  width: 50px;
}

.thumbnail-container {
  position: relative;
  width: 100%;
  height: 600px;
}

.thumbnail-container-content {
  position: relative;
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mltv-play-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mltv-play-button-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mltv-play-button-img {
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 1000;
}

.mltv-play-button-img-content {
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 1000;
}

.anniversary-celebration-video-thumbnail {
  width: 100%;
  height: 480px;
  margin-bottom: 10px;
  border-radius: 8px;
  aspect-ratio: 16/9;
}

.ml-35-video-component {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ml-35-video-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ml-35-video-link {
  position: absolute;
  z-index: 1000;
}

.ml-35-play-video-icon {
  height: 50px;
  width: 50px;
}

.ml-35-video-thumbnail {
  height: 56.25vw;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
}

.ml-35-video-title {
  color: #000;
  font-size: 16px;
  line-height: 20px;
  font-family: 'ProximaNovaCond-SemiBold';
}

.ml-35-gallery-image-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 15px;
  padding: 20px;
}

/* Portrait images */
.vertical {
  /* grid-column: span 1; */
  /* grid-row: span 2; */
  object-fit: cover;
  width: 100%;
  height: auto;
}

/* Landscape images */
.horizontal {
  /* grid-column: span 2; */
  /* grid-row: span 2; */
  object-fit: cover;
  width: 100%;
  height: auto;
}

/* Square images */
.square {
  grid-column: span 1;
  grid-row: span 1;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.celebration-tab-button-style {
  color: #89a8d9;
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  font-weight: 600;
  line-height: 17.05px;
  text-align: center;
  padding: 10px 20px;
}

.vertical-divider-celebrations {
  left: 50%;
  top: 10%;
  bottom: 40%;
  border-left: 1px solid #89a8d9;
}

.ml-35-html-text {
  font-family: 'ProximaNova-Regular';
  font-size: 20px;
}

.ml-35-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.ml-35-fullscreen-image {
  max-width: 80%;
  max-height: 80%;
  border-radius: 8px;
  object-fit: contain;
  margin-bottom: 20px;
}

.ml-35-image-close-button {
  position: absolute;
  top: 30px;
  right: 180px;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.ml-35-image-download-icon {
  height: 30px;
  width: 30px;
}

.ml-35-dashboard-item {
  flex: 0 0 24%;
  box-sizing: border-box;
  padding-left: 10px;
}

.ml-35-images-wrapper {
  line-height: 0;
  -webkit-column-count: 3;
  -webkit-column-gap: 0px;
  -moz-column-count: 3;
  -moz-column-gap: 0px;
  column-count: 3;
  column-gap: 5px;
}

.ml-35-images-wrapper img {
  width: 100% !important;
  height: auto !important;
  padding: 0px 0px 5px 0px;
  border-radius: 16px;
}

.ml-35-images-wrapper {
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 1000px) {
  .ml-35-images-wrapper {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

@media (max-width: 800px) {
  .ml-35-images-wrapper {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media (max-width: 400px) {
  .ml-35-images-wrapper {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}

.ml-35-more-photos-bg-view {
  position: absolute;
  bottom: 5px;
  right: 0px;
  background-color: red;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  padding: 20px;
}

.mltvcontainer {
  display: flex;
  gap: 20px;
}

.mltvtext-item {
  color: lightblue;
  cursor: pointer;
  transition: color 0.3s;
}

.mltvtext-item.clicked {
  color: darkblue;
}

.neo-induction-card {
  border: 1px solid #dddddd;
  background-color: #fff;
  border-radius: 8px;
}

.neo-induction-title-text {
  font-family: 'ProximaNova-Regular';
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
}

.neo-induction-subtitle {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  font-weight: 600;
}

.neo-induction-text {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
}

.neo-induction-vertical-divider {
  left: 50%;
  top: 10%;
  bottom: 40%;
  border-left: 1px solid #dddddd;
  margin-left: 2%;
  margin-right: 2%;
}

.neo-session-detail-image {
  width: 425px;
  height: 245px;
  border-radius: 8px;
  margin-right: 20px;
}

.neo-detail-title-text {
  font-family: 'ProximaNova-Bold';
  font-size: 24px;
  line-height: 28px;
}

.neo-detail-sub-title-text {
  font-family: 'ProximaNova-Regular';
  font-size: 20px;
  line-height: 22px;
}

.neo-detail-semi-bold {
  font-family: 'ProximaNova-SemiBold';
  font-size: 14px;
}

.neo-detail-text {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
}

.neo-detail-confirm-text {
  font-family: 'ProximaNova-Regular';
  font-size: 16px;
}

.neo-detail-yes-btn {
  width: 100px;
  height: 40px;
  background-color: #4472c4;
  color: white;
  font-family: 'ProximaNova-Bold';
}

.neo-detail-no-btn {
  width: 100px;
  height: 40px;
  border: 1px solid #909090;
  font-family: 'ProximaNova-Bold';
}

.neo-detail-session-card {
  border: 1px solid #dddddd;
  border-radius: 8px;
}

.neo-detail-session-title {
  font-family: 'ProximaNova-SemiBold';
  font-size: 16px;
  line-height: 16px;
}

.neo-detail-break-card {
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  background-color: #f3f3f3;
}

.neo-rsvp-text {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
}

.neo-rsvp-confirm-text {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  color: #008000;
}

.neo-rsvp-reject-text {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  color: #ff0000;
}

.neo-document-label {
  font-family: 'ProximaNova-Regular';
  font-size: 16px;
  color: #7d9ecf;
  line-height: 20px;
  text-decoration: underline;
}

.neo-attended-text {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  line-height: 14px;
  color: #008000;
}

.neo-not-attended-text {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  line-height: 14px;
  color: #ff0000;
}

.neo-dashboard-title {
  font-family: 'ProximaNova-SemiBold';
  font-size: 18px;
  line-height: 22px;
}

.neo-db-sub-title {
  font-family: 'ProximaNova-SemiBold';
  font-size: 14px;
}

.neo-db-text {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
}

.neo-vertical-divider {
  border-left: 1px solid #e8e8e8;
}

.celebration-item-container-padding {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.celebration-item-title-text {
  color: #000;
  font-family: 'ProximaNovaCond-SemiBold';
  font-size: 20px;
}

.celebration-item-border {
  border-bottom: 1px solid #e30613;
  width: 100%;
  margin-bottom: 5px;
}

.bottom-padding {
  padding-bottom: 10px;
}

.celebration-item-subtitle {
  color: #4472c4;
  font-size: 14px;
  font-family: 'ProximaNovaCond-SemiBold';
  margin-bottom: 10px;
}

/* Meeting Room */

.meeting-container-width {
  width: 33%;
}

.meeting-carousel-container {
  width: 500px;
  height: 236px;
}

.meeting-component-height {
  height: 236px;
}
.mi-meeting-db-facility-image {
  width: 350px;
  height: 172px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.mi-meeting-border-radius {
  border-radius: 8px;
}

.mi-meeting-facility-title {
  font-family: 'ProximaNova-SemiBold';
  font-size: 12px;
  line-height: 20px;
}

.mi-meeting-facility-subtitle {
  font-family: 'ProximaNova-Regular';
  font-size: 10px;
  line-height: 18px;
}

.mi-meet-check-fac-text {
  font-family: 'ProximaNova-SemiBold';
  font-size: 10px;
  line-height: 18px;
  color: white;
}

.mi-meet-check-fac-container {
  background-color: #4472c4;
  border-radius: 4px;
  max-height: 24px;
}

.mi-meet-check-availability-con {
  background-color: #4472c4;
  border-radius: 4px;
  font-family: 'ProximaNova-SemiBold';
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: white;
}

.room-res-date-container {
  width: 172px;
}

.mi-meet-right-arrow {
  width: 10px;
  height: 10px;
}

.mi-meet-grid-con {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
}

.mi-meet-rs-item-con {
  width: 273px;
  height: 184px;
}

.mi-meet-rs-max-con {
  background-color: #e4e4e4;
}

.mi-meet-max-limit-text {
  color: #4472c4;
  font-family: 'ProximaNova-SemiBold';
  font-size: 10px;
}

.mi-meet-provision-icon {
  height: 28px;
  width: 28px;
}

.mi-meet-prov-remaining-text {
  color: #4472c4;
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
}

.mi-meeting-rs-border-radius {
  border-radius: 4px;
}

.mi-meeting-rs-border-top-radius {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.mi-meeting-rs-border-bottom-radius {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.meet-prev-title {
  font-family: 'ProximaNova-SemiBold';
  font-size: 24px;
  line-height: 28px;
  flex-wrap: wrap;
}

.meet-prev-subtitle {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  line-height: 16px;
}

.meet-prev-cap-text {
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  line-height: 14px;
}

.meet-prev-vertical-divider {
  border-left: 1px solid #0000001a;
}

.meet-prev-prov-icon {
  height: 40px;
  width: 40px;
}

.meet-prov-con {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.meet-prev-form-icon {
  width: 15px;
  height: 15px;
}

.meet-prev-form-label {
  color: #757575;
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  line-height: 16px;
}

.meet-prev-left-con {
  max-width: 300px;
}

.meet-prev-dec-con {
  min-height: 212px;
}

.meet-prev-ti-con {
  min-height: 150px;
}

.ql-container {
  min-height: 150px !important;
}

.ql-container {
  border-radius: 0 0 5px 5px !important;
  border: none !important;
  border-top: none !important;
}

.ql-editor {
  padding-left: 8px !important;
}

.ql-toolbar.ql-snow {
  border-radius: 5px 5px 0 0 !important;
  border: none !important;
  padding: 0px !important;
}

.meet-prev-borderless-input {
  border: none;
  border-radius: 0;
  box-shadow: none;
  background-color: #f9f9f9;
  color: #757575;
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  line-height: 16px;
}

.meet-prev-borderless-input:focus {
  outline: none;
}

.meet-prev-bm {
  border-bottom: 2px solid #89a8d9;
}

.meet-prev-icon-mt {
  margin-top: 2px;
}

.meet-prev-dropdown-panel {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  max-height: 190px;
  overflow-y: auto;
}

.meet-prev-dropdown-item {
  cursor: pointer;
  transition: background-color 0.2s;
  z-index: 9999;
}

.meet-prev-dropdown-item:hover {
  background-color: #89a8d9;
}

.meet-prev-prof-icon {
  height: 30px;
  width: 30px;
  border-radius: 30px;
}

.meet-prev-con-wid {
  min-width: 453px;
}

.meet-prev-con-wid2 {
  min-width: 710px;
}

.meet-prev-org-name-text {
  color: #757575;
}

.meet-prev-date-txt {
  color: #595959;
}

.meet-det-agenda-txt {
  color: #595959;
  white-space: pre-line;
}

.meet-prev-dt-icon {
  height: 18px;
  width: 18px;
}

.meet-prev-colored-bg {
  background-color: #f9f9f9;
}

.meet-prev-provision-container {
  position: relative;
}

.meet-prev-tooltip {
  visibility: hidden;
  width: 150px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.meet-prev-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.meet-prev-provision-container:hover .meet-prev-tooltip {
  visibility: visible;
  opacity: 1;
}

.mi-meet-arrow-txt {
  font-family: 'ProximaNova-Regular';
  font-size: 16px;
  color: white;
}

.mi-meet-gallery-container {
  height: 287px;
  width: 500px;
}

.mi-meet-det-container {
  min-width: 50% !important;
}

.meet-detail-emp-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
}

.meet-detail-employee-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

@media (max-width: 1200px) {
  .meet-detail-employee-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 992px) {
  .meet-detail-employee-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .meet-detail-employee-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .meet-detail-employee-grid {
    grid-template-columns: 1fr;
  }
}

.external-participant-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  width: 100%;
}

.external-participant-item {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  word-break: break-word;
}

.meet-rr-close-icon {
  height: 12px;
  width: 12px;
  cursor: pointer;
}

.mi-meet-html-view-container {
  word-wrap: break-word;
  overflow: hidden;
}

.meet-prev-floating-button-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
  background-color: white;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #e5e7eb;
  z-index: 50;
}

.meet-prev-floating-button-inner {
  display: flex;
  justify-content: center;
}

.meet-rr-facilities-dropdown {
  z-index: 1000;
  min-width: 200px;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #eee;
  position: absolute;
}

.meet-rr-facilities-dropdown div:hover {
  background-color: #f8f9fa;
}

.toast-container {
  padding: 1rem;
  width: 100%;
}

.toast-content {
  flex-direction: row;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.toast-image-container {
  flex-shrink: 0;
  padding-top: 0.125rem;
}

.toast-image {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  object-fit: cover;
}

.toast-text {
  flex-grow: 1;
}

.toast-sender-name {
  font-size: 0.875rem;
  font-weight: 500;
  color: #1f2937;
}

.toast-message {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #6b7280;
}
