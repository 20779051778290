.mention {
    color: #1877F2;
    text-decoration: none;
  }
  
  .mentionSuggestions {
    border-top: 1px solid #eee;
    background: #fff;
    border-radius: 2px;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    transform-origin: 50% 0%;
    transform: scaleY(0);
    margin: -16px;
  }
  
  .mentionSuggestionsEntryContainer {
    display: table;
    width: 100%;
    border-top: 1px solid lightgray;
    padding: 5px 10px 5px 10px;
  }

  .mentionSuggestionsEntryContainer2 {
    display: table;
    width: 100%;
    padding: 5px 10px 5px 10px;
  }
  
  .mentionSuggestionsEntryContainerLeft,
  .mentionSuggestionsEntryContainerRight {
    display: table-cell;
    vertical-align: middle;
  }
  
  .mentionSuggestionsEntryContainerRight {
    width: 100%;
    padding-left: 8px;
  }
  
  .mentionSuggestionsEntry {
    transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
    cursor: pointer;
  }
  
  .mentionSuggestionsEntry:active {
    background-color: #cce7ff;
  }
  
  .mentionSuggestionsEntryFocused {
    composes: mentionSuggestionsEntry;
    background-color: #e6f3ff;
  }
  
  .mentionSuggestionsEntryText,
  .mentionSuggestionsEntryTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .mentionSuggestionsEntryText {
  }
  
  .mentionSuggestionsEntryTitle {
    font-size: 80%;
    color: #a7a7a7;
  }
  
  .mentionSuggestionsEntryAvatar {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }